import React, { useEffect } from 'react';
import { HeaderPanel } from 'carbon-components-react';
import { CloseOutline32, Close24 } from '@carbon/icons-react';
import PortalComp from 'components/common/PortalComp';

const XPAPortal = ({
  id = 'portal-comp',
  open,
  title,
  className,
  children,
  closePortal,
  subTitle,
}) => {
  const handleOutsideClick = (e) => {
    const loadOverFlow = document.getElementsByClassName('bx--loading-overlay');
    const loadOverflowRun = loadOverFlow && loadOverFlow.length !== 0;
    if (e.target === e.currentTarget && !loadOverflowRun) {
      if (typeof open === 'boolean') {
        if (open) closePortal();
      } else closePortal();
    }
  };

  useEffect(() => {
    const handleKeyClick = (event) => {
      const loadOverFlow = document.getElementsByClassName(
        'bx--loading-overlay'
      );
      const loadOverflowRun = loadOverFlow && loadOverFlow.length !== 0;
      if (event.key === 'Escape' && !loadOverflowRun) {
        if (typeof open === 'boolean') {
          if (open) closePortal();
        } else closePortal();
      }
    };
    document.addEventListener('keydown', handleKeyClick);
    return () => {
      document.removeEventListener('keydown', handleKeyClick);
    };
  }, [open]);

  useEffect(() => {
    const beamerSelector = document.getElementById('beamerSelector');
    if (beamerSelector) {
      if (typeof open === 'boolean') {
        if (open) beamerSelector.style.display = 'none';
        else beamerSelector.style.display = 'block';
      } else beamerSelector.style.display = 'none';
      return () => {
        beamerSelector.style.display = 'block';
      };
    }
  }, [open]);

  return (
    <PortalComp id={id} hide={!open}>
      <div className="x-portal-overlay" onClick={handleOutsideClick}>
        <HeaderPanel
          expanded
          aria-label="chatBox-quick-view"
          style={{ overflowY: 'auto', overflowX: 'hidden' }}
          className={className || 'side-panel-chat-box'}>
          {title ? (
            <div className="m-2 p-2" style={{ backgroundColor: 'white' }}>
              <div className="bx--row mt-1 mb-1">
                <div className="bx--col-lg-8 bx--col-md-8 bx--col-sm-8 bx--col-xs-8">
                  <span className="h5 mb-0 d-inline-block primary-color text-capitalize font-weight-bold">
                    {title}
                  </span>
                </div>
                <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-4 bx--col-xs-4">
                  <div className="d-flex justify-content-end mr-3">
                    <Close24 className="cursor-pointer" onClick={closePortal} />
                  </div>
                </div>
              </div>
              {subTitle && (
                <div className="bx--row mb-1">
                  <div className="bx--col-lg-12 bx--col-md-12 bx--col-sm-12 bx--col-xs-12">
                    <span className="p mb-0 d-inline-block text-capitalize">
                      {subTitle}
                    </span>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-end mt-2 mb-2 mr-3">
              <Close24 className="cursor-pointer" onClick={closePortal} />
            </div>
          )}
          <div
            className="m-2 p-2"
            style={{ backgroundColor: 'white', minHeight: '90%' }}>
            {children}
          </div>
        </HeaderPanel>
      </div>
    </PortalComp>
  );
};

export default XPAPortal;
