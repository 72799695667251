import React from 'react';
import { Tile, Button } from 'carbon-components-react';
import noContentImg from 'assets/images/NoContentImg.png';

const NoContentBox = ({
  message,
  name,
  showButtons,
  buttonName,
  handleOnClickButtons,
  showSecondaryButton,
  secondaryButtonName,
  redirectPath,
  secondaryMessage,
  handleOnClickSecondaryButton,
  useNoContentImg
}) => {
  
  return (
    <Tile className={name ? name : ''}>
      <div className="card-body text-center">
        <div className="empty-icon">
          <img style={{ width: '290px' }} src={noContentImg} alt="No content image" />
        </div>
        <p className="empty-title h5">{message}</p>
        <p className="empty-title text-dark">{secondaryMessage}</p>
        <div className="d-flex justify-content-center">
          {showButtons === true && (
            <Button kind="tertiary" onClick={handleOnClickButtons}>
              {buttonName}
            </Button>
          )}
          {showSecondaryButton === true && (
            <div className="ml-2">
              {redirectPath ? (
                <a className="bx--btn bx--btn--secondary" target="_blank" href={redirectPath}>
                  {secondaryButtonName}
                </a>
              ) : (
                <Button kind="tertiary" onClick={handleOnClickSecondaryButton}>
                  {secondaryButtonName}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </Tile>
  );
}

export default NoContentBox;
