import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'carbon-components-react';
import UploadStudentDoc from './UploadStudentDoc';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import ImportHiredStudents from './ImportHiredStudents';

export const DocumentsModal = props => {
  const { 
    documentsModalOpen,
    appId,
    studentId,
  } = props || {};
  const [type, setType] = useState('offerLetter');
  const { t } = useTranslation();

  const dismissDocumentsModal = () => {
    props.dismissDocumentsModal();
  }
  const tabsData = [
    {
      label: t('Offer Letter'),
      key: 'offerLetter',
      onClick() {
        setType('offerLetter');
      },
    },
    {
      label: t('Job Description'),
      key: 'jobDescription',
      onClick() {
        setType('jobDescription');
      },
    },
    {
      label: t('Other Documents'),
      key: 'otherDocument',
      onClick() {
        setType('otherDocument');
      },
    },
  ]
  const tabIndex = tabsData.findIndex(it => it.key === type);
  return (
    <Fragment>
      {documentsModalOpen && (
        <Modal
          open={documentsModalOpen}
          modalHeading={''}
          modalLabel={t('')}
          passiveModal
          onRequestClose={dismissDocumentsModal}
          className='x-modal-100'
        >
          <GenericTabs selected={tabIndex} tabContent={tabsData} />
          <UploadStudentDoc
            key={type}
            appId={appId}
            studentId={studentId}
            type={type}
          />
        </Modal>
      )}
    </Fragment>
  );
}

export const ImportModal = props => {
  const { 
    importModalOpen,
  } = props || {};
  const { t } = useTranslation();

  const dismissImportModal = () => {
    props.dismissImportModal();
  }

  return (
    <Fragment>
      {importModalOpen && (
        <Modal
          open={importModalOpen}
          modalHeading={t('Import Hired Sudents')}
          modalLabel={t('')}
          passiveModal
          onRequestClose={dismissImportModal}
          className='x-modal-100'
          >
          <ImportHiredStudents 
            handleImportSuccess={props.handleImportSuccess}
          />
        </Modal>
      )}
    </Fragment>
  );
}
