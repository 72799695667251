import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'utils/custom-hooks';
import '@elastic/react-search-ui-views/lib/styles/styles.css';
import { InlineLoading } from 'carbon-components-react';
import DropDownBoxWithCreate from 'components/common/dropdown/DropdownCreatable.component';

const defaultfacetLimit = 10;

const getFilterValueDisplay = filterValue => {
  if (filterValue === undefined || filterValue === null) return '';
  if (filterValue.hasOwnProperty('name')) return filterValue.name;
  return String(filterValue);
};

export const MultiFacet = ({
  label = '',
  loading = false,
  className,
  facetLimit = defaultfacetLimit,
  name,
  onSearch,
  optionsMod = [],
  onSelectMod,
  placeHolder,
  showSearchAlways = false,
  callSearchOnNull,
  showSelectedFilters,
  selectedFilters,
}) => {
  const { t } = useTranslation();
  const [mSearch, setMSearch] = useState('');
  const [mShowMore, setMShowMore] = useState(0);

  useDebounce(() => onSearch && (mSearch || callSearchOnNull) && onSearch(mSearch), 800, [mSearch]);

  let optionData = optionsMod.map(el => {
    const count = typeof el.count === 'number' ? el.count.toString() : el.count || '';
    return { ...el, count, value: (el.value || '').toString(), selected: el.selected ? true : false };
  });
  if (mSearch) optionData = optionData.filter(v => v.value.searchIncludes(mSearch));
  const showMoreCount = mShowMore + 1;
  const showMore = showMoreCount * facetLimit < optionData.length;
  const uiOptions = showMore ? optionData.slice(0, facetLimit * showMoreCount) : optionData;
  const showSearch = optionsMod.length > facetLimit;
  const showLessCount = mShowMore - 1;
  const showLess = optionData.length > facetLimit && showLessCount >= 0;
  return (
    <fieldset className={`sui-facet${className ? ` ${className}` : ''}`}>
      {label && <legend className='sui-facet__title'>{label}</legend>}
      {showSelectedFilters && (
        <div className='mb-2'>
          <span className='mr-2 font-weight-bold text-dark'>Selected Filters:</span>
          {selectedFilters && Array.isArray(selectedFilters) && selectedFilters.length > 0
            ? selectedFilters.map(filter => <span className='bx--tag bx--tag--cyan bx--tag--sm'>{filter}</span>)
            : t('None')}
        </div>
      )}
      {(showSearch || showSearchAlways) && (
        <div className='sui-facet-search'>
          <input
            id='search-input'
            className='sui-facet-search__text-input'
            type='search'
            placeholder={placeHolder || t('Search')}
            onChange={e => setMSearch(e.target.value)}
          />
        </div>
      )}
      {loading ? (
        <div className='sui-multi-checkbox-facet'>
          <label className={'sui-multi-checkbox-facet__option-label'}>
            <InlineLoading status={'active'} />
            <div className='ml-2'>Loading...</div>
          </label>
        </div>
      ) : (
        <div className='sui-multi-checkbox-facet'>
          {uiOptions.length < 1 && <div className='ml-2'>No matching options</div>}
          {uiOptions.map(option => (
            <label
              key={getFilterValueDisplay(option.value)}
              htmlFor={'example_facet_' + label + getFilterValueDisplay(option.value)}
              className={'sui-multi-checkbox-facet__option-label'}>
              <div className='sui-multi-checkbox-facet__option-input-wrapper'>
                <input
                  id={'example_facet_' + label + getFilterValueDisplay(option.value)}
                  type='checkbox'
                  className='sui-multi-checkbox-facet__checkbox'
                  checked={option.selected}
                  onChange={() => {
                    const status = option.selected ? 'remove' : 'select';
                    const currVals = uiOptions.find(ele => ele.value === option.value);
                    onSelectMod(currVals, status, name);
                  }}
                />
                <span className='sui-multi-checkbox-facet__input-text'>{getFilterValueDisplay(option.value)}</span>
              </div>
              <span className='sui-multi-checkbox-facet__option-count'>{option.count.toLocaleString('en')}</span>
            </label>
          ))}
          <div>
            {showMore && (
              <button
                id='show-more-btn'
                type='button'
                className='sui-facet-view-more'
                onClick={() => setMShowMore(showMoreCount)}
                aria-label='Show more options'>
                {`+ ${t('More')}`}
              </button>
            )}
            {showLess && (
              <button
                id='show-less-btn'
                type='button'
                className='sui-facet-view-more'
                onClick={() => setMShowMore(showLessCount)}
                aria-label='Show Less options'>
                {`- ${t('Less')}`}
              </button>
            )}
          </div>
        </div>
      )}
    </fieldset>
  );
};

export const CreateMultiValues = ({ className, placeholder, onValueChange, onHandleValue, onChange, value }) => {
  const [inputValue, setInputValue] = useState('');

  const handleChange = () => {
    if (!inputValue) return;
    const check = onHandleValue ? onHandleValue(inputValue) : true;
    if (check) onChange([...value, inputValue].filter((v, p, th) => th.indexOf(v) === p));
    setInputValue('');
  };

  return (
    <>
      <DropDownBoxWithCreate
        isAsync
        required
        mainClassName="bx--form-item"
        custom
        className="bx--col m-0 p-0"
        // options={certificates.map(one => ({ label: one.certFull, value: one.certId }))}
        newOptionCreator={this.onNewOptionCreate}
        onKeyDown={e => {
          if (['Enter', 'Tab'].includes(e.key)) {
            e.preventDefault();
            handleChange();
          }
        }}
        isClearable
        multi
        onBlur={handleChange}
        onChange={v => onChange((v || []).map(it => it.value))}
        placeholder={placeholder}
        onInputChange={v => setInputValue(onValueChange ? onValueChange(v) : v)}
        selectedValue={value.map(v => ({ label: v, value: v }))}
      />
      {/* <CMSelect
        className={className}
        isMulti
        placeholder={placeholder}
        inputProps={{ maxLength: '50' }}
        inputValue={inputValue}
        onInputChange={v => setInputValue(onValueChange ? onValueChange(v) : v)}
        isClearable
        menuIsOpen={false}
        onKeyDown={e => {
          if (['Enter', 'Tab'].includes(e.key)) {
            e.preventDefault();
            handleChange();
          }
        }}
        onBlur={handleChange}
        onChange={v => onChange((v || []).map(it => it.value))}
        value={value.map(v => ({ label: v, value: v }))}
      /> */}
    </>
  );
};

export const FilterSectionButton = ({ style, count, onClick }) => {
  return (
    <div
      id='filter-section'
      className='mt-2 mt-md-0 p-1'
      title={'Show filter section'}
      style={{ fontSize: 32, cursor: 'pointer', position: 'relative', ...style }}
      onClick={onClick}>
      <i className='fa fa-filter' style={{ color: 'dimgray' }} />
      {count > 0 && <div className='dot'>{count > 10 ? '10+' : count}</div>}
    </div>
  );
};
