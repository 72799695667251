import { withTranslation } from 'react-i18next';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Modal, InlineNotification, Button, Toggle, TextInput } from 'carbon-components-react';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import GtpOptinCard from 'components/roboroy/gtp-optin';
import Avatar from 'components/common/Avatar';
import CandidateProfile from 'containers/roboroy/CandidateProfile';
import CandidateDetails from 'components/roboroy/CandidateDetails';
import ContactDetails from 'components/roboroy/ContactDetails';
import { uploadCampusDocs } from 'actions/userProfile/action';
import {
  uploadDocument,
  deleteDocument,
  uploadSignedOfferDocument
} from 'actions/uploadAllDocuments';
import { 
  getCandidateProfile,
  deleteCandidateProfile,
  downloadCandidateProfile,
  editCandidateProfile,
  getJobAppliedCount,
} from 'actions/talentSearch/candidatesList';
import {
  getSaluationList,
} from 'actions/companyRegistration';
import {
  contactInfoValidation,
} from 'containers/common/campusLogin/validationFunctions';
// import { Mixpanel } from 'components/common/Mixpanel';
import SetMixPanel from 'components/common/GetCurrentPath';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import {
  getAllCountries,
} from 'actions/admin/CompanyAndOffice';
import {
  fetchUserLoginState,
} from 'actions/userstate';
import {
  uploadImage,
  updatePictureUrl,
} from 'actions/userProfile/action';
import {
  updateSkills,
  updateEduction,
  updateExperience,
  addEduction,
  addExperience,
} from 'components/roboroy/common/candidateProfile';
import {
  upsertUserInfoDetails,
} from 'actions/candidate/candidateConsent';
import StudentProfileRegister from 'containers/roboroy/StudentProfileRegister';
import ProfileUpdateDetails from './ProfileUpdateDetails';
import StudentDocuments from './StudentDocuments';

const track = {
  EDIT_CANDIDATE_PROFILE_CLICKED: 'EDIT_CANDIDATE_PROFILE_CLICKED',
};

class ProfileInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      showDeleteModal: false,
      currToogleState: '',
      firstname: '',
      lastname: '',
      email: '',
      isFirstNameValid: false,
      isFirstNameValidText: '',
      isLastNameValid: false,
      isLastNameValidText: '',
      isEmailValid: false,
      isEmailValidText: '',
      phoneNumber: null,
      isValidCountryCode: false,
      isValidCountryCodeValue: '',
      isPhoneNumberValid: false,
      isPhoneNumberValidText: '',
      countryCode: null,
      designation: '',
      isValidDesignation: false,
      isValidDesignationVal: '',
      isDisable: false,
      detailsTab: 0,
      saluationListArr: [],
    };
  }

  componentDidMount() {
    const {
      user: {
        secondaryphone,
        agreeRecommendations, profileId,
        roleId,
        firstname, lastname, email,
        phone, codeId, designation,
        saluation: {
          saluationName: propSalutationName,
          saluationId: propSalutationId,
        } = {},
      } = {},
      location: {
        state: {
          selectedTab,
        } = {},
      } = {},
    } = this.props;
    let saluationListArr = [];
    this.props.getSaluationList().then((res) => {
      if (res && !res.error) {
        const { saluationList } = this.props;
        if (
          saluationList &&
          Array.isArray(saluationList) &&
          saluationList.length > 0
        ) {
          saluationListArr = saluationList.map(
            (sals) => {
              const {
                saluationId,
                saluationName,
              } = sals || {};
              const data = {
                label: saluationName,
                value: saluationId,
              };
              return data;
            });
          this.setState({
            saluationListArr,
          });
        }
      }
    });
    this.props.getAllCountries();
    this.setState({
      currToogleState: agreeRecommendations,
      firstname,
      lastname,
      secondaryphone,
      email,
      phoneNumber: phone,
      countryCode: codeId,
      designation,
      saluationVal: { label: propSalutationName, value: propSalutationId },
      selectedTab: selectedTab ? selectedTab : 0,
    }, () => {
      if (roleId && Number(roleId) === 3) {
        this.props.getJobAppliedCount(profileId);
        this.props.getCandidateProfile(profileId).then((res) => {
          if (res && !res.error) {
            const {
              profile: {
                isDisable,
              } = {},
            } = res || {};
            let disabledVal = false;
            if (isDisable === null) {
              disabledVal = false;
            } else {
              disabledVal = isDisable;
            }
            this.setState({
              isDisable: disabledVal,
            });
          }
        });
      }
    });
  }

  handleDropdownChange = (v, metaValue, name) => {
    this.setState({
      [name]: metaValue,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  changeImage = (element) => {
    const { user: { userId } = {} } = this.props;
    const fileData = element.target.files;
    const file = new FormData();
    file.append('file', fileData[0]);
    this.props.uploadImage(file).then((res) => {
      // window.location.reload();
      const { name } = res.result.files.file[0];
      // this.setState({
      //   image: name,
      // });
      if (name) {
        this.props.updatePictureUrl(userId, name)
          .then(() => {
            window.location.reload();
          })
          .catch(e => console.log('Error while updating avatar:', e));
      }
    });
  }

  handleProfileTabClick = (tabType) => {
    let selectedTab = 0;
    SetMixPanel(`workinfinland_Jobs_List ${tabType}`);
    switch (tabType) {
      case 'details':
        selectedTab = 0;
        break;
      // case 'documents':
      //   selectedTab = 1;
      //   break;
      // case 'settings':
      //   selectedTab = 1;
      //   break;
      case 'documents':
        selectedTab = 1;
        break;
      default:
        selectedTab = 0;
        break;
    }
    this.setState({
      selectedTab,
    });
  }

  handleDetailsTabClick = (tabType) => {
    let detailsTab = 0;
    SetMixPanel(`workinfinland_Jobs_List ${tabType}`);
    switch (tabType) {
      case 'student-info':
        detailsTab = 0;
        break;
      case 'bio-info':
        detailsTab = 1;
        break;
      case 'preferences':
        detailsTab = 2;
        break;
      case 'documents':
        detailsTab = 3;
        break;
      default:
        detailsTab = 0;
        break;
    }
    this.setState({
      detailsTab,
    });
  }

  isDisabledToggleClick = () => {
    const { user: { profileId } = {} } = this.props;
    const {
      isDisable,
    } = this.state;
    this.setState({
      isDisable: !isDisable,
    }, () => {
      const data = {
        isDisable: this.state.isDisable,
        profileId: Number(profileId),
      };
      this.props.editCandidateProfile(data).then((res) => {
        if (res && !res.error) {
          this.props.getCandidateProfile(profileId);
        }
      });
    });
  }

  deleteCandidateProfile = ()=>{
    this.setState({showDeleteModal:true});
  }

  onDelete = ()=>{
    const {
      user: {
        userId,
      } = {},
    } = this.props;
    this.setState({showDeleteModal:false}, 
        ()=>{ 
          this.props.deleteCandidateProfile(userId).then(()=>{
            window.location.reload();
          })
        }
      );
  }

  onToggleClick = () => {
    const {
      user: {
        userId,
        agreeRecommendations,
      } = {},
    } = this.props;
    this.setState({
      currToogleState: !this.state.currToogleState
    }, () => {
      const data = {
        agreeRecommendations: this.state.currToogleState,
        userId,
      };
      this.props.upsertUserInfoDetails(data).then((res) => {
        // window.location.reload();
        this.setState({
          currToogleState: res && res.agreeRecommendations,
        });
      });
    });
  }

  activeContactInfoModal = () => {
    this.setState({
      isOpenContactInfoModal: true,
    });
  }

  dismissContactInfoModal = () => {
    this.setState({
      isOpenContactInfoModal: false,
    });
  }

  handleSubmitUserDetails = () => {
    const {
      t,
      user: {
        company: {
          ctypeId,
        } = {},
      } = {},
    } = this.props;
    const {
      firstname,
      lastname,
      designation,
      countryCode,
      phoneNumber,
      email,
      saluationVal: { value: saluationId } = {},
    } = this.state;
    const contactInfo = {
      firstname,
      lastname,
      email,
      countryCode,
      phoneNumber,
      t,
      designation,
    };
    if (ctypeId && Number(ctypeId) === 1) {
      contactInfo.status = 'company';
    } else if (ctypeId && Number(ctypeId) === 2) {
      contactInfo.status = 'institution';
    }
    let isSaluationValid = false;
    if (!saluationId) {
      isSaluationValid = true;
    } else {
      isSaluationValid = false;
    }
    const validContactInfo = contactInfoValidation(contactInfo);
    this.setState({
      ...validContactInfo,
      isSaluationValid,
    }, () => {
      this.handleUserDetailsValidation();
    });
  }

  handleUserDetailsValidation = () => {
    const {
      user: {
        officeId,
        userId,
      } = {},
    } = this.props;
    const {
      isFirstNameValid,
      isLastNameValid,
      isValidCountryCode,
      isPhoneNumberValid,
      isEmailValid,
      isValidDesignation,
      isSaluationValid,
      saluationVal: { value: saluationId } = {},
    } = this.state;
    const {
      match: {
        params: {
          id,
        } = {},
      } = {},
    } = this.props;
    const {
      firstname,
      lastname,
      designation,
      countryCode,
      phoneNumber,
      email,
      isAdmin,
      isSuperAdmin,
      active,
      secondaryphone,
    } = this.state;
    const data = {
      firstname,
      lastname,
      designation,
      codeId: countryCode,
      phone: phoneNumber,
      email,
      companyId: Number(id),
      officeId,
      // roleId: 4,
      username: `${firstname} ${lastname}`,
      isAdmin,
      isSuperAdmin,
      active,
      secondaryphone,
      saluationId,
    };
    if (userId) {
      if (isFirstNameValid !== true && isLastNameValid !== true
        && isEmailValid !== true
        && isSaluationValid !== true
        && isValidDesignation !== true) {
        data.userId = Number(userId);
        this.props.upsertUserInfoDetails(data).then((res) => {
          if (res && !res.error) {
            this.props.fetchUserLoginState();
            this.dismissContactInfoModal();
          }
        })
          .catch(e => console.log('Error:::::', e));
      }
    }
  }

  handleRedirectRegister = () => {
    const {
      history: {
        push,
      } = {},
    } = this.props || {};
    if (push) {
      push('/app/campus/candidate/register/profile/stage/index/0');
    }
  }

  handleUploadFile = (element) => {
    const { files } = this.state;
    const name = element.target.name;
    let { selectedType = null, fileName = null } = {};
    let { index } = this.state;
    if (name !== null && Array.isArray(files) && files.length > Number(name)) {
      selectedType = files[name].selectedType;
      fileName = files[name].fileName;
    }
    const { user: { profileId = null } = {} } = this.props;
    // if (fileName === null || selectedType === null || profileId === null) return;
    const fileData = element.target.files;
    const currFile = new FormData();
    currFile.append('file', fileData[0]);
    this.setState({
      showDocLoading: true,
    }, () => {
      this.props.uploadCampusDocs(currFile).then(({ result: { files = {} } = {} }) => {
        const { file: [fileData = {}] = [] } = files || {};
        const { providerResponse: { location: subLocation } = {} } = fileData !== null ? fileData : {};
        this.props.uploadSignedOfferDocument({
          typeId: 12,
          fileName: `Signed Offer Letter ${profileId}`,
          subLocation,
          profileId,
          uploadTimestamp: new Date(),
        }).then((docs) => {
          if (docs && !docs.error) {
            this.props.getCandidateProfile(profileId);
            this.setState({
              files: [],
              showDocLoading: false,
            });
          }
        });
      }).catch(err => console.log(err));
    });
  }

  handleDeleteDoc = (personDocId) => {
    const {
      user: {
        profileId,
      } = {},
    } = this.props;
    this.setState({
      showDocLoading: true,
    }, () => {
      this.props.deleteDocument(personDocId).then((res) => {
        if (res && !res.error) {
          this.setState({
            showDocLoading: false,
          }, () => {
            this.props.getCandidateProfile(profileId);
          });
        }
      })
        .catch(e => console.log('Error:::::', e));
    });
  }

  render() {
    const {
      user: {
        userId, firstname, lastname, email,
        picture, roleId, profileId,
        agreeRecommendations,
        codeId,
        phone,
        saluation: {
          saluationName,
        } = {},
        company: {
          companyName,
          displayCompanyName,
          ctypeId,
        } = {},
      } = {},
      noBio,
      neonPoly = false,
      t,
      countries,
      isProfilePage,
      offerAcceptedCount,
    } = this.props;
    const { candidateProfile, user } = this.props;
    const {
      studentprofile: {
        isAppSubmitted,
      } = {},
      documents,
    } = candidateProfile || {};
    let filteredDocs = [];
    if (documents && Array.isArray(documents) && documents.length > 0) {
      filteredDocs = documents.filter(res => res.typeId === 12)
    }
    const { selectedTab, showDeleteModal, detailsTab } = this.state;
    const { studentprofile: { company = {} } = [] } = candidateProfile;
    let image = picture;
    if (picture && (!((picture.indexOf("http:") >= 0) || (picture.indexOf("https:") >= 0)))) {
      image = `https://x0pa-profile-images.s3.ap-southeast-1.amazonaws.com/${picture}?_=${(new Date()).getTime()}`;
    }

    const {
      isOpenContactInfoModal,
      isEmailValid, isEmailValidText, isFirstNameValid,
      isFirstNameValidText, isLastNameValid, isLastNameValidText,
      isPhoneNumberValid, isPhoneNumberValidText,
      isValidCountryCode, isValidCountryCodeValue, isDisable,
      countryCode, phoneNumber, isValidDesignationVal, saluationListArr, isSaluationValid,
      saluationVal,
      isValidDesignation, designation, email: currStateEmail, firstname: currStateFirstName, lastname: currStateLastName,
    } = this.state;
    const tabContent = [
      {
        label: t('profile'),
        value: '',
        selected: true,
        onClick: () => { this.handleProfileTabClick('details'); },
      },
      // {
      //   label: t('settings'),
      //   value: '',
      //   onClick: () => { this.handleProfileTabClick('settings'); },
      // },
    ];
    if (offerAcceptedCount > 0) {
      tabContent.push({
        label: t('signedUpdocs'),
        value: '',
        onClick: () => { this.handleProfileTabClick('documents'); },
      })
    }
    const hostName = window.location.hostname;
    let link = '';
    if (hostName.includes('internship-placement.rp.edu.sg')) {
      link = 'https://x0pa-rp-storage.s3-ap-southeast-1.amazonaws.com';
    } else {
      link = 'https://x0pa-rps-storage.s3-ap-southeast-1.amazonaws.com';
    }
    return (
      <Fragment>
        <JDProcessOverlay show={this.props.loading || this.state.showDocLoading} message="processing..." />
        {/* {
          roleId && roleId === 3
            && (
            <div className="mb-3">
              <GenericTabs
                className="mb-2"
                selected={selectedTab}
                tabContent={tabContent}
              />
            </div>
            )
        } */}
        {/* {
          isAppSubmitted !== true && roleId && Number(roleId) === 3 && selectedTab === 0 && (
            <InlineNotification
              lowContrast
              kind="info"
              className=""
              title=""
              hideCloseButton
              subtitle={(
                <div className="d-flex align-items-center">
                  <div>
                    {t('registerGeipHeading')}
                  </div>
                  <div className="ml-2">
                    <Button
                      kind="ghost"
                      size="small"
                      onClick={this.handleRedirectRegister}
                    >
                      {t('submitApplication')}
                    </Button>
                  </div>
                </div>
              )}
              onCloseButtonClick={() => {}}
            />
          )
        } */}
        {/* {roleId && Number(roleId) === 3 && 
         selectedTab === 0 && (
          isAppSubmitted == true && roleId && Number(roleId) === 3 && selectedTab === 0 && (
            <div> */}
               {/* <div className="ml-2 mt-3">
              <StudentProfileRegister
                selectedTab={0}
                detailsTab={detailsTab}
                showSteps={false}
                history={this.props.history}
              />
            </div> */}
            {/* <InlineNotification
              lowContrast
              kind="info"
              className=""
              title=""
              hideCloseButton
              subtitle={(
                <div className="d-flex align-items-center">
                  <div>
                    {t('clickToEdit')}
                  </div>
                  <div className="ml-2">
                    <Button
                      kind="ghost"
                      size="small"
                      onClick={this.handleRedirectRegister}
                    >
                      {t('editProfile')}
                    </Button>
                  </div>
                </div>
              )}
              onCloseButtonClick={() => {}}
            />
            <hr /> */}
            {/* <CandidateDetails notEditable={!noBio} noBio={noBio}/>
            </div>
          )
        } */}
        {/* {
          roleId && Number(roleId) === 3 && selectedTab === 0 && (
            <StudentProfileRegister
              selectedTab={selectedTab}
              detailsTab={null}
              hideBioEcd
              showSteps={false}
              history={this.props.history}
            />
          )
        } */}
        {/* {
          roleId === 3 && selectedTab === 0 && (
            <hr />
          )
        } */}
        {/* {
          roleId && Number(roleId) === 3
            && selectedTab === 0
            && (
            <div>
              <GenericTabs
                className="mb-2"
                selected={detailsTab}
                tabContent={[
                  {
                    label: t('geipMsg1'),
                    value: '',
                    selected: true,
                    onClick: () => { this.handleDetailsTabClick('student-info'); },
                  },
                  {
                    label: t('geipMsg2'),
                    value: '',
                    onClick: () => { this.handleDetailsTabClick('bio-info'); },
                  },
                  {
                    label: t('preferences'),
                    value: '',
                    onClick: () => { this.handleDetailsTabClick('preferences'); },
                  },
                  {
                    label: t('documents'),
                    value: '',
                    onClick: () => { this.handleDetailsTabClick('documents'); },
                  },
                ]}
              />
            </div>
            )
        } */}
        {/* {
          roleId === 3 && selectedTab === 0 && (
            <div className="ml-2 mt-3">
              <StudentProfileRegister
                selectedTab={0}
                detailsTab={detailsTab}
                showSteps={false}
                history={this.props.history}
              />
            </div>
          )
        } */}
        {
          roleId === 3 && selectedTab === 1 && (
            <div>
              <h6>Offer Documents</h6>
              <div className="mt-2 mb-2 text-dark">
                Please upload your signed offer letter.
              </div>
              {
                filteredDocs && Array.isArray(filteredDocs)
                  && filteredDocs.length > 0
                  ? (
                    <div>
                      {
                        filteredDocs.map((res) => {
                          const {
                            fileName,
                            documenttype: {
                              typeName,
                            } = {},
                            subLocation,
                            personDocId,
                            typeId,
                            cvLink,
                          } = res || {};
                          return (
                            <div className="d-flex align-items-center">
                              <div>
                                Signed Offer Letter
                              </div>
                              <div className="ml-3">
                                <a target="_blank" href={`/view/${cvLink}/cv?_=${Date.now()}`}>
                                  <Button size="small" kind="tertiary">
                                    {t('view')} {t('file')}
                                  </Button>
                                </a>
                              </div>
                              <div className="ml-3">
                                <Button size="small" onClick={() => this.handleDeleteDoc(personDocId)} kind="danger">
                                  {t('delete')}
                                </Button>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  ) : (
                    <div className="d-flex">
                      <div>
                        <TextInput
                          className="w-100 upload-docs-min-width mb-2"
                          placeholder={t('docName')}
                          disabled
                          value="Signed Offer Letter"
                          hideLabel={false}
                          name="Signed Offer Letter"
                        />
                      </div>
                      <div className="ml-2">
                        <div className="bx--file h-auto mx-auto text-center newjob-upload-jd" data-file>
                          <label
                            id="same"
                            htmlFor="jd_upload"
                            className="bx--btn bx--btn--sm bx--btn--secondary uploadFile"
                            role="button"
                            tabIndex="0"
                          >
                            {t('upload')}
                          </label>
                          <input
                            type="file"
                            className="bx--file-input"
                            id="jd_upload"
                            accept=".doc,.docx,.pdf,.jpeg,.jpg,.png,.JPEG,.JPG,.PNG"
                            onChange={this.handleUploadFile}
                            name="uploadDocs"
                            data-file-uploader
                            data-target="[data-file-container]"
                          />
                          <div data-file-container className="bx--file-container"></div>
                        </div>
                      </div>
                    </div>
                  )
              }
            </div>
          )
        }
        {/* {selectedTab === 1 && roleId === 3 && (
            <div>
              <StudentDocuments profileId={this.props.user && this.props.user.profileId} />
            </div>
        )} */}
        {/* {selectedTab === 1 && (
          roleId && roleId === 3 && (
            <div className="">
              <div className="card-body">
                <span className="h5 text-dark ml-2">
                  Profile Visiblity
                </span>
                <GtpOptinCard />
              </div>
              {!neonPoly && (
                <Fragment>
                  <div className="bx--row m-0">
                    <div className="bx--col-xs-12 bx--col-sm-9">
                      <p className="font-weight-bold m-0 ml-3">Recommendations</p>
                      <div className="ml-3">
                        <span className="">{t('recommendationDesc')}</span>
                      </div>
                    </div>
                    <div className="bx--col-xs-12 bx--col-sm-3 text-md-right">
                      <div className="bx--col-xs-4 text-right">
                        <Toggle
                          id="togglePrivate" 
                          className="xpa-toggle"
                          onToggle={this.onToggleClick}
                          toggled={currToogleState}
                          labelA="No"
                          labelB="Yes"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="bx--row m-0">
                    <div className="bx--col-xs-12 bx--col-sm-9">
                      <p className="font-weight-bold m-0 ml-3">{t('disable')} {t('profile')}</p>
                      <div className="ml-3">
                        <span className="">{t('disableProf')}</span>
                      </div>
                    </div>
                    <div className="bx--col-xs-12 bx--col-sm-3 text-md-right">
                      <div className="bx--col-xs-4 text-right">
                        <Toggle
                          id="toggle-1" 
                          className="xpa-toggle"
                          onToggle={this.isDisabledToggleClick}
                          toggled={isDisable}
                          labelA="No"
                          labelB="Yes"
                        />
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
              <div className="card-body mt-0 p-0 pl-2">
                <p className="font-weight-bold m-0 ml-2">
                  {t('deleteAccount')}
                </p>
                <small className="text-dark bx--row ml-2 mt-2">{t('deleteDesc')}</small>
                <Button kind="danger" small onClick={this.deleteCandidateProfile} className="ml-2 mt-1">{t('deleteAccount')}</Button>
                <Modal
                  open={showDeleteModal}
                  danger
                  modalHeading="Delete account"
                  modalLabel="My Profile"
                  primaryButtonText="Delete"
                  secondaryButtonText="Cancel"
                  iconDescription="Close the modal"
                  onRequestClose={() => { this.setState({ showDeleteModal: false }); }}
                  onRequestSubmit={this.onDelete}
                  onSecondarySubmit={() => { this.setState({ showDeleteModal: false }); }}
                >
                  <p className="bx--modal-content__text">
                    <strong className="text-dark">Are you sure you want to delete your account?</strong>
                    <small className="pt-1 text-dark d-block">
                      This action is IRREVERSIBLE. On clicking Delete, all the information will be deleted including any active applications, interviews, correspondence, profile infomation, offers and any other information submitted to this site.
                      <div className="text-center"><Button kind="tertiary" href={`/api/v1/Userinfos/candidateInfo`} small target="_blank" className="ml-1 mt-3">Download Data</Button></div>
                    </small>
                  </p>
                </Modal>
              </div>
            </div>
          )
        )} */}
        { roleId && roleId !== 3 && (
          <div className="view-profile">
            <div className="">
              <div className="card-body row">
                <div className="col-xs-12 col-sm-12 col-md-2 mx-auto text-center">
                  <label htmlFor="photo-upload-input" className="mx-auto">
                    <Avatar
                      user={{
                        firstname: user && user.firstname,
                        lastname: user && user.lastname,
                        picture: picture ? picture : null,
                      }}
                      size="100"
                      classNameInfo="rounded-circle"
                    />
                    <span className="upload-span d-block" hidden={picture ? true : false}>{t('uploadImage')}</span>
                  </label>
                  <input
                    accept=".png,.jpg,.jpeg,.gif,image/gif,image/jpg,image/jpeg,image/png"
                    id="photo-upload-input"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={this.changeImage}
                  />
                  <input
                    id="photo-upload-input"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={this.changeImage}
                  />
                </div>
                <table className="col-xs-12 col-sm-12 col-md-9 m-3">
                  <tbody>
                    <tr>
                      <td className="pro-label align-top">{t('name')}</td>
                      <td className="pro-details">{saluationName} <span className="text-capitalize">{firstname}&nbsp;{lastname}</span></td>
                    </tr>
                    <tr>
                      <td className="pro-label align-top">{t('email')}</td>
                      <td className="pro-details">{email || '-'}</td>
                    </tr>
                    <tr>
                      <td className="pro-label align-top">{t('company')}</td>
                      <td className="pro-details text-capitalize">{displayCompanyName || '-'}</td>
                    </tr>
                    {
                      codeId && phone && phone !== ''
                        ? (
                          <tr>
                            <td className="pro-label align-top">{t('phone')}</td>
                            <td className="pro-details text-capitalize">{`(+${codeId}) ${phone}` || '-'}</td>
                          </tr>
                        ) : null
                    }
                    <tr>
                      <td className="pro-label align-top">
                        <Button onClick={this.activeContactInfoModal} small kind="tertiary">{t('edit')}</Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {
                !isProfilePage && (
                  <Fragment>
                    <hr />
                    <ProfileUpdateDetails />
                  </Fragment>
                )
              }
            </div>
          </div>
        )}
        <Modal
          open={isOpenContactInfoModal}
          modalHeading={t('editContactInfo')}
          modalLabel={t('contactDetails')}
          primaryButtonText={t('save')}
          secondaryButtonText={t('cancel')}
          iconDescription="Close the modal"
          onRequestClose={this.dismissContactInfoModal}
          onRequestSubmit={this.handleSubmitUserDetails}
        >
          <div>
            <ContactDetails
              firstname={currStateFirstName}
              lastname={currStateLastName}
              disabled={false}
              isFirstNameValid={isFirstNameValid}
              isFirstNameValidText={isFirstNameValidText}
              handleChange={this.handleChange}
              isLastNameValid={isLastNameValid}
              isLastNameValidText={isLastNameValidText}
              isEmailValid={isEmailValid}
              email={currStateEmail}
              isEmailValidText={isEmailValidText}
              countryCode={countryCode}
              showSecondaryPhone
              secondaryphone={this.state.secondaryphone}
              isValidCountryCode={isValidCountryCode}
              countries={countries}
              isValidCountryCodeValue={isValidCountryCodeValue}
              phoneNumber={phoneNumber}
              isPhoneNumberValid={isPhoneNumberValid}
              isPhoneNumberValidText={isPhoneNumberValidText}
              designation={designation}
              isValidDesignation={isValidDesignation}
              isValidDesignationVal={isValidDesignationVal}
              disableEmail
              saluationListArr={saluationListArr}
              isSaluationValid={isSaluationValid}
              saluationVal={saluationVal}
              ctypeId={ctypeId}
              handleDropdownChange={this.handleDropdownChange}
            />
          </div>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.profile.userInfo,
  user: state.x0paDuser.user,
  candidateProfile: state.roboroyApplications.candidateProfile,
  loading: state.roboroyApplications.loading,
  neonPoly: false,
  countries: state.companyAndOffice.countries,
  saluationList: state.CompanyRegistration.saluationList,
  offerAcceptedCount: state.roboroyApplications.offerAcceptedCount,
});

const mapDispatchToProps = {
  uploadImage,
  updatePictureUrl,
  getCandidateProfile,
  deleteCandidateProfile,
  downloadCandidateProfile,
  upsertUserInfoDetails,
  getAllCountries,
  editCandidateProfile,
  fetchUserLoginState,
  getSaluationList,
  getJobAppliedCount,
  uploadCampusDocs,
  uploadDocument,
  deleteDocument,
  uploadSignedOfferDocument
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProfileInfo));
