import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getHiredStudents, exportHiredStudents } from 'actions/Hires';
import { DocumentsModal, ImportModal } from './HiresModals';
import { Button, Pagination } from 'carbon-components-react';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import NoContentBox from 'components/common/NoContentBox';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import BackRow from 'components/common/BackRow/BackRow';
import { toast } from "react-toastify";
import moment from "moment";
import { FilterSectionButton } from 'containers/roboroy/NewFilterSection/filterComps';
import FilterSectionModel from 'containers/roboroy/NewFilterSection';

let searchTimer = null;
const Hires = (props) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [count, setCount] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [loading, setLoading] = useState(false);
  const [viewUploadModalOpen, setViewUploadModalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [studentId, setStudentId] = useState(false);
  const [appId, setAppId] = useState(false);
  const [filterModel, setFilterModel] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  useEffect(() => {
    handleGetTableData();
  }, [itemsPerPage, activePage, searchKey, selectedFilters]);

  const getFiltersData = () => {
    let filteredData = {};
    selectedFilters && Array.isArray(selectedFilters) && selectedFilters.length > 0 && selectedFilters.map((sf) => {
      const { key, type, values: selectedValues } = sf || {};
      if(selectedValues && Array.isArray(selectedValues) && selectedValues.length > 0) {
        if(type == 'companyType' || type == 'jobType'){
          filteredData[key] = selectedValues.map(({plValue}) => plValue);
        }
      }
    })
    return filteredData;
  }

  const handleGetTableData = async () => {
    try {
      setLoading(true);
      const filtersData = getFiltersData();
      const pData = {
        limit: itemsPerPage,
        offset: activePage === 0 ? 0 : (activePage - 1) * itemsPerPage,
        ...filtersData,
      };

      if (searchKey) {
        pData.searchKey = searchKey;
      }

      const res = await props.getHiredStudents(pData);
      setLoading(false);
      if (res && !res.error) {
        const { data: listData, count: resCount } = res || {};
        // const resCount = 3;
        // const listData = [
        //   {
        //     studentId: 123,
        //     appId: 'ta1',
        //     facultyName: 'School Of Hospitality',
        //     courseName: 'Diploma In Hotel & Hospitality Management',
        //     studentEmail: 'ta1@gmail.com',
        //     studentName: 'Test Student 1',
        //     companyName: 'Test Company 1',
        //     jobTitle: 'Test Job 1',
        //     companyUEN: '123111231231',
        //     contactName: 'Test Contact 1',
        //     contactEmail: 'testcontact1@gmail.com',
        //     contactPhoneNumber: '998867666',
        //     internshipStartDate: moment().subtract(2,'months').format(),
        //     internshipEndDate: moment().add(5, 'months').format(),
        //   },
        //   {
        //     appId: 'ta2',
        //     studentId: 456,
        //     facultyName: 'School Of Hospitality',
        //     courseName: 'Diploma In Hotel & Hospitality Management',
        //     studentName: 'Test Student 2',
        //     studentEmail: 'ta2@gmail.com',
        //     companyName: 'Test Company 2',
        //     companyUEN: '123111231231',
        //     contactName: 'Test Contact 2',
        //     contactEmail: 'testcontact2@gmail.com',
        //     contactPhoneNumber: '998867666',
        //     jobTitle: 'Test Job 2',
        //     internshipStartDate: moment().subtract(1,'months').format(),
        //     internshipEndDate: moment().add(6, 'months').format(),
        //   },
        //   {
        //     appId: 'ta3',
        //     studentId: 78910,
        //     facultyName: 'School Of Hospitality',
        //     courseName: 'Diploma In Hotel & Hospitality Management',
        //     studentName: 'Test Student 3',
        //     studentEmail: 'ta3@gmail.com',
        //     companyName: 'Test Company 3',
        //     contactName: 'Test Contact 3',
        //     contactEmail: 'testcontact3@gmail.com',
        //     contactPhoneNumber: '998867666',
        //     companyUEN: '123111231231',
        //     jobTitle: 'Test Job 3',
        //     internshipStartDate: moment().format(),
        //     internshipEndDate: moment().add(7, 'months').format(),
        //   },
        // ]
        handleGetHeaders();
        handleGetRows(listData);
        setCount((resCount != null && resCount != undefined) ? Number(resCount) : 0);
      }
    } catch (error) {
      setLoading(false);
      console.error('error', error);
    }
  };

  const handleGetHeaders = () => {
    const tHeaders = [
      { key: 'studentName', header: t('Name') },
      { key: 'companyName', header: t('Company') },
      // { key: 'jobTitle', header: t('Job') },
      { key: 'contact', header: t('Contact') },
      { key: 'internshipStartDate', header: t('Start Date') },
      { key: 'internshipEndDate', header: t('End Date') },
      { key: 'documents', header: t('Documents') },
    ];
    setHeaders(tHeaders);
  };

  const handleGetRows = listData => {
    const tRows =
      listData && Array.isArray(listData) && listData.length > 0
        ? listData.map((lr,indx) => {
            const { 
              appId,
              studentName,
              studentEmail,
              companyName,
              companyUEN,
              jobName: jobTitle,
              internshipStartDate,
              internshipEndDate,
              facultyName,
              courseName,
              recruiterName: contactName,
              recruiterEmail: contactEmail,
              recruiterMobileNumber: contactPhoneNumber,
              studentUniversityId: studentId,
              id: hireId
            } = lr || {};
            return {
              id: `${hireId}_${studentId}_${indx}`,
              studentName: (
                <div>
                  <div className="small"><span>Student Univercity ID:</span><span className="ml-1">{studentId}</span></div>
                  <div>{studentName}</div>
                  {studentEmail && (
                    <div className="bx--type-caption">
                      <a href={`mailto:${studentEmail}`}>{studentEmail}</a>
                    </div>
                  )}
                  {jobTitle && (
                    <div className="bx--type-caption d-flex flex-wrap">
                      <div className="font-weight-bold">{t('Job')}:</div>
                      <div className="ml-1">
                        <a className="text-capitalize">
                          {jobTitle}
                        </a>
                      </div>
                    </div>
                  )}
                  {facultyName && (
                    <div className="bx--type-caption d-flex flex-wrap">
                      <div className="font-weight-bold">{t('faculty')}:</div>
                      <div className="ml-1">
                        <a className="text-capitalize">
                          {facultyName}
                        </a>
                      </div>
                    </div>
                  )}
                  {courseName && (
                    <div className="bx--type-caption d-flex flex-wrap">
                      <div className="font-weight-bold">{t('course')}:</div>
                      <div className="ml-1">
                        <a className="text-capitalize">
                          {courseName}
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              ),
              // jobTitle: (jobTitle || '-'),
              companyName: (
                <div>
                  <div>{companyName || '-'}</div>
                  {companyUEN && (
                    <div className="bx--type-caption d-flex flex-wrapn">
                      <div className="font-weight-bold">{t('UEN')}:</div>
                      <div className="ml-1">
                        <a className="text-capitalize">
                        {companyUEN}
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              ),
              contact: (
                <div>
                  <div>{contactName || '-'}</div>
                  {contactEmail && (
                    <div className="bx--type-caption">
                      <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                    </div>
                  )}
                  {contactPhoneNumber && (
                    <div className="bx--type-caption">
                      <i className="fa fa-phone"></i><span className="ml-2">{contactPhoneNumber}</span>
                    </div>
                  )}
                </div>
              ),
              jobTitle: jobTitle || '-',
              internshipStartDate: internshipStartDate ? moment(internshipStartDate).format('Do MMM YYYY') : '-',
              internshipEndDate: internshipEndDate ? moment(internshipEndDate).format('Do MMM YYYY') : '-',
              documents: (
                <a href="javascript:void(0)" onClick={() => handleViewUploadClick(appId, hireId)}>View/Upload</a>  
              ),
            };
          })
        : [];
    setRows(tRows);
  };

  const handleViewUploadClick = (appId, studentId, type) => {
    setStudentId(studentId);
    setAppId(appId);
    setViewUploadModalOpen(true);
  }

  const dismissViewUploadModal = () => {
    setViewUploadModalOpen(false);
    setAppId(null);
    setStudentId(null);
  }

  const handlePaginationChange = data => {
    const tActivePage = data.page || 1;
    const tItemsPerPage = data.pageSize || itemsPerPage;
    setActivePage(tActivePage);
    setItemsPerPage(tItemsPerPage);
  };

  const handleSearch = event => {
    const { target } = event || {};
    const { value } = target || {};
    if (searchTimer) {
      clearTimeout(searchTimer);
    }
    searchTimer = setTimeout(() => {
      setSearchKey(value);
    }, 1000);
  };

  const handleExportData = async () => {
    try {
      setLoading(true);
      const res = await props.exportHiredStudents()
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('error', error);
    }
  }

  const handleImportData = () => {
    setImportModalOpen(true);
  }

  const dismissImportModal = () => {
    setImportModalOpen(false);
  }
  
  const otherActionButtons = [
    <Button className='ml-2' size='small' kind='primary' onClick={handleExportData}>
      {t(`export`)}
    </Button>,
    <Button className='ml-2' size='small' kind='primary' onClick={handleImportData}>
      {t(`import`)}
    </Button>
  ];

  const paths = [
    { id: 'campus', name: t('campus'), href: '/app/campus' },
    { id: 'hires', name: t('hires'), href: '' },
  ];
  
  const handleSubmitFiltersDataNew = (filters) => {
    setSelectedFilters(filters);
  }

  const handleImportSuccess = () => {
    handleGetTableData();
  }

  return (
    <div>
      <BackRow paths={paths} />
      <div className="d-flex align-items-center justify-content-between">
        <h4 className='m-0 font-weight-bold text-capitalize bx--type-gamma'>{t('Hires')}</h4>
        <div className='d-flex justify-content-end'>
          <FilterSectionButton
            onClick={() => {
              setFilterModel(!filterModel)
            }}
            style={{margin: "0 .5rem"}}
            count={selectedFilters && selectedFilters.length}
          />
        </div>
      </div>
      {
        filterModel && (
          <FilterSectionModel
            style={{ top: -17, marginLeft: 20, marginRight: 10 }}
            open={filterModel}
            toggle={() =>
              setFilterModel(!filterModel)
            }
            // multiFilters={true}
            filterType={'hiredStudents'}
            filters={selectedFilters}
            onChangeFilters={handleSubmitFiltersDataNew}
            filterSettings={{}}
            filtersData={[
              {
                key: 'companies',
                name: t('Company'),
                type: 'companyType',
                method: 'pHiredStudents',
              },
              {
                key: 'jobs',
                name: t('Job'),
                type: 'jobType',
                method: 'pHiredStudents',
              },
            ]}
          />
        )
      }
      <GenericDataTable
        className='x-candidate-status-logs-list-table'
        otherActions={otherActionButtons}
        noHeaders={!headers || (headers && headers.length <= 0)}
        headers={headers || []}
        rows={rows || []}
        searchable
        onSearchInputChange={handleSearch}
      />

      {(!rows || !rows.length || !count) && <NoContentBox useNoContentImg name='mb-0' message={t('no_hire_records_msg')} />}
      
      {!loading && count >= 0 && (
        <Pagination
          onChange={handlePaginationChange}
          page={activePage}
          pageSize={itemsPerPage}
          totalItems={count || 0}
          pageSizes={[25, 50, 75, 100]}
        />
      )}

      {importModalOpen && (
        <ImportModal
          importModalOpen={importModalOpen}
          dismissImportModal={dismissImportModal}
          handleImportSuccess={handleImportSuccess}
        />
      )}

      {viewUploadModalOpen && (
        <DocumentsModal
          documentsModalOpen={viewUploadModalOpen}
          dismissDocumentsModal={dismissViewUploadModal}
          appId={appId}
          studentId={studentId}
        />
      )}

      {loading && (<JDProcessOverlay show={loading} message="processing..." />)}
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapActionsToProps = {
  getHiredStudents,
  exportHiredStudents
}
export default connect(mapStateToProps, mapActionsToProps)(Hires);