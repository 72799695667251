import React, { useState } from 'react';
import { Search } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';

const FilterSectionView = ({ filters, filtersData, setStep, setCurrFilter, fieldsData }) => {
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  const mainTypes = ['customFieldType'];
  const subFieldsTypes = filtersData.filter(x => x.subFields && Array.isArray(x.subFields)).map(x => x.type);
  const searchedFilterList = filtersData.reduce((a, c) => {
    if (
      !c.disabled &&
      !subFieldsTypes.includes(c.type) &&
      !mainTypes.includes(c.type) &&
      c && c.name && c.name.searchIncludes(search) &&
      filters && !filters.find(x => x.key === c.key)
    ) {
      a.push(c);
    } else if (c && mainTypes && !c.disabled && subFieldsTypes.includes(c.type)) {
      const sFData = c.subFields.filter(x => !x.disabled && x && x.name && x.name.searchIncludes(search) && !filters.find(el => el.key === x.key));
      if (sFData.length > 0) a.push({ ...c, subFields: sFData });
    } else if (c && mainTypes && !c.disabled && mainTypes.includes(c.type)) {
      const cFields = (fieldsData && fieldsData[c && c.customKey]) || [];
      const sFData = cFields.filter(x => !x.disabled && x && x.displayName && x.displayName.searchIncludes(search) && !filters.find(el => el.cfId === x.cfId));
      if (sFData.length > 0) a.push({ ...c, subFields: sFData });
    }
    return a;
  }, []);

  return (
    <fieldset className='ml-1 sui-facet'>
      <Search
        className='p-0 bx--col-xs-12 bx--col-sm-12'
        name='searchValue'
        defaultValue={search}
        labelText='Submit'
        closeButtonLabelText=''
        placeHolderText={t('Search Content')}
        onChange={e => setSearch(e.target.value)}
        id='search_1'
        tabIndex='0'
      />
      <div className='mt-2 ml-1 d-flex flex-column'>
        {searchedFilterList.length === 0 && <span className='p-1 text-capitalize bx--type-zeta'>{t('No Properties found')}</span>}
        {searchedFilterList.map(ele => (
          <>
            {mainTypes.includes(ele.type) || subFieldsTypes.includes(ele.type) ? (
              <span key={ele.key} className='p-1 text-capitalize bx--type-zeta'>
                {ele.name}
              </span>
            ) : (
              <span
                id={`curr-filter-${ele.key}`}
                key={ele.key}
                className={`p-1 text-capitalize bx--type-zeta ${(ele.type || '').includes('sortBy') ? 'xpa-primary-link' : 'xpa-link'}`}
                onClick={() => {
                  setCurrFilter({ ...ele, id: `${ele.key}` });
                  setStep('selectable');
                }}>
                {ele.name}
              </span>
            )}
            {mainTypes.includes(ele.type) &&
              ele.subFields && ele.subFields.map(({ cfId, dataType, displayName }) => (
                <span
                  id={`curr-filter-2-${cfId}`}
                  key={cfId}
                  className='ml-3 p-1 text-capitalize bx--type-zeta-min xpa-link'
                  onClick={() => {
                    const id = `${ele.key}-${cfId}`;
                    setCurrFilter({ key: ele.key, type: ele.type, customKey: ele.customKey, name: displayName, cfId, id, dataType });
                    setStep('selectable');
                  }}>
                  {displayName}
                </span>
              ))}
            {subFieldsTypes.includes(ele.type) &&
              ele.subFields && ele.subFields.map(el => (
                <span
                  id={`curr-filter-3-${ele.key}`}
                  key={el.key}
                  className={`ml-3 p-1 text-capitalize bx--type-zeta-min ${
                    (el.type || '').includes('sortBy') ? 'xpa-primary-link' : 'xpa-link'
                  }`}
                  onClick={() => {
                    setCurrFilter({ ...el, id: `${ele.key}-${el.key}` });
                    setStep('selectable');
                  }}>
                  {el.name}
                </span>
              ))}
          </>
        ))}
      </div>
    </fieldset>
  );
};

export default FilterSectionView;
