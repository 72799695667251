import { withTranslation } from 'react-i18next';
import React, { useState } from 'react';
import {
  Accordion,
  AccordionItem,
} from 'carbon-components-react';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';

import NoContentBox from '../../components/common/NoContentBox';
import './ImportResults.css';

const ImportResults = (props) => {
  const [isNewRecordsAccrOpen, setNewRecordsAccrOpen] = useState(true);
  const [isFailedRecordsAccrOpen, setFailedRecordsAccrOpen] = useState(true);
  const [isExistingRecordsAccrOpen, setExistingRecordsAccrOpen] = useState(true);
  const {
    existingRecords,
    existingRecordsCount,
    failedRecords,
    failedRecordsCount,
    newRecords,
    newRecordsCount,
    totalRecordsCount,
    t,
  } = props || {};
  
  const handleOpenAccr = (key) => {
    switch (key) {
      case 'new':
        setNewRecordsAccrOpen(!isNewRecordsAccrOpen);
        break;
      case 'failed':
        setFailedRecordsAccrOpen(!isFailedRecordsAccrOpen);
        break;
      case 'existing':
        setExistingRecordsAccrOpen(!isExistingRecordsAccrOpen);
        break;
      default:
        break;
    }
  };

  const getTableData = (key) => {
    const headers = [
      { key: 'name', header: t('Name') },
      { key: 'email', header: t('Email') },
      { key: 'schoolname', header: t('School') },
      { key: 'status', header: t('Status') },
    ];
    if (key == 'failed') {
      headers.push({
        key: 'errorMsg',
        header: t('Error Message'),
      });
    }
    let finalTableRows;
    let statusTagColor = '';
    let statusTagText = '';
    if (key == 'new') {
      finalTableRows = newRecords;
      statusTagColor = 'green';
      statusTagText = 'Success';
    } else if (key == 'failed') {
      statusTagColor = 'red';
      statusTagText = 'Failed';
      finalTableRows =
        failedRecords && Array.isArray(failedRecords) && failedRecords.length > 0
          ? failedRecords.map((fu) => {
              const { error, message, item } = fu || {};
              return {
                error,
                message,
                ...item,
              };
            })
          : [];
    } else if (key == 'existing') {
      statusTagColor = 'blue';
      statusTagText = 'Already exists';
      finalTableRows = existingRecords;
    }
    const rows =
      finalTableRows &&
      Array.isArray(finalTableRows) &&
      finalTableRows.length > 0
        ? finalTableRows.map((tr, indx) => {
            const {
              studentName: name,
              studentEmail: email,
              appMobileNumber: phone,
              facultyName: schoolname,
              status,
              error,
              message,
            } = tr || {};
            const row = {
              id:`${indx}`,
              name: name || '-',
              email: email || '-',
              phone: phone || '-',
              schoolname: schoolname || '-',
              status: (
                <span style={key == 'existing' ? {minWidth:'6rem'}:{}} className={`bx--tag bx--tag--${statusTagColor}`}>
                  {statusTagText}
                </span>
              ),
            };
            if (key == 'failed') {
              row.errorMsg = message || 'N/A';
            }
            return row;
          })
        : [];
    return { headers, rows };
  };

  const newTableData = getTableData('new') || {};
  const existingTableData = getTableData('existing') || {};
  const failedTableData = getTableData('failed') || {};
  const { headers: newRecordsHeaders, rows: newRecordsRows } = newTableData || {};
  const { headers: failedRecordsHeaders, rows: failedRecordsRows } = failedTableData || {};
  const { headers: existingRecordsHeaders, rows: existingRecordsRows } = existingTableData || {};

  return (
    <div>
      <div className='d-flex mb-3 align-items-baseline'>
        <div>
          <div class="h5 mb-2">{t('Below is the result of imported records')}.</div>
          <div class="bx--type-zeta">
            {`${t('Among total of')} ${totalRecordsCount} ${t('records')}. ${'Importing of'} ${failedRecordsCount} ${t('record(s) failed')}, ${existingRecordsCount} ${t('record(s) already exist and')} ${newRecordsCount} ${t('record(s) were imported successfully')}.`}
          </div>
        </div>
      </div>
      <div>
        <Accordion className='xpa-accrodion'>
          <AccordionItem
            title={<span className='h6'>{`Failed (${failedRecordsCount})`}</span>}
            open={isFailedRecordsAccrOpen}
            onClick={() => handleOpenAccr('failed')}>
            {failedRecordsRows && Array.isArray(failedRecordsRows) && failedRecordsRows.length > 0 && (
              <div>
                <div>
                  {`${t('Failed to import')} ${failedRecordsCount} ${t('record(s)')}`}
                </div>
                <GenericDataTable
                  hideToolbar
                  headers={failedRecordsHeaders || []}
                  rows={failedRecordsRows || []}
                />
              </div>
            )}
            {(!failedRecordsRows || (failedRecordsRows && Array.isArray(failedRecordsRows) && failedRecordsRows.length <= 0)) && (
              <NoContentBox message={t("There are no failed record records to show")} />
            )}
          </AccordionItem>
          <AccordionItem
            title={<span className='h6'>{`Already exist (${existingRecordsCount})`}</span>}
            open={isExistingRecordsAccrOpen}
            onClick={() => handleOpenAccr('existing')}>
            {existingRecordsRows && Array.isArray(existingRecordsRows) && existingRecordsRows.length > 0 && (
              <div>
                <div>
                  {`${existingRecordsCount} ${t('record(s) already exist')}`}
                </div>
                <GenericDataTable
                  hideToolbar
                  headers={existingRecordsHeaders || []}
                  rows={existingRecordsRows || []}
                />
              </div>
            )}
            {(!existingRecordsRows || (existingRecordsRows && Array.isArray(existingRecordsRows) && existingRecordsRows.length <= 0)) && (
              <NoContentBox message={t("There are no already existing record records to show")} />
            )}
          </AccordionItem>
          <AccordionItem
            title={<span className='h6'>{`Successfull  (${newRecordsCount})`}</span>}
            open={isNewRecordsAccrOpen}
            onClick={() => handleOpenAccr('new')}>
            {newRecordsRows && Array.isArray(newRecordsRows) && newRecordsRows.length > 0 && (
              <div>
                <div>
                  {`${t('Successfully imported')} ${newRecordsCount} ${t('record(s)')}`}
                </div>
                <GenericDataTable
                  hideToolbar
                  headers={newRecordsHeaders || []}
                  rows={newRecordsRows || []}
                />
              </div>
            )}
            {(!newRecordsRows || (newRecordsRows && Array.isArray(newRecordsRows) && newRecordsRows.length <= 0)) && (
              <NoContentBox message={t("There are no successfully imported record records to show")} />
            )}
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};
export default withTranslation()(ImportResults);
