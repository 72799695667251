import { useState } from 'react';
const proxyMap = new WeakMap();
const Target = Symbol('target');

export function useProxyState(initialValue, _useState = useState) {
  const [state, dispatch] = _useState(initialValue);
  if (proxyMap.has(dispatch)) return [state, proxyMap.get(dispatch)];
  function update(p, value) {
    dispatch(Object.assign({}, state, { [p]: value }));
  }
  const proxy = new Proxy(state, {
    set(target, p, value, receiver) {
      const result = Reflect.set(target, p, value, receiver);
      update(p, value);
      return result;
    },
    get(target, p, receiver) {
      if (p === Target) return target;
      const value = Reflect.get(target, p, receiver);
      return wrapMutableValue(value, () => update(p, value));
    },
  });
  proxyMap.set(dispatch, proxy);
  return [state, proxy];
}

export function unProxy(proxy) {
  return Reflect.get(proxy, Target);
}

const MutableMethodIndex = {
  Class: 0,
  Methods: 1,
};
const mutableMethodsByConstructor = [];
const mutableMethodsByClassName = [];

export function registerMutableMethodsByClassConstructor(constructor, methods) {
  const prev = mutableMethodsByConstructor.find((mutableMethod) => mutableMethod[MutableMethodIndex.Class] === constructor);
  if (prev) {
    const keys = new Set(methods);
    prev[MutableMethodIndex.Methods].forEach((key) => keys.add(key));
    prev[MutableMethodIndex.Methods] = Array.from(keys);
  } else mutableMethodsByConstructor.push([constructor, methods.slice()]);
}

export function registerMutableMethodsByClassName(className, methods) {
  const prev = mutableMethodsByClassName.find((mutableMethod) => mutableMethod[MutableMethodIndex.Class] === className);
  if (prev) {
    const keys = new Set(methods);
    prev[MutableMethodIndex.Methods].forEach((key) => keys.add(key));
    prev[MutableMethodIndex.Methods] = Array.from(keys);
  } else mutableMethodsByClassName.push([className, methods.slice()]);
}
export function getClassName(o) {
  return Object.prototype.toString.apply(o);
}
export function registerPrimitiveMutableClass(constructor, className, methods) {
  registerMutableMethodsByClassConstructor(constructor, methods);
  registerMutableMethodsByClassName(className, methods);
}
export const mutableArrayMethods = ['push', 'pop', 'shift', 'unshift', 'splice', 'fill', 'copyWithin', 'sort'];
registerPrimitiveMutableClass(Array, getClassName([]), mutableArrayMethods);
export const mutableMapMethods = ['set', 'delete', 'clear'];
registerPrimitiveMutableClass(Map, getClassName(new Map()), mutableMapMethods);
export const mutableSetMethods = ['add', 'delete', 'clear'];
registerPrimitiveMutableClass(Set, getClassName(new Set()), mutableSetMethods);
const mutableDateMethods1 = ['setMilliseconds', 'setUTCMilliseconds', 'setSeconds', 'setUTCSeconds'];
const mutableDateMethods2 = ['setMinutes', 'setUTCMinutes', 'setHours', 'setUTCHours', 'setDate', 'setUTCDate'];
const mutableDateMethods3 = ['setMonth', 'setUTCMonth', 'setFullYear', 'setUTCFullYear', 'setTime'];
export const mutableDateMethods = [...mutableDateMethods1, ...mutableDateMethods2, ...mutableDateMethods3];
registerPrimitiveMutableClass(Date, getClassName(new Date()), mutableDateMethods);

const ObjectClassName = getClassName({});
function wrapMutableValue(value, update) {
  const mutableMethodByConstructor = mutableMethodsByConstructor.find(
    (mutableMethod) => value instanceof mutableMethod[MutableMethodIndex.Class]
  );
  if (mutableMethodByConstructor) return wrapMutableMethods(value, mutableMethodByConstructor[MutableMethodIndex.Methods], update);
  const className = getClassName(value);
  const mutableMethodByClassName = mutableMethodsByClassName.find((mutableMethod) => mutableMethod[MutableMethodIndex.Class] === className);
  if (mutableMethodByClassName) return wrapMutableMethods(value, mutableMethodByClassName[MutableMethodIndex.Methods], update);
  if (className === ObjectClassName) return wrapMutableObject(value, update);
  return value;
}
const proxySet = new WeakSet();
function wrapMutableMethods(o, methods, update) {
  if (proxySet.has(o)) return o;
  proxySet.add(o);
  methods.forEach((method) => {
    const fn = o[method];
    o[method] = function () {
      const result = fn.apply(o, arguments);
      update();
      return result;
    };
  });
  return o;
}
function wrapMutableObject(o, update) {
  return new Proxy(o, {
    set(target, p, value, receiver) {
      const result = Reflect.set(target, p, value, receiver);
      update();
      return result;
    },
  });
}
