import { useCallback, useEffect, useRef } from 'react';

export const useTimeout = (callback, delay) => {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    set();
    return clear;
  }, [delay, set, clear]);

  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  return { reset, clear };
};

export const useDebounce = (callback, delay, dep) => {
  const { reset, clear } = useTimeout(callback, delay);
  useEffect(reset, [...dep, reset]);
  useEffect(clear, []);
};

export const useDebounceDelay = (callback, delay, dep) => {
  const { reset, clear } = useTimeout(callback, delay);
  useEffect(reset, [...dep, reset]);
  useEffect(() => {
    const timeout = setTimeout(clear, 100);
    return () => clearTimeout(timeout);
  }, []);
};

export const useTimer = (delay = 800) => {
  const timeoutRef = useRef();

  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  const timerSet = useCallback(
    callback => {
      clear();
      timeoutRef.current = setTimeout(() => callback(), delay);
    },
    [delay]
  );

  useEffect(() => clear, [delay, clear]);

  return { clear, timerSet };
};
