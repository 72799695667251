import React, { useState, useEffect, Fragment } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { importHiredStudents } from 'actions/Hires';
import {  FileUploaderButton } from 'carbon-components-react';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import { toast } from "react-toastify";
import HiredStudentsTemplate from 'assets/files/Hired_Students_Template.xls';
import moment from "moment";
import ImportResults from "./ImportResults";

let searchTimer = null;
const ImportHiredStudents = (props) => {
  const { t } = useTranslation();
  const { appId, studentId, type } = props || {};
  // '.doc','.docx','.pdf','.ppt','.pptx','.xls','.xlsx','.txt','.png','.jpg','.jpeg'
  const supportedFileFormats = ['.xls', '.xlsx'];
  const [loading, setLoading] = useState(false);
  const [studentImportResultData, setStudentImportResultData] = useState(null);
  const [showResult, setShowResult] = useState(false);

  const handleUploadDocument = async (element) => {
    try {
      const { target } = element || {};
      const { files } = target || {};
      let fileTypeInvalid = false;
      const fileData = files && files[0] || {};
      const { name } = fileData || {};
      let fileType = name && name.split('.').pop();
      fileType = `.${fileType && fileType.toString() && fileType.toString().toLowerCase()}`;
      fileTypeInvalid = !supportedFileFormats.includes(fileType);
      if(fileTypeInvalid){
        alert(`${t('Only')} ${supportedFileFormats.join(', ')} ${t('are supported, please check the file extension and try again')}.`);
        return;
      }
      const currFile = new FormData();
      currFile.append('excel', fileData);
      setLoading(true);
      const res = await props.importHiredStudents(currFile);
      setLoading(false);
      if(res && !res.error) {
        setStudentImportResultData(res);
        setShowResult(true);
        props.handleImportSuccess();
      } else {
        toast.error((res && res.error && res.error.message) || t('Sorry, something went wrong'));
      }
    } catch (error) {
      console.error('error', error);
      toast.error(t('Sorry, something went wrong'));
    }
  }

  const {
    existingStudents: existingRecords,
    existingStudentsCount: existingRecordsCount,
    failedStudents: failedRecords,
    failedStudentsCount: failedRecordsCount,
    newStudents: newRecords,
    newStudentsCount: newRecordsCount,
    totalStudents: totalRecordsCount,
  } = studentImportResultData || {};

  return (
    <Fragment>
      {!showResult ? (
        <Fragment>
          <div className="mb-2">{t('Upload your template here after filling it')}. <span><a target="_blank" href={HiredStudentsTemplate}>{t('Click here')}</a></span> {t('to download the template')}.</div>
          <FileUploaderButton
            accept={supportedFileFormats}
            buttonKind="primary"
            className="bob"
            labelText={`${t('upload')}`}
            name="index"
            onChange={handleUploadDocument}
            tabIndex={0}
            size='sm'
          />
        </Fragment>
      ) : (
        <ImportResults
          existingRecords={existingRecords}
          existingRecordsCount={existingRecordsCount}
          failedRecords={failedRecords}
          failedRecordsCount={failedRecordsCount}
          newRecords={newRecords}
          newRecordsCount={newRecordsCount}
          totalRecordsCount={totalRecordsCount}
        />
      )}
      {loading && (<JDProcessOverlay show={loading} message="processing..." />)}
    </Fragment>
  );
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapActionsToProps = {
  importHiredStudents
}

export default connect(mapStateToProps, mapActionsToProps)(ImportHiredStudents);