import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { ArrowLeft32, Edit24, TrashCan24, Checkmark24 } from '@carbon/icons-react';
import { Button, Loading, TextInput, InlineNotification } from 'carbon-components-react';
import { FilterSectionButton } from './filterComps';
import { toast } from 'react-toastify';
import XPAPortal from 'components/common/XPAPortal2';
import GenericTabs from 'components/common/GenericTabs/GenericTabs';
import { useTranslation } from 'react-i18next';
import { useLoaders } from 'utils/custom-hooks';
import FilterSectionEditor from './FilterSectionEditor';
import FilterSectionView from './FilterSectionView';
import { mergeArrUni } from 'utils/helpers';
import { 
  getCompaniesList,
  getJobsList,
} from 'actions/Hires';
import { 
  createOrUpdateFilter,
  getAllFilters,
  deleteFilter,
} from 'actions/filtersActions';

const FilterSectionModel = (props) => {
  const { 
    style,
    multiFilters = false,
    filterType = '',
    open,
    allRecruitersTalentPool,
    filters = [],
    filtersData = [],
    filterSettings,
    toggle,
    onChangeFilters,
    showFiltersBtn,
    user 
  } = props || {};
  const [currFilter, setCurrFilter] = useState(null);
  const [step, setStep] = useState('intial');
  const [selectedClick, setSelectedClick] = useState(false);
  const [loaders, handleLoaders] = useLoaders();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('create');
  const [allFilters, setAllFilters] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [slfView, setSlfView] = useState(false);
  const [errorInfo, setErrorInfo] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [companies, setCompanies] = useState([]);
  const [jobsList, setJobsList] = useState([]);

  useEffect(() => {
    if (filters.length > 0) setStep('selected');
    else setStep('intial');
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    if (multiFilters) getAllFiltersData();
  }, []);

  const getAllFiltersData = async () => {
    handleLoaders('getAllFilters');
    const data = { filterType };
    await props.getAllFilters(data).then(res => {
      const { records = [] } = res || {};
      setAllFilters(records);
    });
    handleLoaders('getAllFilters');
  };

  useEffect(() => {
    const allTypes = filtersData.treeToArray('subFields').map(ele => ele.type);
    if (allTypes.includes('companyType')) getIntialCompanyDetails();
    if (allTypes.includes('jobType')) getIntialJobDetails();
  }, []);

  const getCompanyDetailsOnSearch = async searchKey => {
    await props.getCompaniesList({ limit: 30, searchKey }).then(res => {
      if(res && !res.error) {
        const { companies = [], } = res || {};
        setCompanies(p => mergeArrUni(p, companies || [], 'companyName'));
      }
    });
  };

  const getIntialCompanyDetails = async () => {
    handleLoaders('All-company');
    await getCompanyDetailsOnSearch();
    handleLoaders('All-company');
  };

  const getJobDetailsOnSearch = async searchKey => {
    await props.getJobsList({ limit: 30, searchKey }).then(res => {
      if(res && !res.error){
        const { jobs: jobsList = [] } = res || {}; 
        setJobsList(p => mergeArrUni(p, jobsList || [], 'jobName'));
      }
    });
  };

  const getIntialJobDetails = async () => {
    handleLoaders('All-company');
    await getJobDetailsOnSearch();
    handleLoaders('All-company');
  };

  const onChangeCurrentFilter = tCurFilter => {
    const tslFilters = Object.clone(filters);
    const curFindedIndex = tslFilters.findIndex(ele => ele.id === tCurFilter.id);
    if (curFindedIndex === -1) tslFilters.push(tCurFilter);
    else tslFilters[curFindedIndex] = tCurFilter;
    onChangeFilters(tslFilters);
    setStep('selected');
  };

  const deleteSelectedfilter = ele => {
    const tslFilters = filters.filter(el => el.id !== ele.id);
    onChangeFilters(tslFilters);
    if (tslFilters.length === 0) setStep('intial');
  };

  const onClearAllFilters = () => {
    onChangeFilters([]);
    setStep('intial');
    setSelectedFilter({});
    setActiveTab('create');
  };

  const onSaveFilter = async () => {
    const { apiFilterId, filterName = '' } = selectedFilter || {};
    if (!filterName) {
      setErrorInfo(true);
      setErrorMessage(t`Please fill Filter Name`);
      return;
    }
    const data = {
      filterId: apiFilterId,
      filterType,
      filterName,
      filterJson: filters,
    };
    try {
      const res = await props.createOrUpdateFilter(data);
      const { error, message } = res || {};
      if (res && !res.error) {
        if (apiFilterId) toast.success(t`successfully updated`);
        else toast.success(t`Successfully created`);
        const newFilter = res || {};
        setErrorInfo(false);
        setErrorMessage('');
        setSelectedFilter(newFilter);
        setActiveTab('list-all');
        setSlfView(true);
      } else {
        setErrorInfo(true);
        setErrorMessage(message || t('Sorry, something went wrong'));
        return;
      }
    } catch (error) {
      const { message } = error || {};
      console.error('error', error);
      setErrorInfo(true);
      setErrorMessage(message || t('Sorry, something went wrong'));
    }
  };

  const deleteFilterData = async id => {
    const data = { filterId: id };
    await props.deleteFilter(data).then(res => {
      const { error, message } = res || {};
      if (error) toast.error(message || t('Sorry, something went wrong'));
      else {
        toast.success(t`Successfully deleted`);
      }
    });
  }

  const dataSection = () => {
    const { apiFilterId, filterName = '' } = selectedFilter || {};
    return step === 'selectable' ? (
      <div className='sui-facet-container'>
        <div
          className='m-0 cursor-pointer xpa-link'
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 90,
            fontSize: 14,
          }}
          onClick={() => {
            const { type } = currFilter;
            setStep(selectedClick ? 'selected' : 'intial');
            setSelectedClick(false);
          }}>
          <ArrowLeft32 style={{ width: 24 }} /> {t('Back')}
        </div>
        {currFilter && (
          <FilterSectionEditor
            filterSettings={filterSettings}
            currFilter={currFilter}
            setCurrFilter={setCurrFilter}
            onChangeCurrentFilter={onChangeCurrentFilter}
            companies={companies}
            getCompanyDetailsOnSearch={getCompanyDetailsOnSearch}
            jobsList={jobsList}
            getJobDetailsOnSearch={getJobDetailsOnSearch}
          />
        )}
      </div>
    ) : step === 'selected' && filters.length > 0 ? (
      <div className='sui-facet-container'>
        <div className='ml-1'>
          {filters
            .map(ele => {
              return (
                <div key={ele.id} className='bx--row mt-1 mb-1 bx--tag--orange'>
                  <div
                    style={{ cursor: 'pointer' }}
                    className='bx--col-lg-7 bx--col-md-7 bx--col-sm-7 bx--col-xs-7'
                    onClick={() => {
                      setCurrFilter(ele);
                      setStep('selectable');
                      setSelectedClick(true);
                    }}>
                    <span
                      className={`mr-1 text-capitalize bx--type-zeta ${(ele.type || '').includes('sortBy') ? 'xpa-primary-link' : 'xpa-link'}`}>
                      {ele.name}
                    </span>
                    {ele.qLabel}
                  </div>
                  <div className='bx--col-lg-3 bx--col-md-3 bx--col-sm-3 bx--col-xs-3'>
                    <div className='d-flex justify-content-end mr-3'>
                      <TrashCan24 color='red' className='cursor-pointer' onClick={() => deleteSelectedfilter(ele)} />
                    </div>
                  </div>
                </div>
              );
            })
            .joinElm(<span className='bx--type-zeta'>{t('and')}</span>)}
        </div>
        <div className='bx--row mt-1 mb-1'>
          <Button
            className='mt-3 ml-3 d-flex justify-content-center align-items-center'
            kind='secondary'
            small
            onClick={() => setStep('intial')}>
            {t('AND')}
          </Button>
          <Button className='mt-3 ml-2 d-flex justify-content-center align-items-center' kind='tertiary' small onClick={onClearAllFilters}>
            {t('Clear All')}
          </Button>
        </div>

        {multiFilters && (
          <div className='mt-3'>
            <hr />
            <div className='mt-2 mb-3 bx--row'>
              <div className='bx--col-lg-7 bx--col-md-7 bx--col-sm-7 bx--col-xs-7'>
                <span className='bx--type-zeta font-weight-bold text-capitalize'>
                  {apiFilterId ? t('Update this filter') : t('Save this filter')}
                </span>
              </div>
            </div>
            {errorInfo && (
              <div className='bx--row align-items-center m-2'>
                <InlineNotification
                  lowContrast
                  onCloseButtonClick={() => {
                    setErrorInfo(false);
                    setErrorMessage('');
                  }}
                  className='mt-1 mb-1'
                  title={errorMessage || t`Please fill all the fields`}
                  iconDescription={t`describes the close button`}
                  kind='error'
                />
              </div>
            )}
            <div className='bx--row align-items-center mb-2 ml-1'>
              <div className='bx--col-lg-3 mb-2 pl-0'>
                <label className='bx--label '>{t('Filter Name')}</label>
              </div>
              <div className='bx--col-lg-7'>
                <div className='bx--row align-items-center'>
                  <TextInput
                    id='dashboard-name'
                    required
                    className='mb-2 mr-2'
                    // labelText={t('Filter Name')}
                    placeholder={''}
                    invalidText={t('Please enter Filter Name')}
                    onChange={e => {
                      setSelectedFilter({
                        ...selectedFilter,
                        filterName: e.target.value,
                      });
                    }}
                    value={filterName}
                  />
                </div>
              </div>
            </div>
            <Button
              className='mt-3 d-flex justify-content-center align-items-center'
              kind='tertiary'
              small
              onClick={() => {
                onSaveFilter();
              }}>
              {apiFilterId ? t('Update') : t('Save')}
            </Button>
          </div>
        )}
      </div>
    ) : (
      <div className='sui-facet-container'>
        {filters.length > 0 && (
          <div
            className='m-0 cursor-pointer xpa-link'
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 90,
              fontSize: 14,
            }}
            onClick={() => setStep('selected')}>
            <ArrowLeft32 style={{ width: 24 }} /> {t('Back')}
          </div>
        )}
        <FilterSectionView filters={filters} filtersData={filtersData} setStep={setStep} setCurrFilter={setCurrFilter} />
      </div>
    );
  };

  return (
    <>
      {
        showFiltersBtn && (
          <FilterSectionButton onClick={toggle} style={style} count={filters.length} />
        )
      }
      <XPAPortal id='portal-filter' title={t('Filter & Sort')} open={open} closePortal={toggle}>
        {Object.values(loaders || {}).includes(true) ? (
          <div className='xpa-search-loading'>
            <Loading withOverlay={false} />
          </div>
        ) : multiFilters ? (
          (() => {
            const tabsData = [
              {
                label: t('Filters'),
                key: 'create',
                onClick() {
                  setActiveTab('create');
                  setSelectedFilter({});
                  setErrorInfo(false);
                  setErrorMessage('');
                  if (filters.length > 0) setStep('selected');
                  else setStep('intial');
                },
              },
              {
                label: t('Saved Filters'),
                key: 'list-all',
                onClick() {
                  setActiveTab('list-all');
                  setSlfView(false);
                  setErrorInfo(false);
                  setErrorMessage('');
                  if (filters.length > 0) setStep('selected');
                  else setStep('intial');
                },
              },
            ];
            const tabIndex = tabsData.findIndex(it => it.key === activeTab);
            return (
              <>
                <GenericTabs selected={tabIndex} tabContent={tabsData} />
                <div style={{ marginTop: -20 }}>
                  {activeTab === 'create' ? (
                    dataSection()
                  ) : activeTab === 'list-all' ? (
                    !slfView ? (
                      <div className='sui-facet-container'>
                        <div className='ml-1'>
                          {allFilters.map(ele => {
                            const { apiFilterId, filterName = '', filterJson = [] } = ele || {};
                            const selectedCurrent = apiFilterId === selectedFilter.apiFilterId;
                            return (
                              <div key={`${apiFilterId}-${selectedCurrent}`} className='bx--row mt-1 mb-1 bx--tag--orange'>
                                <div
                                  style={{ cursor: 'pointer' }}
                                  className='bx--col-lg-7 bx--col-md-7 bx--col-sm-7 bx--col-xs-7'
                                  onClick={() => {
                                    if (!selectedCurrent) setSelectedFilter(ele);
                                    if (JSON.stringify(filters) !== JSON.stringify(filterJson)) {
                                      onChangeFilters(filterJson);
                                    }
                                  }}>
                                  <span className='mr-1 text-capitalize bx--type-zeta xpa-link'>{filterName}</span>
                                  {selectedCurrent && <Checkmark24 color='blue' />}
                                </div>
                                <div className='bx--col-lg-3 bx--col-md-3 bx--col-sm-3 bx--col-xs-3'>
                                  <div className='d-flex justify-content-end mr-3'>
                                    <Edit24
                                      color='blue'
                                      className='cursor-pointer'
                                      onClick={() => {
                                        setSlfView(true);
                                        if (!selectedCurrent) setSelectedFilter(ele);
                                        if (JSON.stringify(filters) !== JSON.stringify(filterJson)) {
                                          onChangeFilters(filterJson);
                                        }
                                      }}
                                    />
                                    <TrashCan24
                                      color='red'
                                      className='ml-3 cursor-pointer'
                                      onClick={() => {
                                        if (selectedCurrent) setSelectedFilter({});
                                        deleteFilterData(apiFilterId);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          {allFilters.length === 0 && (
                            <div className={`p-2 d-flex flex-column align-items-center justify-content-center`} style={{ height: 150 }}>
                              <span className='h5 text-center pl-3 pr-3'>{t('There is no filters in list')}</span>
                              <Button
                                className='mt-3 ml-2 d-flex justify-content-center align-items-center'
                                kind='tertiary'
                                small
                                onClick={() => {
                                  setActiveTab('create');
                                  setSelectedFilter({});
                                }}>
                                {t('Create')}
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      dataSection()
                    )
                  ) : null}
                </div>
              </>
            );
          })()
        ) : (
          dataSection()
        )}
      </XPAPortal>
    </>
  );
};

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapActionsToProps = {
  getCompaniesList,
  getJobsList,
  createOrUpdateFilter,
  getAllFilters,
  deleteFilter,
}
export default connect(mapStateToProps, mapActionsToProps)(FilterSectionModel);
