import cloneDeep from 'lodash/cloneDeep';

Object.clone = cloneDeep;

const protoTypeDefine = (dataType, name, value) => {
  if (!dataType || !name || !value || !dataType['prototype']) return;
  Object.defineProperty(dataType['prototype'], name, { writable: false, enumerable: false, value });
};

protoTypeDefine(Number, 'fileSize', function () {
  const i = Math.floor(Math.log(this) / Math.log(1024));
  return (this / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
});

protoTypeDefine(String, 'toCapitalize', function (value) {
  if (this === '') return '';
  if (value === undefined || value === false) return this.charAt(0).toUpperCase() + this.slice(1);
  return this.replace(/(^\w{1})|(\s+\w{1})/g, l => l.toUpperCase());
});

protoTypeDefine(String, 'searchIncludes', function (value) {
  if (!value) return true;
  return this.toLowerCase().includes(value.trim().toLowerCase());
});

protoTypeDefine(String, 'camelToTitle', function () {
  if (this === '') return '';
  return this.replace(/[0-9]{2,}/g, m => ` ${m} `)
    .replace(/[^A-Z0-9][A-Z]/g, m => `${m[0]} ${m[1]}`)
    .replace(/[A-Z][A-Z][^A-Z0-9]/g, m => `${m[0]} ${m[1]}${m[2]}`)
    .replace(/[ ]{2,}/g, m => ' ')
    .replace(/\s./g, m => m.toUpperCase())
    .replace(/^./, m => m.toUpperCase())
    .trim();
});

protoTypeDefine(Array, 'filterMap', function (fn = null) {
  if (typeof fn !== 'function') return this;
  return this.reduce((acc, item, i) => {
    const value = fn(item, i, this);
    if (value === false || value === undefined) return acc;
    else return [...acc, value];
  }, []);
});

protoTypeDefine(Array, 'forAsyncSerial', async function (fn = null) {
  let result = [];
  for (let i = 0; i < this.length; i++) {
    if (typeof fn !== 'function') result[i] = await this[i];
    else result[i] = await fn(this[i], i, this);
  }
  return result;
});

protoTypeDefine(Array, 'arrayToTree', function (id = null, link = 'parentId') {
  return this.filterMap(item => {
    if (!(id === null ? !this.some(ele => ele.id === item[link]) : item[link] === id)) return;
    return { ...item, children: this.arrayToTree(item.id, link) };
  });
});

protoTypeDefine(Array, 'treeToArray', function (key = 'children') {
  return this.reduce((acc, curr) => {
    const newArr = (curr[key] || []).treeToArray(key);
    return [...acc, ...newArr].map(({ [key]: child, ...ele }) => ele);
  }, this);
});

protoTypeDefine(Array, 'joinElm', function (elm) {
  return this.reduce((acc, curr, ind) => {
    acc.push(curr);
    if (this.length - 1 !== ind && elm) acc.push(elm);
    return acc;
  }, []);
});
