import React from 'react';

export function reactMap(arr, ifFn, elseFn) {
  if (!arr || !Array.isArray(arr) || arr.length === 0) {
    if (elseFn) {
      if (typeof elseFn === 'function') return elseFn();
      else return elseFn;
    } else return false;
  } else {
    if (ifFn) {
      if (typeof ifFn === 'function') {
        return React.Children.toArray(arr.map(ifFn));
      } else return React.Children.toArray(arr.map(() => ifFn));
    } else if (elseFn) return false;
    else return true;
  }
}

export function arrMap(arr, ifFn, elseFn) {
  if (!arr || !Array.isArray(arr) || arr.length === 0) {
    if (elseFn) {
      if (typeof elseFn === 'function') {
        const result = elseFn();
        if (result && Array.isArray(result)) return result;
      } else {
        if (elseFn && Array.isArray(elseFn)) return elseFn;
      }
    }
  } else {
    if (ifFn) {
      if (typeof ifFn === 'function') {
        return arr.reduce((acc, item, i) => {
          const value = ifFn(item, i, arr);
          if (value === false || value === undefined) return acc;
          else return [...acc, value];
        }, []);
      } else return arr.map(() => ifFn);
    } else if (!elseFn) return arr;
  }
  return [];
}
