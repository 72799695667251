import { withTranslation } from 'react-i18next';
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import BackRow from 'components/common/BackRow/BackRow';
import {
  InlineNotification, Button, TextInput,
  RadioButtonGroup, RadioButton, Select,
  SelectItem, InlineLoading, StructuredListWrapper,
  StructuredListHead, StructuredListRow, StructuredListCell,
  StructuredListBody, Toggle, Modal
} from 'carbon-components-react';
import DropDownBoxWithCreate from 'components/common/dropdown/DropdownCreatable.component';
import ResumeUpload from 'containers/roboroy/ResumeUpload/ResumeUpload';
import _ from 'lodash';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import OfficeDetails from 'components/roboroy/common/OfficeDetails';
import Toast from 'components/common/Toast';
import { updateProfile } from '../../actions/candidate/CandidateDetails';
import CandidateDetails from 'components/roboroy/CandidateDetails';
import SkillsCompetency from 'components/roboroy/SkillsCompetency';
import FacultyQuestionsConfig from 'components/roboroy/FacultyQuestionsConfig';
import UploadAllDocuments from 'containers/common/uploadAllDocuments';
import { EditIcon } from 'components/common/Icons';
import {
  getLocations,
} from 'actions/campus/registrationRequest';
import WizardSteps from 'components/roboroy/WizardSteps';
import Steps from 'rc-steps';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import {
  officeDetailsValidation,
  getLocationsDetails,
} from 'containers/common/campusLogin/validationFunctions';
import {
  getCandidateProfile,
  editCandidateProfile,
  editProfileContact,
} from 'actions/talentSearch/candidatesList';
import PercentageSlider from 'components/common/PercentageSlider';
import {
  fetchAll,
  createNewOption,
} from 'actions/talentSearch/newJob';
import {
  REQUEST_ALL_SKILLS,
  REQUEST_CREATE_NEW_SKILLS,
} from 'actions/talentSearch/actionTypes';
import {
  updateSkills,
  updateEduction,
  updateExperience,
  addEduction,
  addExperience,
} from 'components/roboroy/common/candidateProfile';
import {
  getAllCountries,
} from 'actions/admin/CompanyAndOffice';
import {
  updateStudentProfile,
  getSkillsPreferredList,
  createSkillsLearnsForStds,
  createStudentCompanyPref,
  getCompanyTypes,
  createStudentPreference,
  deleteSkillsLearnsForStdsById,
} from 'actions/campus/internCandidate';
import DocumentsData from 'components/roboroy/DocumentsData';
import {
  updatePersonSkill,
} from 'actions/candidate/businessFinland';
import {
  getBatchDetails,
} from 'actions/BatchManagementView';
import FacultyUploadedDocs from 'components/roboroy/FacultyUploadedDocs';
import { uploadCampusDocs } from 'actions/userProfile/action';
import { uploadDocument, deleteDocument } from 'actions/uploadAllDocuments';
import './StudentProfileRegister.css';
import parse from 'html-react-parser';

const { Step } = Steps;



const chineseGrades = [
  'NA',
  'A1',
  'A2',
  'B3',
  'B4',
  'C5',
  'C6',
  'D7',
  'E8',
  'F9',
];

const yearsOfStudy = [
  '1',
  '2',
  '3',
  '4',
];

const semestersOfStydy = [
  '1',
  '2',
  '3',
];

class StudentProfileRegister extends Component {
  paths = [
    { id: 'campus', name: 'Campus', href: '/app/campus/candidate' },
    { id: 'student', name: 'Registration', href: '' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      personalData: [],
      currStep: 0,
      '0-status': 'process',
      '1-status': 'wait',
      '2-status': 'error',
      '3-status': 'error',
      '4-status': 'error',
      '5-status': 'error',
      showDocsError: false,
      prefSkillsArr: [],
      companyTypesArr: [],
      preferenceTaskOriented: 50,
      preferenceBackoffice: 50,
      showRegCompletedMsg: false,
      answerConfig: [],
      uploadResumeClicked: false,
      uploadLoader: false,
      showDropDownLoading: false,
      locationsArr: [],
      gender: '',
      currentGpa: 0,
      facultyId: 0,
      scholarChangeLoader: false,
      isOpenScholarModal: false,
      isScholar: false
    };
  }

  componentDidMount() {
    const {
      user: {
        profileId: stdProfId,
        roleId,
      } = {},
      match: {
        params: {
          profileId: paramProfileId,
        } = {},
      } = {},
    } = this.props;
    let profileId = stdProfId;
    if (roleId && Number(roleId) === 3) {
      profileId = stdProfId;
    } else {
      profileId = paramProfileId;
    }
    if (profileId) {
      if (roleId && Number(roleId) != 3) {
        this.handleGetBatchOptions();
      }
      this.props.getCandidateProfile(profileId).then((res) => {
        if (res && !res.error) {
          let profileInfo = res && res.profile || {}
          let scholarInfo = profileInfo && profileInfo.scholar
          if(scholarInfo){
            this.setState({
              isScholar: scholarInfo
            })
          }
        
          this.props.getAllCountries();
          this.props.getCompanyTypes();
          this.props.getSkillsPreferredList(true).then((skills) => {
            if (skills && !skills.error) {
              const {
                preferredSkills,
              } = this.props;
              let prefSkillsArr = [];
              if (preferredSkills && Array.isArray(preferredSkills) && preferredSkills.length > 0) {
                const sortedSkillsArr = preferredSkills.sort((a, b) => {
                  if (a.skillName.toLowerCase() < b.skillName.toLowerCase()) {
                    return -1;
                  }
                  if (a.skillName.toLowerCase() > b.skillName.toLowerCase()) {
                    return 1;
                  }
                  if (a.skillName.toLowerCase() === b.skillName.toLowerCase()) {
                    return 0;
                  }
                });
                prefSkillsArr = sortedSkillsArr.map((prefSkill) => {
                  const {
                    skillName,
                    skillId,
                  } = prefSkill;
                  const data = {
                    label: skillName,
                    value: skillId,
                  };
                  return data;
                });
              }
              this.setState({
                prefSkillsArr,
              }, () => {
                this.handleSaveProfiledata();
              });
            }
          });
        }
      });
    }
  }

  handleGetBatchOptions = () => {
    this.props.getBatchDetails().then((res) => {
      if(res && !res.error) {
        const { list: batchList } = res;
        const batchOptions = batchList && Array.isArray(batchList)
          && batchList.length > 0
          ? batchList.map((one, indx) => {
            return {
              label: one.batchName,
              value: one.batchName,
            };
          }) : [];
        this.setState({ batchOptions });
      }
    }).catch((err) => {
      console.error('error',err);
    })
  }

  handleRefreshCandidateProfile = () => {
    const {
      user: {
        profileId: stdProfId,
        roleId,
      } = {},
      match: {
        params: {
          profileId: paramProfileId,
        } = {},
      } = {},
    } = this.props;
    let profileId = stdProfId;
    if (roleId && Number(roleId) === 3) {
      profileId = stdProfId;
    } else {
      profileId = paramProfileId;
    }
    if(profileId){
      this.props.getCandidateProfile(profileId).then((res) => {
        this.handleSaveProfiledata();
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      candidateProfile: {
        studentprofile: {
          facultyId,
        } = {},
      } = {},
    } = prevProps || {};
    if (facultyId != this.state.facultyId) {
      this.handleSaveProfiledata();
    }
  }

  handleChange = (e) => {
    const { target: { name = '', value = '' } } = e;
    const finalVal = value && value.toString() && value.trimLeft();
    if (name === 'contact' && /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value)) {
      this.setState({ [name]: finalVal });
    } else {
      this.setState({ [name]: finalVal });
    }
  }

  onSkillsInputChange = (value, cb) => {
    if (value && typeof value === 'string') value = value.trimLeft();
    if (value && typeof value === 'string' && value.length === 0) return;
    if (/^[a-zA-Z0-9 #+.-]*$/.test(value)) {
      this.props.fetchAll({
        actionType: REQUEST_ALL_SKILLS,
        apiName: 'skills',
        paramName: 'skillName',
        value: _.toLower(value),
      }).then((res) => {
        const options = Array.isArray(res) && res.map(one => ({ label: one.skillName, value: one.skillId }));
        this.setState({
          prefSkillsArr: options || [],
        }, () => {
          this.handleStudentLearnState();
        });
        if (cb && typeof cb === 'function') cb(options || []);
      });
    }
  }

  onNewOptionCreate = (params, value) => {
    const {
      candidateProfile: { profile: { profileId } = {} } = {},
    } = this.props;
    const {
      skillLearnType = [],
    } = this.state;
    const actionData = {
      apiName: 'Skills',
      actionType: REQUEST_CREATE_NEW_SKILLS,
      data: { skillName: value, typeId: 1 },
    };
    this.props.createNewOption(actionData).then((res) => {
      const {
        skillId,
        skillName,
      } = (res && res.data) || {};
      const {
        candidateProfile: {
          studentlearns,
          studentprofile: {
            studentProfileId,
          } = {},
        } = {},
      } = this.props;
      this.props.createSkillsLearnsForStds({
        skillName,
        studentProfileId,
        studentChoice: studentlearns && studentlearns.length + 1,
      }).then((stds) => {
        if (stds && !stds.error) {
          const {
            stuLearnId,
          } = stds || {};
          skillLearnType.push({
            label: skillName,
            value: skillId,
            stuLearnId,
          });
          this.setState({
            skillLearnType,
          });
        }
      });
    });
  }

  handleSkillChange = (value, name, e) => {
    this.setState({
      [name]: value,
      maxSkillsPref: false,
    }, () => {
      const {
        candidateProfile: {
          studentlearns,
          profile: {
            profileId,
          } = {},
          studentprofile: {
            studentProfileId,
          } = {},
        } = {},
      } = this.props;
      if (value && Array.isArray(value) && value.length > 0 && !e.removedValue) {
        const data = {
          skillName: value && value[value.length - 1] && value[value.length - 1].label,
          studentProfileId,
          studentChoice: studentlearns && studentlearns.length + 1,
        };
        this.props.createSkillsLearnsForStds(data).then((res) => {
          if (res && !res.error) {
            this.props.getCandidateProfile(profileId);
          }
        });
        this.handleStudentLearnState();
      } else if (e && e.removedValue) {
        const {
          stuLearnId,
          value: skillId,
        } = e.removedValue;
        if (stuLearnId) {
          this.props.deleteSkillsLearnsForStdsById(studentProfileId, stuLearnId);
        } else {
          let filterValue = {};
          if (studentlearns && Array.isArray(studentlearns) && studentlearns.length > 0) {
            filterValue = studentlearns.find(res => res.skillId == skillId);
            const {
              stuLearnId: filteredLearnId,
            } = filterValue || {};
            this.props.deleteSkillsLearnsForStdsById(studentProfileId, filteredLearnId);
          }
        }
      }
    });
  };

  handleStudentLearnState = () => {
    const {
      preferredSkills,
    } = this.props;
    if (preferredSkills && Array.isArray(preferredSkills)
      && preferredSkills.length > 0) {
      const sortedSkillsArr = preferredSkills.sort((a, b) => {
        if (a.skillName.toLowerCase() < b.skillName.toLowerCase()) {
          return -1;
        }
        if (a.skillName.toLowerCase() > b.skillName.toLowerCase()) {
          return 1;
        }
        if (a.skillName.toLowerCase() === b.skillName.toLowerCase()) {
          return 0;
        }
      });
      const options = sortedSkillsArr.map((vals) => {
        const {
          skillId,
          skillName,
        } = vals || {};
        const data = {
          label: skillName,
          value: skillId,
        };
        return data;
      });
      this.setState({
        prefSkillsArr: options,
      });
    }
  }

  handleUpdateName = (editData) => {
    const { firstName: eFirstName, lastName: eLastName } = editData || {};
    this.setState({ eFirstName, eLastName, showUpdateNameModal: true });
  }

  handleUpdateNameModalSubmit = () => {
    const { eFirstName, eLastName } = this.state || {};
    const eFirstNameInvalid = !eFirstName ? true : false;
    const eLastNameInvalid = !eLastName ? true : false;
    this.setState({
      eFirstNameInvalid,
      eLastNameInvalid,
    },() => {
      if(!eFirstNameInvalid && !eLastNameInvalid) {
        const {
          user: {
            profileId: stdProfId,
            roleId,
          } = {},
          match: {
            params: {
              profileId: paramProfileId,
            } = {},
          } = {},
        } = this.props;
        let profileId = stdProfId;
        if (roleId && Number(roleId) === 3) {
          profileId = stdProfId;
        } else {
          profileId = paramProfileId;
        }
        const data = {
          profileId,
          infoData: {firstName: eFirstName, lastName: eLastName}
        }
        this.handleProfileUpdate(data);
      }
    })
  }

  dismissUpdateNameModal = () => {
    this.setState({
      eFirstName: null,
      eFirstNameInvalid: false,
      eLastName: null,
      eLastNameInvalid: false,
      showUpdateNameModal: false,
    })
  }


  handleUpdateBatch = (editData) => {
    const { batch } = editData || {};
    this.setState({ eBatch: {label: batch, value: batch}, showUpdateBatchModal: true });
  }

  handleUpdateBatchModalSubmit = () => {
    const { eBatch } = this.state || {};
    const eBatchInvalid = !eBatch ? true : false;
    this.setState({
      eBatchInvalid
    },() => {
      if(!eBatchInvalid) {
        const {
          user: {
            profileId: stdProfId,
            roleId,
          } = {},
          match: {
            params: {
              profileId: paramProfileId,
            } = {},
          } = {},
        } = this.props;
        let profileId = stdProfId;
        if (roleId && Number(roleId) === 3) {
          profileId = stdProfId;
        } else {
          profileId = paramProfileId;
        }
        const data = {
          profileId,
          infoData: {batch: eBatch && eBatch.value}
        }
        this.handleProfileUpdate(data);
      }
    })
  }

  dismissUpdateBatchModal = () => {
    this.setState({
      eBatch: null,
      eBatchInvalid: false,
      showUpdateBatchModal: false,
    })
  }

  handleProfileUpdate = (data) => {
    this.setState({ loading: true }, () => {
      this.props.updateProfile(data).then((res) => {
        if(res && !res.error) {
          this.handleRefreshCandidateProfile();
          this.dismissUpdateNameModal();
          this.dismissUpdateBatchModal();
          this.setState({ loading: false, showUpdateNameSuccess: true }, () => {
            setTimeout(() => {
              this.setState({ showUpdateNameSuccess: false });
            }, 5000);
          })
        }else{
          this.setState({ loading: false, showUpdateNameError: true }, () => {
            setTimeout(() => {
              this.setState({ showUpdateNameError: false });
            }, 5000);
          })
        }
      }).catch((error) => {
        this.setState({ loading: false, showUpdateNameError: true }, () => {
          setTimeout(() => {
            this.setState({ showUpdateNameError: false });
          }, 2000);
        })
      });
    })
  }

  handleSaveProfiledata = () => {
    const {
      candidateProfile,
      companyTypes,
      user: {
        roleId,
        isAdmin,
        isSuperAdmin
      } = {},
    } = this.props;
    const {
      studentlearns,
      ctypepreferences,
      studentpreferences,
      skills,
      documents,
      profile,
      studentprofile: {
        isAppSubmitted,
        answerConfig,
        portfolioLink,
        // yearOfStudy,
        // semester,
        currentGpa,
        // chinesegrade: {
        //   grade: {
        //     gradeId,
        //     gradeName,
        //   } = {},
        // } = {},
        // hchinesegrade: {
        //   grade: {
        //     gradeId: hGradeId,
        //     gradeName: hGradeName,
        //   } = {},
        // } = {},
        studentUniversityId,
        company: {
          companyName,
        } = {},
        courses: {
          courseId,
          courseName,
        } = {},
        faculty: {
          facultyId,
          facultyName,
        } = {},
      } = {},
    } = candidateProfile || {};
    const {
      firstName,
      lastName,
      contacts,
      email,
      gender: {
        genderName,
      } = {},
      residentStatus,
      address: {
        zipcode,
        addressLine1,
        addressLine2,
        location: {
          locationName,
          locationId,
          countryId,
        } = {},
      } = {},
      mailingAddress,
      batch
    } = profile || {};
    const {
      preferenceBackoffice,
      preferenceTaskOriented,
    } = (studentpreferences && studentpreferences[0]) || {};
    const {
      mobile,
    } = (contacts && contacts[0]) || [];
    const personalData = [
      {
        title: 'fullName',
        value: (
          <span className='d-flex'>
            <span>{`${firstName} ${lastName}`}</span> 
            <span className='ml-1 xpa-link' style={{marginTop: '-3px'}} onClick={() => {
              this.handleUpdateName({firstName, lastName});
            }}><EditIcon/></span>
          </span>
        ),
        showCapitalize: true,
      },
      {
        title: 'instName',
        value: `${companyName}`,
        showCapitalize: true,
      },
      {
        title: 'studentId',
        value: `${studentUniversityId}`,
        showCapitalize: true,
      },
      {
        title: 'schoolEmail',
        value: `${email}`,
        showCapitalize: false,
      },
      {
        title: 'facultySchool',
        value: `${facultyName}`,
        showCapitalize: true,
      },
    ];
    if (courseName) {
      personalData.push({
        title: 'course',
        value: `${courseName}`,
        showCapitalize: true,
      });
    }
    if (currentGpa && roleId && Number(roleId) !== 3) {
      personalData.push({
        title: 'GPA',
        value: `${currentGpa}`,
        showCapitalize: true,
      });
    }
    if (mobile) {
      personalData.push({
        title: 'phone',
        value: `${mobile}`,
        showCapitalize: true,
      });
    }
    if(batch){
      personalData.push({
        title: 'Batch',
        value: (roleId && Number(roleId) != 3 && (isSuperAdmin || isAdmin)) ? (
          <span className='d-flex'>
            <span>{`${batch}`}</span> 
            <span className='ml-1 xpa-link' style={{marginTop: '-3px'}} onClick={() => {
              this.handleUpdateBatch({batch});
            }}><EditIcon/></span>
          </span>
        ):(
          `${batch}`
        ),
        showCapitalize: true,
      })
    }else{
      personalData.push({
        title: 'Batch',
        value: `Batch info not available`,
        showCapitalize: true,
      })
    }
    personalData.push({
      title: 'Mailing Address',
      value: `${mailingAddress ? mailingAddress : '-'}`,
      showCapitalize: true,
    })
    let skillLearnType = [];
    let companyTypesArr = [];
    let ctypePref = [];
    if (studentlearns && Array.isArray(studentlearns) && studentlearns.length > 0) {
      skillLearnType = studentlearns.map((res) => {
        const {
          stuLearnId,
          skill: {
            skillId,
            skillName,
          } = {},
        } = res || {};
        return { label: skillName, value: skillId, stuLearnId };
      });
    }

    if (companyTypes && Array.isArray(companyTypes) && companyTypes.length > 0) {
      companyTypesArr = companyTypes.map((res) => {
        const {
          companyTypeId,
          companyName: companyTypeName,
        } = res || {};
        return { label: companyTypeName, value: companyTypeId };
      });
    }

    // if (ctypepreferences && Array.isArray(ctypepreferences) && ctypepreferences.length > 0) {
    //   ctypePref = ctypepreferences.map((res) => {
    //     const {
    //       studCtypePrefId,
    //       companytype: {
    //         companyName: ctypeName,
    //       } = {},
    //     } = res || {};
    //     return { label: ctypeName, value: studCtypePrefId };
    //   });
    // }

    if (studentlearns && Array.isArray(studentlearns) && studentlearns.length > 0) {
      skillLearnType = studentlearns.map((res) => {
        const {
          stuLearnId,
          skill: {
            skillId,
            skillName,
          } = {},
        } = res || {};
        return { label: skillName, value: skillId, stuLearnId };
      });
    }
    const data = {};
    if (answerConfig && Array.isArray(answerConfig) && answerConfig.length > 0) {
      answerConfig.forEach((res, idx) => {
        const {
          answer,
        } = res || {};
        data[`answer-${idx}`] = answer;
      });
    }
    this.setState({
      personalData,
      // chineseOGrade: { label: gradeName, value: gradeId },
      // chineseHigherOGrade: { label: hGradeName, value: hGradeId },
      portfolioLink,
      // semOfStudy: { label: semester, value: 1 },
      // yearOfStudy: { label: yearOfStudy, value: 1 },
      residentStatus,
      currentGpa,
      facultyId,
      skillLearnType,
      companyTypesArr,
      gender: genderName,
      ctypePref,
      preferenceBackoffice: preferenceBackoffice ? preferenceBackoffice : 50,
      preferenceTaskOriented: preferenceTaskOriented ? preferenceTaskOriented : 50,
      personMobileNumber: mobile,
      countryId,
      addressLine1,
      addressLine2,
      zipcode,
      courseId,
      country: countryId,
      location: { label: locationName, value: locationId },
      ...data,
    }, () => {
      if (isAppSubmitted === true) {
        this.setState({
          '0-status': 'completed',
          '1-status': 'completed',
          '2-status': 'completed',
          '3-status': 'completed',
          '4-status': 'completed',
          '5-status': 'completed',
        });
      } else if (documents && Array.isArray(documents) && documents.length > 0) {
        this.setState({
          '0-status': 'completed',
          '1-status': 'completed',
          '2-status': 'process',
          currStep: 2,
        });
      }
    });
  }

  handleOnBackSteps = () => {
    const {
      currStep,
    } = this.state;
    this.setState({
      currStep: currStep - 1,
    });
  }

  handleOnStepsChange = (current) => {
    if (current !== null) {
      this.setState({
        currStep: current,
      });
    } else {
      const {
        currStep,
      } = this.state;
      if (currStep === 0) {
        this.setState({
          currStep: currStep + 1,
        });
      } else if (this.state.currStep === 1) {
        this.handleResumeValidation(currStep);
      } else if (this.state.currStep === 2) {
        this.handleBioInfoPart1Validation(currStep);
      } else if (this.state.currStep === 3) {
        this.handleBioInfoPart2Validation(currStep);
      } else if (this.state.currStep === 4) {
        this.handlePreferencesValidation();
      }
    }
  }

  handleOfficeValidation = (profileData, mobiledata, data, studentProfileId) => {
    const {
      addressLine1, addressLine2, zipcode, country,
      location,
      mailingAddress,
    } = this.state;
    const {
      t,
    } = this.props;
    const officeInfo = {
      addressLine1,
      addressLine2,
      zipcode,
      country,
      location,
      t,
      mailingAddress,
    };
    const officeValidInfo = officeDetailsValidation(officeInfo);
    const { isValidAddrLine1, isValidCountry, isValidZipcode, isValidCity, isMailingAddressInvalid } = officeValidInfo || {};
    if (isValidAddrLine1 !== true && isValidCountry !== true
      && isValidZipcode !== true && isValidCity !== true && !isMailingAddressInvalid) {
      officeValidInfo.showBioInfo1Err = true;
    }
    this.setState({
      ...officeValidInfo,
    }, () => {
      this.handleOfficeStatusValid(profileData, mobiledata, data, studentProfileId);
    });
  }

  handleDeleteDoc = (personDocId) => {
    const {
      candidateProfile: {
        profile: {
          profileId,
        } = {},
      } = {},
    } = this.props;
    this.props.deleteDocument(personDocId).then(() => {
      this.props.getCandidateProfile(profileId);
    })
      .catch(e => console.log('Error:::::', e));
  }

  handleOfficeStatusValid = (profileData, mobiledata, data, studentProfileId) => {
    const {
      isValidAddrLine1,
      isValidCountry,
      isValidZipcode,
      isValidCity,
      currStep,
      addressLine1,
      addressLine2,
      zipcode,
      country,
      location,
      isMailingAddressInvalid,
      mailingAddress,
    } = this.state;
    const {
      allCountries,
    } = this.props;
    let finalCountry = {};
    if (allCountries && Array.isArray(allCountries) && allCountries.length > 0) {
      finalCountry = allCountries.find(res => res.countryId == country);
    }
    const finalProfileData = {
      ...profileData,
      zipcode,
      address: {
        addressLine1,
        addressLine2,
        location: {
          locationName: location && location.label,
          country: finalCountry && finalCountry.countryFull,
        },
      },
      mailingAddress,
    };
    if (isValidAddrLine1 !== true && isValidCountry !== true
      && isValidZipcode !== true && isValidCity !== true && !isMailingAddressInvalid) {
      this.props.editCandidateProfile(finalProfileData).then((prof) => {
        if (prof && !prof.error) {
          this.props.editProfileContact(mobiledata);
          this.props.updateStudentProfile(data, studentProfileId).then((res) => {
            if (res && !res.error) {
              this.setState({
                showBioInfo1Err: false,
                showBioApiErr: false,
                '2-status': 'process',
                currStep: currStep + 1,
              });
            } else if (!res || res.error) {
              this.setState({
                showBioApiErr: true,
              });
            }
          }).catch((e) => {
            console.log('Error:::::', e);
            this.setState({
              showBioApiErr: true,
            });
          });
        }
      });
    }
  }

  handleResumeValidation = (current) => {
    const {
      candidateProfile: {
        documents,
      } = {},
    } = this.props;
    const {
      currStep,
    } = this.state;
    if (!documents || (documents && Array.isArray(documents) && documents.length <= 0)) {
      this.setState({
        '1-status': 'error',
        showDocsError: true,
      });
    } else {
      
      this.setState({
        '1-status': 'process',
        showDocsError: false,
        currStep: currStep + 1,
      });
    }
  }

  handleBioInfoPart1Validation = () => {
    const {
      countryFull,
      residentStatus,
      currentGpa,
      currStep,
      portfolioLink,
      personMobileNumber,
      countryId,
      courseId,
      gender,
    } = this.state;
    const {
      allCountries,
      user: {
        roleId,
      } = {},
    } = this.props;
    let countryObj = {};
    if (allCountries && Array.isArray(allCountries) && allCountries.length > 0) {
      countryObj = allCountries.find(res => res.countryId == countryId);
    }
    if (!residentStatus || (residentStatus === 'others' && !countryFull) || !gender || (roleId && Number(roleId) !== 3 && (!currentGpa || !courseId)) || !personMobileNumber || !countryId) {
      this.setState({
        showBioInfo1Err: true,
      });
    } else if (personMobileNumber && personMobileNumber.length !== 8) {
      this.setState({
        showBioInfo1Err: true,
        isValidPersonNumber: true,
      });
    } else {
      const {
        candidateProfile,
      } = this.props;
      const {
        profile: {
          contacts,
          profileId,
        } = {},
        studentprofile: {
          studentProfileId = '',
        } = {},
      } = candidateProfile || {};
      const profileData = {
        residentStatus,
        gender,
        profileId: profileId && Number(profileId),
      };
      const {
        personMobId,
      } = (contacts && contacts[0]) || [];
      const mobiledata = {
        codeId: countryId,
        countryFull: countryObj && countryObj.countryFull,
        mobile: personMobileNumber,
        profileId: profileId && Number(profileId),
      };
      if (personMobId) {
        mobiledata.personMobId = personMobId;
      }
      const data = {
        countryFull,
        currentGpa,
        courseId,
        // yearOfStudy: yearOfStudy && yearOfStudy.label,
        // semester: semOfStudy && semOfStudy.label,
        // chineseOGrade: chineseOGrade && chineseOGrade.label,
        // chineseHigherOGrade: chineseHigherOGrade && chineseHigherOGrade.label,
        portfolioLink,
      };
      this.handleOfficeValidation(profileData, mobiledata, data, studentProfileId);
    }
  }

  handleBioInfoPart2Validation = () => {
    const {
      candidateProfile,
    } = this.props;
    const {
      skills,
    } = candidateProfile || {};
    const {
      currStep,
    } = this.state;
    if (!skills || (skills && Array.isArray(skills) && skills.length <= 0)) {
      this.setState({
        showError: true,
      });
    } else {
      this.setState({
        showError: false,
        '3-status': 'process',
        currStep: currStep + 1,
      });
    }
  }

  handlePreferencesValidation = () => {
    const {
      candidateProfile: {
        profile: {
          profileId,
        } = {},
        studentprofile: {
          studentProfileId,
        } = {},
      } = {},
    } = this.props;
    const {
      currStep,
      ctypePref,
      skillLearnType,
    } = this.state;
    if (!skillLearnType || (skillLearnType && Array.isArray(skillLearnType) && skillLearnType.length < 1)) {
      this.setState({
        showStudentLearnsErr: true,
        noCompPrefMsg: false,
      });
    } else {
      const data = { studentProfileId };
      // data.ctypeData = ctypePref && Array.isArray(ctypePref)
      //   && ctypePref.length > 0
      //   && ctypePref.map((res, index) => {
      //     const {
      //       label,
      //     } = res;
      //     const finalData = {
      //       ctypeName: label,
      //       rank: index + 1,
      //     };
      //     return finalData;
      //   });
      // this.props.createStudentCompanyPref(data).then((res) => {
      //   if (res && !res.error) {

      //   }
      // });
      const {
        preferenceBackoffice,
        preferenceTaskOriented,
      } = this.state;
      const prefdata = {
        preferenceBackoffice,
        preferenceTaskOriented,
      };
      this.props.createStudentPreference(prefdata, studentProfileId).then((result) => {
        if (result && !result.error) {
          this.props.getCandidateProfile(profileId);
          this.setState({
            showStudentLearnsErr: false,
            noCompPrefMsg: false,
            '4-status': 'process',
            currStep: currStep + 1,
            showApiErr: false,
          });
        } else if (result && result.error) {
          this.setState({
            showApiErr: true,
          });
        }
      });
    }
  }

  onSliderChange = ({ value }, name) => {
    const {
      candidateProfile: {
        profile: { profileId = '' } = {},
        studentprofile: {
          studentProfileId = ''
        } = {},
        studentpreferences = null,
      } = {},
    } = this.props;
    let data = {};
    data[name] = value;
    const studentPreferenceId = Array.isArray(studentpreferences) && studentpreferences.length > 0 ? studentpreferences[0].studentPreferenceId : null;
    if (studentPreferenceId && studentPreferenceId !== null) {
      this.setState({
        [name]: value,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  handleRedirectHomePage = () => {
    const {
      history: {
        push,
      } = {},
      user: {
        roleId,
      } = {},
      candidateProfile: {
        profile: {
          profileId,
        } = {},
        studentprofile: {
          studentProfileId,
        } = {},
      } = {},
    } = this.props;
    if (push) {
      if (roleId && Number(roleId) === 3) {
        push('/app/campus/candidate');
      } else {
        push(`/app/campus/profile/${profileId}/student/${studentProfileId}`)
      }
    }
  }

  activateModal = () => {
    this.setState({
      activeModal: true,
    });
  };

  dismissModal = (flag) => {
    this.setState({
      activeModal: false,
      uploadResumeClicked: flag,
    });
  };

  toastSuccess = (isEmailExists) => {
    const {
      currStep,
    } = this.state;
    window.location.reload();
    this.setState({
      currStep: currStep + 1,
      '1-status': 'process',
      uploadResumeClicked: false,
    });
  };

  refresh = (partialRefresh) => {
    // setTimeout(() => {
    //   });
    // }, 1000);
  };

  onresidentStatusChange = (value, name) => {
    this.setState({ [name]: value });
  }

  onDropdownChange = (e, params) => {
    if (e === undefined) return;
    const stageBio = e === null ? {} : e;
    this.setState({ [params]: stageBio });
  };

  handleUploadFile = (element, typeId, typeName) => {
    const { files } = this.state;
    const name = element.target.name;
    let { selectedType = null, fileName = null } = {};
    let { index } = this.state;
    if (name !== null && Array.isArray(files) && files.length > Number(name)) {
      selectedType = files[name].selectedType;
      fileName = files[name].fileName;
    }
    const { candidateProfile: { profile: { profileId } = {} } = {} } = this.props;
    // if (fileName === null || selectedType === null || profileId === null) return;
    const fileData = element.target.files;
    const currFile = new FormData();
    currFile.append('file', fileData[0]);
    this.setState({
      uploadLoader: true,
    }, () => {
      this.props.uploadCampusDocs(currFile).then(({ result: { files = {} } = {} }) => {
        const { file: [fileData = {}] = [] } = files || {};
        const { providerResponse: { location: subLocation } = {} } = fileData !== null ? fileData : {};
        this.props.uploadDocument({
          typeId,
          fileName: typeName,
          subLocation,
          profileId,
          uploadTimestamp: new Date(),
        }).then(() => {
          this.props.getCandidateProfile(profileId);
          this.setState({
            uploadLoader: false,
          });
        });
      }).catch(err => console.log(err));
    });
  }

  onInputDropdownChange = (value) => {
    if (value) {
      this.setState({
        showDropDownLoading: true,
      }, () => {
        this.props.getLocations(value).then((res) => {
          if (res && !res.error) {
            const {
              locations,
            } = this.props;
            const values = {
              locations,
            };
            const locationsArr = getLocationsDetails(values);
            this.setState({
              locationsArr,
              showDropDownLoading: false,
            });
          }
        });
      });
    }
  };

  handleDropdownChange = (v, metaValue, name) => {
    this.setState({
      [name]: metaValue,
    }, () => {
      const {
        location,
      } = this.state;
      let country = null;
      if (name === 'location' && location) {
        const {
          countryId,
        } = location;
        if (countryId) {
          country = countryId;
        }
        this.setState({
          country,
        });
      }
    });
  };

  onCompanyprefDropDown = (e, params) => {
    const {
      ctypePref,
    } = this.state;
    if (!ctypePref || (ctypePref && Array.isArray(ctypePref) && ctypePref.length < 3)) {
      this.setState({
        [params]: e,
        maxCtypePrefMsg: false,
      });
    } else {
      this.setState({
        maxCtypePrefMsg: true,
      });
    }
  }

  onStarClick = (nextValue, personSkillId, skillName) => {
    const {
      candidateProfile: {
        profile: {
          profileId,
        } = {},
      } = {},
    } = this.props;
    const data = {
      skillName,
      rating: nextValue,
      profileId,
      personSkillId,
    };
    this.props.updatePersonSkill(data).then((res) => {
      if (res && !res.error) {
        this.props.getCandidateProfile(profileId);
      }
    }).catch(e => console.log('Error:::::', e));
  }

  handleChangeAnsConfig = (e, uuid, idx) => {
    let {
      indexData,
    } = this.state;
    if ((indexData && Array.isArray(indexData) && indexData.length <= 0) || !indexData) {
      indexData = [idx];
    } else {
      indexData.push(idx);
    }
    this.setState({
      [e.target.name]: e.target.value,
      [`answer-uuid-${idx}`]: uuid,
      ansConfigIndex: idx,
      indexData,
    });
  }

  handleSubmitRegData = () => {
    const {
      candidateProfile: {
        documents,
        studentprofile: {
          studentProfileId,
          answerConfig: propAnsConfig,
          faculty: {
            studentUploadDocs,
            facultyId,
            questionConfig,
          } = {},
        } = {},
      } = {},
    } = this.props;
    let documentTypeIds = [];
    let finalStudentDocs = [];
    if (documents && Array.isArray(documents) && documents.length > 0) {
      documentTypeIds = documents.map(res => res.typeId);
      if (studentUploadDocs && Array.isArray(studentUploadDocs) && studentUploadDocs.length > 0) {
        finalStudentDocs = studentUploadDocs.filter(res => res && !(documentTypeIds.includes(res.typeId)));
      }
    } else {
      finalStudentDocs = studentUploadDocs;
    }
    const {
      indexData,
    } = this.state;
    const finalData = [...new Set(indexData)];
    const propArrConfigLength = (propAnsConfig && Array.isArray(propAnsConfig) && propAnsConfig.length) || 0;
    let finalAns = finalData && Array.isArray(finalData) && finalData.length > 0
      && finalData.map((res) => {
        const data = { uuid: this.state[`answer-uuid-${res}`], answer: this.state[`answer-${res}`] };
        return data;
      });
    let propAns = [];
    if (propArrConfigLength > 0) {
      propAns = propAnsConfig.map((res) => {
        const {
          answer,
          uuid,
        } = res || {};
        const data = {
          answer,
          uuid,
        };
        return data;
      });
    }
    if (propAns && Array.isArray(propAns) && propAns.length > 0) {
      if (finalAns && Array.isArray(finalAns) && finalAns.length > 0) {
        finalAns.push(...propAns);
      } else {
        finalAns = propAns;
      }
    } else {
      finalAns = finalData && Array.isArray(finalData) && finalData.length > 0
        && finalData.map((res) => {
          const data = { uuid: this.state[`answer-uuid-${res}`], answer: this.state[`answer-${res}`] };
          return data;
        });
    }
    this.setState({
      answerConfig: finalAns,
    }, () => {
      const {
        answerConfig,
      } = this.state;
      const quesConfigLength = questionConfig && Array.isArray(questionConfig) && questionConfig.length;
      const answerConfigLength = answerConfig && Array.isArray(answerConfig) && answerConfig.length;
      if (finalStudentDocs && Array.isArray(finalStudentDocs) && finalStudentDocs.length > 0) {
        this.setState({
          showFinalDocsErr: true,
        });
      } else if ((questionConfig && Array.isArray(questionConfig) && questionConfig.length > 0) ? ((quesConfigLength !== answerConfigLength) || !answerConfig) : false) {
        this.setState({
          showNoAnsErrMsg: true,
          showFinalDocsErr: false,
        });
      } else {
        const data = {
          isAppSubmitted: true,
          answerConfig,
          facultyId,
        };

        this.props.updateStudentProfile(data, studentProfileId).then((res) => {
          if (res && !res.error) {
            this.setState({
              showRegCompletedMsg: true,
              showNoAnsErrMsg: false,
            });
          }
        }).catch(e => console.log('Error:::::', e));
      }
    });
  }

  handleRedirectStudentProfPage = () => {
    const {
      history: {
        push,
      } = {},
      user: {
        roleId,
      } = {},
      candidateProfile: {
        profile: {
          profileId,
        } = {},
        studentprofile: {
          studentProfileId,
        } = {},
      } = {},
    } = this.props;
    if (push) {
      if (roleId && Number(roleId) === 3) {
        push('/app/profile');
      } else {
        push(`/app/campus/profile/${profileId}/student/${studentProfileId}/tab/student-profile`);
      }
    }
  }

  handleScholarToggleChange = (profileId, isScholar) => {
    this.setState({
      scholar: isScholar
    })
    let data = {
      profileId: Number(profileId),
      scholar: isScholar,
    };
    if (profileId) {
      this.setState({ scholarChangeLoader: true }, () => {
        this.props.editCandidateProfile(data).then(() => {
          this.props.getCandidateProfile(profileId).then(() => {
            this.setState({ scholarChangeLoader: false, isOpenScholarModal: false });
          });
        });
      });
    }
  };

  activeScholarModal = (isScholar) => {
    this.setState({
      isOpenScholarModal: true,
      isScholar: isScholar
    });
  };

  dismissScholarModal = () => {
    this.setState({
      isOpenScholarModal: false,
    });
  };

  onBatchChange = (id, value, name) => {
    this.setState({ eBatch: value, eBatchInvalid: false });
  }

  render() {
    const {
      t,
      taskId,
      allCountries,
      candidateProfile,
      candidateProfile: {
        documents,
        profile,
        studentprofile: {
          currentGpa: propCurrGpa,
          courses: {
            courseName,
          } = {},
          isAppSubmitted,
          loContactNumber,
          loEmail,
          loName,
          answerConfig,
          faculty: {
            cvTemplate,
            additionalDocLink,
            studentUploadDocs,
            questionConfig,
            courses,
          } = {},
        } = {},
        skills,
        interviewCompletionStatus = {},
        positionOfferStatus = {},
      } = {},
      user: {
        roleId,
        isAdmin, isSuperAdmin, company: userCompany
      } = {},
      selectedTab,
      showSteps,
      detailsTab,
      loading,
      user,
      hideBioEcd,
    } = this.props;
    const {
      profileId,
      applications,
      // scholar
      profilingData
    } = profile || {};
    let profilingDataDoc;
    if(profilingData){
      profilingDataDoc = parse(profilingData)
    }
    const { ctypeId } = userCompany || {};
    let documentTypeIds = [];
    let finalStudentDocs = []
    if (documents && Array.isArray(documents) && documents.length > 0) {
      documentTypeIds = documents.map(res => res.typeId);
      if (studentUploadDocs && Array.isArray(studentUploadDocs) && studentUploadDocs.length > 0) {
        finalStudentDocs = studentUploadDocs.filter(res => res && !(documentTypeIds.includes(res.typeId)));
      }
    } else {
      finalStudentDocs = studentUploadDocs;
    }
    const containerStyle = {
      border: '1px solid rgb(235, 237, 240)',
      marginBottom: 24,
    };
    let studentResumeDocs = [];
    if (documents && Array.isArray(documents) && documents.length > 0) {
      studentResumeDocs = documents.filter(res => res && res.typeId === 1);
    }
    const {
      personalData,
      currStep,
      showDocsError,
      uploadResumeClicked,
      activeModal,
      portfolioLink,
      countryFull,
      residentStatus,
      currentGpa,
      yearOfStudy,
      semOfStudy,
      chineseOGrade,
      chineseHigherOGrade,
      showBioInfo1Err,
      showBioApiErr,
      showError,
      prefSkillsArr,
      skillLearnType,
      showStudentLearnsErr,
      companyTypesArr,
      ctypePref,
      maxCtypePrefMsg,
      maxSkillsPref,
      noCompPrefMsg,
      preferenceTaskOriented,
      preferenceBackoffice,
      showRegCompletedMsg,
      showNoAnsErrMsg,
      personMobileNumber,
      countryId,
      isValidCountryId,
      isValidCountryIdValue,
      uploadLoader,
      addressLine1, addressLine2, zipcode, country,
      city, officeName, isValidAddrLine1, isValidAddrLine1Text,
      isValidCity, gender,
      isValidCityText, isValidCountry, isValidCountryText,
      location, isValidZipcode, isValidZipcodeText,
      showDropDownLoading, locationsArr, courseId, scholarChangeLoader, isOpenScholarModal,
      isScholar,
      showUpdateNameModal,
      eFirstName,
      eFirstNameInvalid,
      eLastName,
      eLastNameInvalid,
      showUpdateNameSuccess,
      showUpdateNameError,
      mailingAddress,
      isMailingAddressInvalid,
      mailingAddressInvalidText,
      showUpdateBatchModal,
      batchOptions,
      eBatch,
      eBatchInvalid,
    } = this.state;
    const stepsArr = [
      `${t('personalInfo')}`,
      `${t('uploadResume')}`,
      `${t('geipMsg1')}`,
      `${t('geipMsg2')}`,
      `${t('preferences')}`,
      `${t('additionInfo')}`,
    ];
    return (
      <Fragment>
        {
          showRegCompletedMsg === false && (
            <Fragment>
              {
                showSteps !== false && (
                  <Fragment>
                    <BackRow paths={this.paths} />
                    <WizardSteps
                      header={t('applRP')}
                      handleRedirectToHome={this.handleRedirectHomePage}
                      currStep={currStep}
                      hideCompanyDetTerms={false}
                      onChange={this.handleOnStepsChange}
                      handleFinalStep={this.handleSubmitRegData}
                      state={this.state}
                      stepsArr={stepsArr}
                      handleOnBackSteps={this.handleOnBackSteps}
                    />
                  </Fragment>
                )
              }
              {
                ((currStep === 0 && showSteps !== false) || (detailsTab === null)) && (
                  <div className="mt-2 ml-2">
                    <div className="h6">
                      {t('personalInfo')}
                    </div>
                    <hr />
                    {showUpdateNameSuccess && (
                      <InlineNotification
                        lowContrast
                        kind="success"
                        className=""
                        title=""
                        subtitle={<span className="">{t('Successfully updated name!')}</span>}
                        onCloseButtonClick={() => this.setState({ showUpdateNameSuccess: false })}
                      />
                    )}
                    {showUpdateNameError && (
                      <InlineNotification
                        lowContrast
                        kind="error"
                        className=""
                        title=""
                        subtitle={<span className="text-danger font-weight-bold">{t('Sorry, something went wrong. Unable to update name.')}</span>}
                        onCloseButtonClick={() => this.setState({ showUpdateNameError: false })}
                      />
                    )}
                    <div className="bx--row">
                      <div className="bx--col-lg-6">
                        {
                          personalData && Array.isArray(personalData)
                          && personalData.length > 0
                          && personalData.map((res) => {
                            const {
                              title,
                              value,
                              showCapitalize,
                            } = res || {};
                            return (
                              <div className="bx--row align-items-center mb-2">
                                <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-2 bx--col-xs-2 bx--type-zeta">
                                  {t(title)}
                                </div>
                                {
                                  showCapitalize === true ? (
                                    <div className="text-capitalize bx--col-sm-2 pl-0">
                                      {value}
                                    </div>
                                  ) : (
                                    <div className="d-flex">
                                      <div>
                                        <svg width="16" height="9" viewBox="0 0 16 11">
                                          <path d="M2.028 1l5.977 4.38L13.974 1H2.028zM15 1.487L8.3 6.403a.5.5 0 0 1-.59 0L1 1.486V10h14V1.487zM1 0h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z"></path>
                                        </svg>
                                      </div>
                                      <div>
                                        <span className="text-dark font-weight-bold ml-2 mt-0">
                                          <a className="text-lowercase" href={`mailto:${value}`}>{value}</a>
                                        </span>
                                      </div>
                                    </div>
                                  )
                                }
                              </div>
                            );
                          })
                        }
                        {(parseInt(roleId) === 1 || (parseInt(roleId) === 4 && parseInt(ctypeId) === 2)) &&
                          <div>
                            <div className="bx--row align-items-center mb-2">
                              <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-2 bx--col-xs-2 bx--type-zeta">
                              LO Name
                              </div>
                                {loName ? loName : '-'}
                            </div>
                            <div className="bx--row align-items-center mb-2">
                              <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-2 bx--col-xs-2 bx--type-zeta">
                              LO Email
                              </div>
                                {loEmail ? loEmail : '-'}
                            </div>
                            <div className="bx--row align-items-center mb-2">
                              <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-2 bx--col-xs-2 bx--type-zeta">
                              LO Contact Number
                              </div>
                                {loContactNumber ? loContactNumber : '-'}
                            </div>
                          </div>  
                        }
                        {(parseInt(roleId) === 1 || (parseInt(roleId) === 4 && parseInt(ctypeId) === 2)) &&
                          <div className="bx--row align-items-center mb-2">
                            <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-2 bx--col-xs-2 bx--type-zeta">
                              Tag student as scholar
                            </div>
                            <Toggle
                              id="toggle-4"
                              onToggle={val => this.activeScholarModal(val)}
                              toggled={isScholar}
                              labelA={t('no')}
                              labelB={t('yes')}
                            />
                            <Modal
                              open={isOpenScholarModal}
                              modalHeading={t('scholarHeading')}
                              primaryButtonText={t('yes')}
                              secondaryButtonText={t('no')}
                              iconDescription="Close the modal"
                              onRequestClose={this.dismissScholarModal}
                              onRequestSubmit={() => {
                                this.handleScholarToggleChange(profileId, isScholar)
                              }
                              }
                            >
                              {
                                isScholar === true ? (
                                  t('scholarMsg')
                                )
                                  : t('scholarNoMsg')
                              }
                              <JDProcessOverlay
                                show={this.state && this.state.scholarChangeLoader}
                                message="processing..."
                              />
                            </Modal>
                          </div>
                        }
                      </div>
                      {parseInt(roleId) !== 3 && profilingDataDoc && <div className="bx--col-lg-6">
                        <div className="xpa-profiling-data-card m-1 p-3">
                          {profilingDataDoc}
                        </div>
                      </div>}
                    </div>
                    
                   
                    <hr />
                    {(isSuperAdmin || isAdmin) && ctypeId && Number(ctypeId) == 2 ? (
                      <div>
                        {applications && Array.isArray(applications) && applications.length > 0 && (
                          <div className="h6">
                            {t('applicationStatus')}
                            <hr />
                            <StructuredListWrapper ariaLabel="Structured list">
                              <StructuredListHead>
                                <StructuredListRow
                                  head
                                  tabIndex={0}
                                >
                                  <StructuredListCell head className="align-baseline">
                                    Company Name
                                  </StructuredListCell>
                                  <StructuredListCell head className="align-baseline">
                                    Job Title
                                  </StructuredListCell>
                                  <StructuredListCell head className="align-baseline">
                                    Shortlisted
                                  </StructuredListCell>
                                  <StructuredListCell head>
                                    Interview Completion Status
                                  </StructuredListCell>
                                  <StructuredListCell head>
                                    Position Offer Status
                                  </StructuredListCell>
                                  <StructuredListCell head>
                                    Placement Completion Status
                                  </StructuredListCell>
                                </StructuredListRow>
                              </StructuredListHead>
                              <StructuredListBody>
                                {
                                  applications && Array.isArray(applications)
                                  && applications.length > 0
                                  && applications.map((resp) => {
                                    let shortlistedStatus = '-'
                                    let placementStatus = '-'
                                    let interviewcompletionstatus = '-'
                                    const {
                                      appId,
                                      shortlisted,
                                      isHired,
                                      job: {
                                        jobname: {
                                          nameName,
                                        } = {},
                                        jobId,
                                        company: {
                                          companyName,
                                          displayCompanyName,
                                          companyId
                                        } = {},
                                      } = {},
                                    } = resp
                                    if (shortlisted) {
                                      shortlistedStatus = 'Yes'
                                    }
                                    else if (shortlisted == false) {
                                      shortlistedStatus = 'No'
                                    }
                                    if (isHired) {
                                      placementStatus = 'Yes'
                                    }
                                    else if (isHired == false) {
                                      placementStatus = 'No'
                                    }
                                    return (
                                      <StructuredListRow head tabIndex={0}>
                                        <StructuredListCell>
                                          <span className="xpa-link" onClick={()=>{
                                            this.props.history.push(`/app/campus/company/info/${companyId}`)
                                          }}>{displayCompanyName}</span>
                                        </StructuredListCell>
                                        <StructuredListCell>
                                          <span className="xpa-link text-capitalize" onClick={()=>{
                                            this.props.history.push(`/app/campus/job/${jobId}`)
                                          }}>{nameName}</span>
                                        </StructuredListCell>
                                        <StructuredListCell>
                                          <span className="xpa-link" onClick={()=>{
                                            this.props.history.push(`/app/campus/candidate/${appId}/job/${jobId}/candidateprofile`)
                                          }}>{shortlistedStatus}</span>
                                        </StructuredListCell>
                                        <StructuredListCell>
                                          <span className="xpa-link" onClick={()=>{
                                            this.props.history.push(`/app/campus/candidate/${appId}/job/${jobId}/candidateprofile`)
                                          }}>{interviewCompletionStatus[appId] || '-'}</span>
                                        </StructuredListCell>
                                        <StructuredListCell>
                                          <span className="xpa-link" onClick={()=>{
                                            this.props.history.push(`/app/campus/candidate/${appId}/job/${jobId}/candidateprofile`)
                                          }}>{positionOfferStatus[appId] || '-'}</span>
                                        </StructuredListCell>
                                        <StructuredListCell>
                                          <span className="xpa-link" onClick={()=>{
                                            this.props.history.push(`/app/campus/candidate/${appId}/job/${jobId}/candidateprofile`)
                                          }}>{placementStatus}</span>
                                        </StructuredListCell>
                                      </StructuredListRow>
                                    )
                                  })
                                }
                              </StructuredListBody>
                            </StructuredListWrapper>
                          </div>
                        )}
                      </div>
                    ) : ''}
                  </div>
                )
              }
              {
                currStep === 1 && showSteps !== false && (
                  <div>
                    <div className="h6">
                      {t('uploadResume')}
                    </div>
                    <hr />
                    {
                      showDocsError === true && (
                        <InlineNotification
                          lowContrast
                          kind="error"
                          className=""
                          title=""
                          subtitle={<span className="text-danger font-weight-bold">{t('uploadResErr')}</span>}
                          onCloseButtonClick={() => this.setState({ showDocsError: false })}
                        />
                      )
                    }
                    {uploadResumeClicked === true && (
                      <div className="toastCandidate">
                        <Toast taskId={taskId} successAction={this.toastSuccess} partialRefresh={this.refresh} />
                      </div>
                    )}
                    {
                      studentResumeDocs && Array.isArray(studentResumeDocs)
                        && studentResumeDocs.length <= 0
                        ? (
                          <div>
                            <div className="text-center mb-2 font-weight-bold">
                              {t('studentUploadResume')}
                            </div>
                            <div className="d-lg-flex d-md-flex justify-content-center">
                              <ResumeUpload pageType="candidate-profile" candidateProfile={candidateProfile} dismiss={this.dismissModal} active={activeModal} multipleResume={false} />
                              <Button disabled={uploadResumeClicked} onClick={this.activateModal}>{t('uploadResume')}</Button>
                              <a className="btn--color" target="_blank" href="https://drive.google.com/file/d/14p6-HWbmZ88oDbjinTpFRet3NwpOgcUy/view">
                                <Button
                                  kind="ghost"
                                  className="ml-2"
                                >
                                  {t('cvTemplate')}
                                </Button>
                              </a>
                              {
                                additionalDocLink && (
                                  <a className="btn--color" target="_blank" href={additionalDocLink}>
                                    <Button
                                      kind="ghost"
                                      className="ml-lg-2 ml-md-2"
                                    >
                                      {t('additionalDocLink')}
                                    </Button>
                                  </a>
                                )
                              }
                            </div>
                            {uploadResumeClicked === true && (
                              <div className="d-flex justify-content-center align-items-center">
                                <div data-loading className="bx--loading bx--loading--small  d-inline-block">
                                  <svg className="bx--loading__svg" viewBox="-75 -75 150 150">
                                    <circle cx="0" cy="0" r="37.5" />
                                  </svg>
                                </div>
                                <div>
                                  {t('uploadResumeMsg')}
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                            <div className="text-center bx--type-delta">
                              {t('uploadedDocsMsg')}
                            </div>
                            <div className="mt-3">
                              <DocumentsData handleDeleteDoc={this.handleDeleteDoc} center t={t} user={user} documents={documents} />
                            </div>
                          </div>
                        )
                    }
                  </div>
                )
              }
              { ((currStep === 2 || detailsTab === 0 ) && hideBioEcd !== true) && (
                  <div>
                    <div className="h6">
                      {t('geipMsg1')}
                    </div>
                    <hr />
                    {
                      showBioInfo1Err === true && (
                        <InlineNotification
                          lowContrast
                          kind="error"
                          className=""
                          title=""
                          subtitle={<span className="text-danger font-weight-bold">{t('commonValidMsg')}</span>}
                          onCloseButtonClick={() => this.setState({ showBioInfo1Err: false })}
                        />
                      )
                    }
                    {
                      showBioApiErr === true && (
                        <InlineNotification
                          lowContrast
                          kind="error"
                          className=""
                          title=""
                          subtitle={<span className="text-danger font-weight-bold">{t('errorMsg')}</span>}
                          onCloseButtonClick={() => this.setState({ showBioApiErr: false })}
                        />
                      )
                    }
                    <div className="bx--row align-items-center mt-3">
                      <div className="bx--type-zeta bx--col-lg-3">
                        {t('gender')} <span className="req-feild">*</span>
                      </div>
                      <div className="d-flex bx--col-lg-5 p-0">
                        <RadioButtonGroup
                          name="gender"
                          disabled={showSteps === false}
                          className="ml-3"
                          id="gender"
                          defaultSelected={gender}
                          valueSelected={gender}
                          onChange={this.onresidentStatusChange}
                        >
                          <RadioButton
                            value="Male"
                            id="male"
                            disabled={showSteps === false}
                            labelText={t('male')}
                          />
                          <RadioButton
                            value="Female"
                            id="female"
                            disabled={showSteps === false}
                            labelText={t('female')}
                          />
                          {/* <RadioButton
                            value="Undisclosed"
                            id="undisclosed"
                            disabled={showSteps === false}
                            labelText={t('prefNotToDiscole')}
                          /> */}
                        </RadioButtonGroup>
                      </div>
                    </div>
                    <div className="bx--row align-items-center mt-4">
                      <div className="bx--type-zeta bx--col-lg-3">
                        {t('portfolioLink')}
                      </div>
                      <div className="bx--col-lg-5">
                        <TextInput
                          placeholder="www.myportfolio.com"
                          labelText=""
                          hideLabel
                          name="portfolioLink"
                          disabled={showSteps === false}
                          max="20"
                          value={portfolioLink || ''}
                          onChange={this.handleChange}
                          id="portfolioLink"
                        />
                      </div>
                    </div>
                    <div className="bx--row align-items-center mt-3">
                      <div className="bx--type-zeta bx--col-lg-3">
                        {t('residentStatus')} <span className="req-feild">*</span>
                      </div>
                      <div className="d-flex bx--col-lg-5 p-0">
                        <RadioButtonGroup
                          name="residentStatus"
                          disabled={showSteps === false}
                          className="ml-3"
                          id="residentStatus"
                          defaultSelected={residentStatus}
                          valueSelected={residentStatus}
                          onChange={this.onresidentStatusChange}
                        >
                          <RadioButton
                            value="singapore"
                            id="singapore"
                            disabled={showSteps === false}
                            labelText={t('singapore')}
                          />
                          <RadioButton
                            value="singapore-pr"
                            id="singapore-pr"
                            disabled={showSteps === false}
                            labelText={t('singaporePr')}
                          />
                          <RadioButton
                            value="others"
                            id="others"
                            disabled={showSteps === false}
                            labelText={t('others')}
                          />
                        </RadioButtonGroup>
                      </div>
                    </div>
                    <div className="bx--col bx--row align-items-center mt-2 mb-2 m-0 p-0">
                      <div className="col-sm-12 mt-2 col-md-3 form-label bx--type-zeta mb-1" />
                      {
                        residentStatus && residentStatus === 'others'
                        && (
                          <div className="ml-3">
                            <Select
                              name="countryFull"
                              labelText=""
                              hideLabel
                              disabled={showSteps === false}
                              id="countryFull"
                              onChange={e => this.handleChange(e)}
                              value={countryFull || {}}
                            >
                              <SelectItem text="Select" />
                              {
                                allCountries && Array.isArray(allCountries) && allCountries.map((b, i) => (
                                  <SelectItem key={i} text={b.countryFull} value={`${b.countryFull}`} />
                                ))
                              }
                            </Select>
                          </div>
                        )
                      }
                    </div>
                    {
                      roleId && Number(roleId) !== 3 && !propCurrGpa && (
                        <div className="bx--row align-items-center mt-4">
                          <div className="bx--type-zeta bx--col-lg-3">
                            {t('studentGpa')} <span className="req-feild">*</span>
                          </div>
                          <div className="bx--col-lg-5">
                            <TextInput
                              placeholder={t('studentGpa')}
                              labelText=""
                              hideLabel
                              type="number"
                              name="currentGpa"
                              disabled={showSteps === false}
                              max="20"
                              value={currentGpa || ''}
                              onChange={this.handleChange}
                              id="currentGpa"
                            />
                          </div>
                        </div>
                      )
                    }
                    {
                      roleId && Number(roleId) !== 3 && !courseName && (
                        <div className="bx--row align-items-center">
                          <div className="bx--type-zeta bx--col-lg-3">
                            {t('courses')} <span className="req-feild">*</span>
                          </div>
                          <div className="mt-2 mr-2 bx--col-lg-5">
                            <Select
                              name="courseId"
                              labelText="Country"
                              id="courseId"
                              hideLabel
                              onChange={e => this.handleChange(e)}
                              value={courseId}
                              disabled={showSteps === false}
                              className="w-100 select-input-width"
                            >
                              <SelectItem text="Select" />
                              {
                                courses && Array.isArray(courses) && courses.map(b => (
                                  <SelectItem key={b.countryId} text={b.courseName} value={b.courseId} />
                                ))
                              }
                            </Select>
                          </div>
                        </div>
                      )
                    }
                    <div className="bx--row align-items-center mt-4">
                      <div className="bx--type-zeta bx--col-lg-3">
                        {t('phone')} <span className="req-feild">*</span>
                      </div>
                      <div className="bx--col-lg-5 d-flex">
                        <div className="mt-2 mr-2">
                          <Select
                            name="countryId"
                            labelText="Country"
                            id="countryId"
                            hideLabel
                            onChange={e => this.handleChange(e)}
                            value={countryId}
                            disabled={showSteps === false}
                            className="w-100"
                            invalid={isValidCountryId}
                            invalidText={isValidCountryIdValue}
                          >
                            <SelectItem text="Select" />
                            {
                              allCountries && Array.isArray(allCountries) && allCountries.map(b => (
                                <SelectItem key={b.countryId} text={`${b.countryFull}(+${b.countryCode})`} value={`${b.countryId}`} />
                              ))
                            }
                          </Select>
                        </div>
                        <div className="ml-2 mt-2">
                          <TextInput
                            placeholder="##########"
                            labelText=""
                            hideLabel
                            type="number"
                            invalid={this.state.isValidPersonNumber}
                            invalidText={t('phoneNoValidation')}
                            name="personMobileNumber"
                            disabled={showSteps === false}
                            max="8"
                            value={personMobileNumber || ''}
                            onChange={this.handleChange}
                            id="personMobileNumber"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="text-uppercase text-dark mb-2 mt-2">
                      {t('address')}
                    </div>
                    <hr />
                    <div className="w-50 small-devices-width">
                      <OfficeDetails
                        countries={allCountries}
                        handleChange={this.handleChange}
                        city={city}
                        zipcode={zipcode}
                        officeName={officeName}
                        addressLine1={addressLine1}
                        addressLine2={addressLine2}
                        country={country}
                        showOfcInput={false}
                        showDropDownLoading={showDropDownLoading}
                        isValidAddrLine1={isValidAddrLine1}
                        isValidAddrLine1Text={isValidAddrLine1Text}
                        isValidCity={isValidCity}
                        isValidCityText={isValidCityText}
                        isValidCountry={isValidCountry}
                        isValidCountryText={isValidCountryText}
                        isValidZipcode={isValidZipcode}
                        isValidZipcodeText={isValidZipcodeText}
                        disabled={showSteps === false}
                        location={location}
                        handleDropdownChange={this.handleDropdownChange}
                        onInputDropdownChange={this.onInputDropdownChange}
                        locationsArr={locationsArr}
                        mailingAddress={mailingAddress}
                        isMailingAddressInvalid={isMailingAddressInvalid}
                        mailingAddressInvalidText={mailingAddressInvalidText}
                      />
                    </div>
                    {/* <div className="bx--row align-items-center mt-3">
                      <div className="bx--type-zeta bx--col-lg-3">
                        {t('currentYearOfStudy')}
                      </div>
                      <div className="bx--col-lg-5 p-0">
                        <DropDownBox
                          title=""
                          required
                          labelClass=""
                          titleClass=""
                          disabled={showSteps === false}
                          mainClassName="bx--form-item mb-0"
                          className="w-100"
                          options={yearsOfStudy.map((year, i) => ({ label: year, value: i + 1 }))}
                          name="yearOfStudy"
                          placeholder={t('year')}
                          onChange={(id, value, name) => this.onDropdownChange(value, name)}
                          selectedValue={yearOfStudy}
                        />
                      </div>
                    </div>
                    <div className="bx--row align-items-center mt-3">
                      <div className="bx--type-zeta bx--col-lg-3">
                        {t('semester')}
                      </div>
                      <DropDownBox
                        title=""
                        required
                        labelClass=""
                        titleClass=""
                        disabled={showSteps === false}
                        mainClassName="bx--form-item mb-0"
                        className="w-100"
                        options={semestersOfStydy.map((sem, i) => ({ label: sem, value: i + 1 }))}
                        name="semOfStudy"
                        placeholder={t('semester')}
                        onChange={(id, value, name) => this.onDropdownChange(value, name)}
                        selectedValue={semOfStudy}
                      />
                    </div> */}
                    {/* <div className="bx--row align-items-center mt-3">
                      <div className="bx--type-zeta bx--col-lg-3">
                        {t('chineseGrade')}
                      </div>
                      <div className="bx--col-md-5 p-0">
                        <DropDownBox
                          title=""
                          required
                          labelClass=""
                          titleClass=""
                          disabled={showSteps === false}
                          mainClassName="bx--form-item mb-0"
                          className="w-100"
                          options={chineseGrades.map((one, i) => ({ value: i + 1, label: one }))}
                          name="chineseOGrade"
                          placeholder={t('grade')}
                          onChange={(id, value, name) => this.onDropdownChange(value, name)}
                          selectedValue={chineseOGrade}
                        />
                      </div>
                    </div>
                    <div className="bx--row align-items-center mt-3">
                      <div className="bx--type-zeta bx--col-lg-3">
                        {t('higherChineseGrade')}
                      </div>
                      <div className="bx--col-md-5 p-0">
                        <DropDownBox
                          title=""
                          required
                          labelClass=""
                          titleClass=""
                          disabled={showSteps === false}
                          mainClassName="bx--form-item mb-0"
                          className="w-100"
                          options={chineseGrades.map((one, i) => ({ value: i + 1, label: one }))}
                          name="chineseHigherOGrade"
                          placeholder={t('grade')}
                          onChange={(id, value, name) => this.onDropdownChange(value, name)}
                          selectedValue={chineseHigherOGrade}
                        />
                      </div>
                    </div> */}
                  </div>
                )
              }
              {
                ((currStep === 3 || detailsTab === 1) && hideBioEcd !== true) && (
                  <div className="p-1 ml-0 w-100 mb-3">
                    <div className="h6">
                      {t('geipMsg2')}
                    </div>
                    <hr />
                    <CandidateDetails
                      candidateProfile={candidateProfile}
                      profileId={profileId}
                      roleId={roleId}
                      notEditable={showSteps === false}
                      noBio
                      updateSkills={updateSkills}
                      updateEduction={updateEduction}
                      updateExperience={updateExperience}
                      addEduction={addEduction}
                      addExperience={addExperience}
                      showError={showError}
                    />
                  </div>
                )
              }
              {
                (currStep === 4 || detailsTab === 2) && (
                  <div>
                    <div className="h6">
                      {t('preferences')}
                    </div>
                    <hr />
                    {
                      noCompPrefMsg === true && (
                        <InlineNotification
                          lowContrast
                          kind="error"
                          className="bx--col"
                          title=""
                          subtitle={<span className="text-danger font-weight-bold">{t('noCompPrefMsg')}</span>}
                          onCloseButtonClick={() => this.setState({ noCompPrefMsg: false })}
                        />
                      )
                    }
                    {
                      maxCtypePrefMsg === true && (
                        <InlineNotification
                          lowContrast
                          kind="error"
                          className="bx--col"
                          title=""
                          subtitle={<span className="text-danger font-weight-bold">{t('maxCtypePrefMsg')}</span>}
                          onCloseButtonClick={() => this.setState({ maxCtypePrefMsg: false })}
                        />
                      )
                    }
                    {/* <h6 className="text-dark font-weight-bold">
                      {t('companyPrefMsg')}
                    </h6>
                    <div className="bx--row mt-3 align-items-center">
                      <div className="bx--type-zeta bx--col-lg-3">
                        {t('companyPref')}
                      </div>
                      <div className="bx--col-lg-5 p-0 m-0">
                        <DropDownBox
                          title=""
                          required
                          labelClass=""
                          disabled={showSteps === false}
                          titleClass=""
                          mainClassName="bx--form-item mb-0"
                          className="w-100"
                          isMulti
                          options={companyTypesArr}
                          placeholder={t('companyPref')}
                          onChange={(id, value, name) => this.onCompanyprefDropDown(value, name)}
                          name="ctypePref"
                          selectedValue={ctypePref}
                        />
                      </div>
                    </div>
                    <hr /> */}
                    {
                      showStudentLearnsErr === true && (
                        <InlineNotification
                          lowContrast
                          kind="error"
                          className="bx--col"
                          title=""
                          subtitle={<span className="text-danger font-weight-bold">{t('noSkillsLearnMsg')}</span>}
                          onCloseButtonClick={() => this.setState({ showStudentLearnsErr: false })}
                        />
                      )
                    }
                    {
                      this.state.showApiErr === true && (
                        <InlineNotification
                          lowContrast
                          kind="error"
                          className="bx--col"
                          title=""
                          subtitle={<span className="text-danger font-weight-bold">{t('errorMsg')}</span>}
                          onCloseButtonClick={() => this.setState({ showApiErr: false })}
                        />
                      )
                    }
                    {
                      maxSkillsPref === true && (
                        <InlineNotification
                          lowContrast
                          kind="error"
                          className="bx--col"
                          title=""
                          subtitle={<span className="text-danger font-weight-bold">{t('maxSkillPrefMsg')}</span>}
                          onCloseButtonClick={() => this.setState({ maxSkillsPref: false })}
                        />
                      )
                    }
                    <h6 className="text-dark font-weight-bold">
                      {t('studentLearnMsg')}
                    </h6>
                    <div className="bx--row mt-3 align-items-center">
                      <div className="bx--type-zeta bx--col-lg-3">
                        {t('geipMsg4')}
                      </div>
                      <div className="bx--col-lg-5 p-0 m-0" style={{zIndex:4}}>
                        <DropDownBoxWithCreate
                          // isAsync
                          multi
                          mainClassName="bx--col-12 p-0"
                          custom
                          options={prefSkillsArr}
                          disabled={showSteps === false}
                          isClearable={false}
                          newOptionCreator={this.onNewOptionCreate}
                          onInputChange={this.onSkillsInputChange}
                          placeholder={t('skills')}
                          onChange={this.handleSkillChange}
                          name="skillLearnType"
                          selectedValue={skillLearnType}
                        />
                        {/* <DropDownBox
                          title=""
                          required
                          labelClass=""
                          disabled={showSteps === false}
                          titleClass=""
                          mainClassName="bx--form-item mb-0"
                          className="w-100"
                          isMulti
                          onInputChange={this.onSkillsInputChange}
                          options={prefSkillsArr}
                          placeholder={t('skills')}
                          onChange={this.handleSkillChange}
                          name="skillLearnType"
                          selectedValue={skillLearnType}
                        /> */}
                      </div>
                    </div>
                    <hr />
                    <h6 className="text-dark font-weight-bold">
                      {t('sliderTitle')}
                    </h6>
                    <div className="bx--row mt-3 align-items-center">
                      <div className="bx--type-zeta bx--col-lg-3">
                        {t('jobPreferences')}
                      </div>
                      <div className="bx--col-lg-8 mb-4 ml-3 p-0 m-0">
                        <PercentageSlider
                          hideSideLabels
                          minLabel={t('taskOrientedPreference')}
                          maxLabel={t('independentPreference')}
                          disabled={showSteps === false}
                          value={Number(preferenceTaskOriented)}
                          name="preferenceTaskOriented"
                          onChange={this.onSliderChange}
                        />
                        <PercentageSlider
                          hideSideLabels
                          minLabel={t('backEndPreference')}
                          disabled={showSteps === false}
                          maxLabel={t('frontEndPreference')}
                          value={Number(preferenceBackoffice)}
                          name="preferenceBackoffice"
                          onChange={this.onSliderChange}
                        />
                      </div>
                    </div>
                    <div className="text-uppercase">
                      {t('legend')}
                    </div>
                    <div className="mb-2 bx--type-zeta">
                      {t('taskOrientedPreference')}
                    </div>
                    <div className="mb-2 text-secondary">
                      {t('prefMsg1')}
                    </div>
                    <div className="mb-2 bx--type-zeta">
                      {t('independentPreference')}
                    </div>
                    <div className="mb-2 text-secondary">
                      {t('prefMsg2')}
                    </div>
                    <div className="mb-2 bx--type-zeta">
                      {t('backEndPreference')}
                    </div>
                    <div className="mb-2 text-secondary">
                      {t('prefMsg3')}
                    </div>
                    <div className="mb-2 bx--type-zeta">
                      {t('frontEndPreference')}
                    </div>
                    <div className="mb-5 text-secondary">
                      {t('prefMsg4')}
                    </div>
                    {/* {
                        showSteps !== false && (
                          <div>
                            <hr />
                            <h6 className="text-dark font-weight-bold">
                              {t('skillsCompMsg')}
                            </h6>
                            <div className="bx--row">
                              <div className="bx--type-zeta bx--col-lg-3">
                                {t('geipMsg3')}
                              </div>
                              <div className="bx--col-lg-5">
                                <SkillsCompetency skills={skills} onStarClick={this.onStarClick} />
                              </div>
                            </div>
                          </div>
                        )
                      } */}
                  </div>
                )
              }
              {
                (currStep === 5 || detailsTab === 3) && (
                  <div>
                    <div className="bx--type-epsilon">
                      {t('geipMsg7')}
                    </div>
                    {
                      this.state.showFinalDocsErr === true && (
                        <InlineNotification
                          lowContrast
                          kind="error"
                          className="bx--col"
                          title=""
                          subtitle={<span className="text-danger font-weight-bold">Please upload all documents to proceed.</span>}
                          onCloseButtonClick={() => this.setState({ showFinalDocsErr: false })}
                        />
                      )
                    }
                    {
                      studentUploadDocs && Array.isArray(studentUploadDocs)
                        && studentUploadDocs.length > 0
                        ? (
                          <div>
                            <div className="mb-2 text-dark mt-2">
                              Uploaded documents
                            </div>
                            <div className="d-flex">
                              <div className="mt-3 w-100">
                                <DocumentsData handleDeleteDoc={this.handleDeleteDoc} t={t} user={user} center={false} documents={documents} />
                                <hr />
                                {
                                  finalStudentDocs && Array.isArray(finalStudentDocs) && finalStudentDocs.length > 0
                                  && (
                                    <div>
                                      <div className="mb-2 text-dark">Please upload the following documents</div>
                                      <FacultyUploadedDocs handleUploadFile={this.handleUploadFile} disabled={showSteps === false} studentUploadDocs={finalStudentDocs} showUploadBtn handleDeleteStdDocs={() => { }} />
                                      <hr />
                                    </div>
                                  )
                                }

                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="mt-2 font-weight-bold text-dark">
                            {t('noDocsUplMsg')}
                          </div>
                        )
                    }
                    <div className="bx--type-zeta">
                      {t('uploadAdditionalDocs')}
                    </div>
                    <UploadAllDocuments disabled={showSteps === false} candidateProfile={candidateProfile} />
                    <hr />
                    {
                      showNoAnsErrMsg === true && (
                        <InlineNotification
                          lowContrast
                          kind="error"
                          className="bx--col"
                          title=""
                          subtitle={<span className="text-danger font-weight-bold">{t('ansAllQuesMsg')}</span>}
                          onCloseButtonClick={() => this.setState({ showNoAnsErrMsg: false })}
                        />
                      )
                    }
                    {
                      questionConfig && Array.isArray(questionConfig)
                      && questionConfig.length > 0
                      && (
                        <div>
                          <div className="bx--type-epsilon">
                            {t('stdQuesHeading')}
                          </div>
                          <div className="mt-2">
                            <FacultyQuestionsConfig answerConfig={answerConfig} disabled={showSteps === false} state={this.state} showAnsInput questionConfig={questionConfig} handleChange={this.handleChangeAnsConfig} />
                          </div>
                        </div>
                      )
                    }
                  </div>
                )
              }
            </Fragment>
          )
        }
        {
          showRegCompletedMsg === true && (
            <div className="">
              <div className="text-center bx--type-alpha">{t('profileSaved')}</div>
              <div className="align-content-center text-center">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" style={{ maxWidth: 100 }} >
                  <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                  <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                </svg>
              </div>
              <div className="m-3 text-center bx--row justify-content-center">
                <div className="m-3">
                  <Button kind="primary" className="" onClick={this.handleRedirectStudentProfPage}>{t('view')} {t('profile')}</Button>
                </div>
              </div>
            </div>
          )
        }
        {showUpdateNameModal && (
          <Modal
            open={showUpdateNameModal}
            modalHeading={t('Update Name')}
            primaryButtonText={t('Submit')}
            secondaryButtonText={t('Cancel')}
            onRequestClose={this.dismissUpdateNameModal}
            onRequestSubmit={this.handleUpdateNameModalSubmit}
          >
            <div className='bx--row'>
              <div className='bx--col-lg-6'>
                <TextInput
                  placeholder="Enter First Name"
                  labelText="First Name"
                  name="eFirstName"
                  max="100"
                  invalid={eFirstNameInvalid}
                  invalidText={t('Please enter first name')}
                  value={eFirstName || ''}
                  onChange={this.handleChange}
                  id="eFirstName"
                />
              </div>
              <div className='bx--col-lg-6'>
                <TextInput
                  placeholder="Enter Last Name"
                  labelText="Last Name"
                  name="eLastName"
                  invalid={eLastNameInvalid}
                  invalidText={t('Please enter last name')}
                  max="100"
                  value={eLastName || ''}
                  onChange={this.handleChange}
                  id="eLastName"
                />
              </div>
            </div>
          </Modal>
        )}
        {showUpdateBatchModal && (
          <Modal
            open={showUpdateBatchModal}
            modalHeading={t('Update Batch')}
            primaryButtonText={t('Submit')}
            secondaryButtonText={t('Cancel')}
            onRequestClose={this.dismissUpdateBatchModal}
            onRequestSubmit={this.handleUpdateBatchModalSubmit}
            className='xpa-modal-height-70'
          >
            <div>
              <DropDownBox
                title={<span>{t('Select Batch')}<span className='text-danger'>*</span></span>}
                mainClassName={`m-0 ${eBatchInvalid ? 'invalid':''}`}
                titleClass="bx--select"
                labelClass="bx--label"
                custom
                placeholder={t('Select Batch')}
                options={batchOptions || []}
                name={t('eBatch')}
                onChange={this.onBatchChange}
                selectedValue={eBatch}
              />
              {eBatchInvalid === true && (
                <div className="invalid-msg">
                  {t('Please select batch.')}
                </div>
              )}
            </div>
          </Modal>
        )}
        <JDProcessOverlay
          show={loading || this.props.stdProfileLoading || uploadLoader || this.state.loading}
          message="processing..."
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
  loading: state.roboroyApplications.loading,
  stdProfileLoading: state.StudentProfile.loading,
  candidateProfile: state.roboroyApplications.candidateProfile,
  taskId: state.resumeUpload.taskId,
  allCountries: state.RegistrationRequest.countries,
  preferredSkills: state.StudentProfile.preferredSkills,
  companyTypes: state.StudentProfile.companyTypes,
  locations: state.RegistrationRequest.locations,
});

const mapDispatchToProps = {
  getCandidateProfile,
  getAllCountries,
  updateStudentProfile,
  updatePersonSkill,
  getSkillsPreferredList,
  fetchAll,
  createSkillsLearnsForStds,
  createStudentCompanyPref,
  getCompanyTypes,
  createStudentPreference,
  uploadCampusDocs,
  uploadDocument,
  editCandidateProfile,
  editProfileContact,
  getLocations,
  createNewOption,
  deleteSkillsLearnsForStdsById,
  deleteDocument,
  updateProfile,
  getBatchDetails,
};

export default
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StudentProfileRegister));
