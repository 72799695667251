const NavItemsList = [
  {
    label: 'dashboard',
    icon: 'fa fa-home',
    path: '/app/campus',
    roles: [1, 4, 5, 6, 9],
    accepts: ['/app/campus'],
    ctypeId: [1, 2],
    subItems: [],
  },
  {
    label: 'browse',
    desc: 'browseDesc',
    icon: 'fa fa-search',
    roles: [1, 4, 5, 6, 9],
    ctypeId: [2],
    subItems: [
      {
        subItemsLabel: 'companies',
        subDesc: 'companyDesc',
        subItemsIcon: 'fa fa-id-badge',
        subItemsPath: '/app/campus/browse/companies',
        accepts: ['/app/campus/browse/companies'],
        ctypeId: [2],
      },
      {
        subItemsLabel: 'institutions',
        subDesc: 'instDesc',
        subItemsIcon: 'fa fa-building',
        subItemsPath: '/app/campus/browse/institutions',
        accepts: ['/app/campus/browse/institutions'],
        ctypeId: [0],
      },
      {
        subItemsLabel: 'students',
        subDesc: 'stdDesc',
        subItemsIcon: 'fa fa-graduation-cap',
        subItemsPath: '/app/campus/browse/students',
        accepts: ['/app/campus/browse/students'],
        ctypeId: [2],
      },
      {
        subItemsLabel: 'faculty',
        subDesc: 'facultyDesc',
        subItemsIcon: 'fa fa-building',
        subItemsPath: '/app/campus/browse/faculties',
        accepts: ['/app/campus/browse/faculties'],
        ctypeId: [2],
      },
      {
        subItemsLabel: 'employees',
        subDesc: 'employeeDesc',
        subItemsIcon: 'fa fa-building',
        subItemsPath: '/app/campus/browse/employee',
        accepts: ['/app/campus/browse/employee'],
        ctypeId: [2],
      },
    ],
  },
  {
    label: 'status',
    icon: 'fa fa-users',
    desc: 'sourceDesc',
    roles: [1, 4, 5, 6, 9],
    ctypeId: [1, 2],
    accepts: [
      '/app/campus/jobs/open/', '/app/campus/jobs/closed/',
      '/app/campus/jobs/shared/page/1/limit/10/',
      '/app/campus/candidates/xopa/processing',
    ],
    subItems: [
      {
        subItemsLabel: 'jobs',
        subDesc: 'jobsDesc',
        subItemsIcon: 'fa fa-file-o',
        subItemsPath: '/app/campus/jobs/open/',
        accepts: ['/app/campus/jobs/open/', '/app/campus/jobs/closed/'],
        ctypeId: [1, 2],
      },
      // {
      //   subItemsLabel: 'sharedJobs',
      //   subItemsIcon: 'fa fa-share-alt',
      //   subItemsPath: '/app/campus/jobs/shared/page/1/limit/10/',
      //   accepts: ['/app/campus/jobs/shared/page/1/limit/10/'],
      //   ctypeId: [1, 2],
      // },
      {
        subItemsLabel: 'applicants',
        subDesc: 'applDesc',
        subItemsIcon: 'fa fa-users',
        subItemsPath: '/app/campus/candidates/xopa/processing',
        accepts: ['/app/campus/candidates/xopa/processing'],
        ctypeId: [1, 2],
      },
      {
        subItemsLabel: 'hires',
        subDesc: 'hiresDesc',
        subItemsIcon: 'fa fa-users',
        subItemsPath: '/app/campus/candidates/hires',
        accepts: ['/app/campus/candidates/hires'],
        ctypeId: [1, 2],
      },
    ],
  },
  // {
  //   label: 'interview',
  //   icon: 'fas fa-user-tie',
  //   desc: 'interviewDesc',
  //   roles: [1, 4, 5, 6, 9],
  //   ctypeId: [1, 2],
  //   accepts: [
  //     '/app/campus/interviews/status/draft',
  //     '/app/campus/interviews/status/awaiting',
  //     '/app/campus/interviews/status/awaitingfeedback',
  //     '/app/campus/interviews/status/completed',
  //     '/app/campus/interviews/status/skipped',
  //     '/app/campus/interviews-calendar',
  //   ],
  //   subItems: [
  //     {
  //       subItemsLabel: 'videoInterviews',
  //       subItemsIcon: 'fa fa-video',
  //       subItemsPath: '/app/campus/interviews/status/draft/page/1/limit/10',
  //       accepts: [
  //         '/app/campus/interviews/status/draft',
  //         '/app/campus/interviews/status/awaiting',
  //         '/app/campus/interviews/status/awaitingfeedback',
  //         '/app/campus/interviews/status/completed',
  //         '/app/campus/interviews/status/skipped',
  //         '/app/campus/interviews/status/draft/page/1/limit/10',
  //         '/app/campus/interviews/status/awaiting/page/1/limit/10',
  //         '/app/campus/interviews/status/awaitingfeedback/page/1/limit/10',
  //         '/app/campus/interviews/status/completed/page/1/limit/10',
  //         '/app/campus/interviews/status/skipped/page/1/limit/10',
  //       ],
  //       ctypeId: [1, 2],
  //     },
  //     {
  //       subItemsLabel: 'calendar',
  //       subDesc: 'calendarDesc',
  //       subItemsIcon: 'fa fa-calendar-alt',
  //       subItemsPath: '/app/campus/interviews-calendar',
  //       accepts: ['/app/campus/interviews-calendar'],
  //       ctypeId: [1, 2],
  //     },
  //   ],
  // },
  {
    label: 'offers',
    desc: 'offerDesc',
    icon: 'fa fa-handshake-o',
    roles: [1, 4, 5, 6, 9],
    ctypeId: [1, 2],
    path: '/app/campus/offers',
    subItems: [],
  },
  // {
  //   label: 'hire',
  //   desc: 'hireDesc',
  //   icon: 'fa fa-id-badge',
  //   roles: [1, 4, 5, 6, 9],
  //   ctypeId: [1, 2],
  //   accepts: ['/app/campus/offers'],
  //   subItems: [
  //     {
  //       subItemsLabel: 'offers',
  //       subDesc: 'offerDesc',
  //       subItemsIcon: 'fa fa-handshake-o',
  //       subItemsPath: '/app/campus/offers',
  //       accepts: ['/app/campus/offers'],
  //       ctypeId: [1, 2],
  //     },
  //   ],
  // },
  {
    label: 'admin',
    icon: 'fas fa-tools',
    roles: [1, 4, 5, 6, 9],
    desc: 'adminDesc',
    ctypeId: [2],
    accepts: [
      '/app/campus/browse/cities',
      '/app/campus/browse/languages',
      '/app/campus/emails',
      '/app/campus/reports',
    ],
    subItems: [
      {
        subItemsLabel: 'cities',
        subDesc: 'citiesDesc',
        subItemsIcon: 'fa fa-map-marker',
        subItemsPath: '/app/campus/browse/cities',
        accepts: ['/app/campus/browse/cities'],
        ctypeId: [0],
        cxName: "x-nav-cities"
      },
      // {
      //   subItemsLabel: 'languages',
      //   subDesc: 'languagesDesc',
      //   subItemsIcon: 'fa fa-language',
      //   subItemsPath: '/app/campus/browse/languages',
      //   accepts: ['/app/campus/browse/languages'],
      //   ctypeId: [0],
      // },
      // {
      //   subItemsLabel: 'logs',
      //   subItemsIcon: 'fa fa-list-alt',
      //   subItemsPath: '/app/campus/admin/logs',
      //   accepts: ['/app/campus/admin/logs'],
      //   ctypeId: [2],
      // },
      {
        subItemsLabel: 'emailTemplates',
        subDesc: 'emailtempDesc',
        subItemsIcon: 'fa fa-envelope-o',
        subItemsPath: '/app/campus/emails',
        accepts: ['/app/campus/emails'],
        ctypeId: [0],
        cxName: "x-nav-emails"
      },
      {
        subItemsLabel: 'Register new company',
        subDesc: 'Register new company',
        subItemsIcon: 'fa fa-envelope-o',
        subItemsPath: '/app/campus/registration/company',
        accepts: ['/app/campus/registration/company'],
        ctypeId: [0],
        cxName: "x-nav-emails"
      },
      // {
      //   subItemsLabel: 'settings',
      //   subDesc: 'settingsDec',
      //   subItemsIcon: 'fa fa-envelope-o',
      //   subItemsPath: '/app/admin',
      //   accepts: ['/app/admin'],
      //   ctypeId: [0],
      // },
      // {
      //   subItemsLabel: 'reports',
      //   subItemsIcon: 'fa fa-file-text',
      //   subItemsPath: '/app/campus/reports',
      //   accepts: ['/app/campus/reports'],
      //   ctypeId: [1, 2],
      // },
    ],
    cxName: "x-nav-top x-nav-admin"
  },
  {
    label: 'myAnalytics',
    icon: 'fa fa-bar-chart',
    path: '/app/campus/analytics',
    roles: [1, 4, 5, 6, 9],
    subItems: [],
    ctypeId: [2],
    cxName: "x-nav-analytics"
  },
  // {
  //   label: 'myAnalytics',
  //   icon: 'fa fa-bar-chart',
  //   path: '/app/campus/analyticsdetails',
  //   roles: [1, 4, 5, 6, 9],
  //   subItems: [],
  //   ctypeId: [1, 2],
  // },

  // {
  //   label: 'switchCompany',
  //   icon: 'fas fa-bolt',
  //   path: '',
  //   roles: [1, 4, 5, 6, 9],
  //   accepts: [],
  //   subItems: [],
  //   ctypeId: [1, 2],
  // },
  {
    label: 'myProfile',
    icon: 'fa fa-user-o',
    roles: [1, 4, 5, 6, 9],
    path: '/app/profile',
    accepts: ['/app/profile'],
    subItems: [],
    ctypeId: [1, 2],
    cxName: "x-nav-profile"
  },
  {
    label: 'dashboard',
    icon: 'fa fa-home',
    path: '/app/campus/candidate',
    roles: [3],
    accepts: ['/app/campus/candidate'],
    subItems: [],
    ctypeId: [1, 2],
    cxName: "x-nav-cand-dashboard"
  },
  {
    label: 'browseJobs',
    icon: 'fa fa-search',
    path: '/app/campus/candidate/browse',
    roles: [3],
    accepts: ['/app/campus/candidate/browse'],
    subItems: [],
    ctypeId: [1, 2],
    cxName: "x-nav-browse-jobs"
  },
  // {
  //   label: 'matches',
  //   icon: 'fa fa-sun-o',
  //   path: '/app/campus/candidate/recommended/jobs',
  //   roles: [3],
  //   accepts: ['/app/campus/candidate/recommended/jobs'],
  //   subItems: [],
  //   ctypeId: [1, 2],
  // },
  {
    label: 'interviews',
    icon: 'fa fa-calendar',
    path: '/app/campus/candidate/slots',
    roles: [3],
    accepts: ['/app/campus/candidate/slots'],
    subItems: [],
    ctypeId: [1, 2],
    cxName: "x-nav-candidate-slots"
  },
  // {
  //   label: 'References',
  //   icon: 'icon-user-comment',
  //   path: '/app/campus/candidate/references',
  //   roles: [3],
  //   accepts: ['/app/campus/candidate/references'],
  //   subItems: [],
  // },
  {
    label: 'profile',
    icon: 'fa fa-user-o',
    path: '/app/profile',
    roles: [3],
    ctypeId: [1, 2],
    accepts: ['/app/profile'],
    subItems: [],
    cxName: "x-nav-profile"
  },
  {
    label: 'notifications',
    icon: 'fa fa-search',
    path: '/app/campus/candidate/notifications',
    roles: [3],
    accepts: ['/app/campus/candidate/notifications'],
    subItems: [],
    ctypeId: [1, 2],
    cxName: "x-nav-notifications"
  },
  {
    label: 'notifications',
    desc: 'notifications',
    icon: 'fa fa-handshake-o',
    roles: [1, 4, 5, 6, 9],
    ctypeId: [1, 2],
    path: '/app/campus/notifications',
    subItems: [],
    cxName: "x-nav-notifications"
  },
  // {
  //   label: 'employee',
  //   desc: 'employee',
  //   icon: 'fa fa-handshake-o',
  //   roles: [1, 4],
  //   ctypeId: [2],
  //   path: '/app/campus/browse/employee',
  //   subItems: [],
  // },
  // {
  //   label: 'logout',
  //   icon: 'fa fa-sign-out',
  //   path: '/auth/logout',
  //   ctypeId: [1, 2],
  //   roles: [1, 3, 4, 5, 6, 9],
  //   accepts: ['/auth/logout'],
  //   subItems: [],
  // },
];


export default NavItemsList;
