import { withTranslation } from 'react-i18next';
import React, { Fragment } from 'react';
import {
  TextInput,
  TextArea,
  Select, SelectItem,
} from 'carbon-components-react';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import './OfficeDetails.css';

const OfficeDetails = (props) => {
  console.log("aa",props)
  const {
    handleChange,
    addressLine1,
    addressLine2,
    city,
    zipcode,
    countries,
    country,
    officeName,
    isValidAddrLine1,
    isValidAddrLine1Text,
    isValidCity,
    isValidCityText,
    isValidCountry,
    isValidCountryText,
    isValidZipcode,
    isValidZipcodeText,
    isValidOfficeNameText,
    isValidOfficeName,
    disabled,
    locationsArr,
    handleDropdownChange,
    location,
    onInputDropdownChange,
    t,
    showDropDownLoading,
    showOfcInput,
    isMailingAddressInvalid,
    mailingAddress,
    mailingAddressInvalidText,
  } = props || {};
  return (
    <Fragment>
      {
          showOfcInput === true && (
            <div className="mt-3 xs-fields-device sm-fields-device lg-fields-device">
              <TextInput
                className="mb-2"
                name="officeName"
                id="officeName"
                value={officeName}
                disabled={disabled}
                invalid={isValidOfficeName}
                invalidText={isValidOfficeNameText}
                labelText={<span>{t('office')} {t('name')}</span>}
                placeholder={`${t('office')} ${t('name')}`}
                onChange={(e) => { handleChange(e); }}
              />
            </div>
          )
        }
      <div className="mt-2   xs-fields-device sm-fields-device lg-fields-device">
        <TextInput
          className="mb-2"
          name="addressLine1"
          id="addressLine1"
          value={addressLine1}
          disabled={disabled}
          invalid={isValidAddrLine1}
          invalidText={isValidAddrLine1Text}
          labelText={<span>{t('address')} {t('line')} 1</span>}
          placeholder={`${t('address')} ${t('line')} 1`}
          onChange={(e) => { handleChange(e); }}
        />
      </div>
      <div className="mt-2   xs-fields-device sm-fields-device lg-fields-device">
        <TextInput
          className="mb-2"
          name="addressLine2"
          id="addressLine2"
          disabled={disabled}
          value={addressLine2}
          labelText={<span>{t('address')} {t('line')} 2</span>}
          placeholder={`${t('address')} ${t('line')} 2`}
          onChange={(e) => { handleChange(e); }}
        />
      </div>
      <div className="mt-2   xs-fields-device sm-fields-device lg-fields-device">
        <TextInput
          className="mb-2"
          name="zipcode"
          id="zipcode"
          value={zipcode}
          disabled={disabled}
          invalid={isValidZipcode}
          invalidText={isValidZipcodeText}
          labelText={t('zipcode')}
          placeholder={t('zipcode')}
          onChange={(e) => { handleChange(e); }}
          type="number"
        />
      </div>
      <div className="mt-2   xs-fields-device sm-fields-device lg-fields-device">
        <DropDownBox
          title={t('city')}
          labelClass="mb-0"
          titleClass="col-sm-12 bx--label col-md-3 mt-2 p-0"
          mainClassName="row m-0"
          className={`col-lg-12 col-xl-12 xpa-rs-wrap mb-2 p-0 ${isValidCity === true ? 'invalid pl-0 pr-0' : ''}`}
          options={locationsArr}
          custom
          disabled={disabled}
          isLoading={showDropDownLoading}
          name="location"
          placeholder={t('location')}
          onInputChange={e => onInputDropdownChange(e)}
          onChange={handleDropdownChange}
          selectedValue={location}
        />
        {
          isValidCity === true && (
            <div className="invalid-msg">{isValidCityText}</div>
          )
        }
      </div>
      <div className="mt-2   xs-fields-device sm-fields-device lg-fields-device">
        <TextArea 
          disabled={disabled}
          id="mailingAddress" 
          name="mailingAddress" 
          invalid={isMailingAddressInvalid} 
          invalidText={mailingAddressInvalidText} 
          labelText={<span>{t('mailingAddress')}<span className="text-danger">*</span></span>} 
          placeholder="Enter mailing address" 
          value={mailingAddress} 
          onChange={(e) => { handleChange(e); } } 
        />
      </div>
    </Fragment>
  );
};

export default withTranslation()(OfficeDetails);
