import isEqual from 'lodash/fp/isEqual';
import { useEffect, useMemo, useRef, useState } from 'react';

export const useStateData = (initial = {}) => {
  if (!(initial.constructor === Object || typeof initial === 'function')) throw new Error('useStateData will only recieve Object type');
  const [state, set] = useState(initial);
  const setState = updater => {
    set(prev => ({ ...prev, ...(typeof updater === 'function' ? updater(prev) : updater) }));
  };
  return [state, setState];
};

export const useOnMount = func => {
  useEffect(() => {
    func();
  }, []);
};

export const useOnUnmount = func => {
  const fnRef = useRef(func);
  fnRef.current = func;
  useEffect(() => {
    return () => {
      fnRef.current();
    };
  }, []);
};

export const useWindowUnloadEffect = (handler, callOnCleanup) => {
  const cb = useRef()
  cb.current = handler
  useEffect(() => {
    const handler = () => cb.current()
    window.addEventListener('beforeunload', handler)
    return () => {
      if(callOnCleanup) handler()
      window.removeEventListener('beforeunload', handler)
    }
  }, [callOnCleanup])
}

export const useOnUpdate = (func, dep, init = false) => {
  const ref = useRef(true);
  useEffect(() => {
    if (ref.current && !init) ref.current = false;
    else func();
  }, dep);
};

export const useOnDeepUpdate = (func, dep, init = false) => {
  const ref = useRef(true);
  const depRef = useRef();
  if (!isEqual(depRef.current, dep)) depRef.current = dep;
  useEffect(() => {
    if (ref.current && !init) ref.current = false;
    else func();
  }, [depRef.current]);
};

export const useDeepMemo = (func, dep) => {
  const ref = useRef();
  if (!isEqual(ref.current, dep)) ref.current = dep;
  return useMemo(() => func(), ref.current);
};

export const useForceUpdate = () => {
  const [value, setValue] = useState(0);
  return () => setValue(value => value + 1);
};

export const useLoaders = () => {
  const [loaders, setLoaders] = useState({});

  const loadersHandle = (key, val) => {
    if (key) setLoaders(x => ({ ...x, [key]: val === undefined ? !x[key] : val }));
  };

  const reset = () => setLoaders({});

  return [loaders, loadersHandle, reset];
};
