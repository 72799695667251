import React, { Suspense } from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage'; // default: localStorage if web, AsyncStorage if react-native
import { routerMiddleware, ConnectedRouter } from 'react-router-redux';
import thunk from 'redux-thunk';

import { FlaskLoader } from 'components/common/common';

// rr4
import createHistory from 'history/createBrowserHistory';
import Theme from './containers/App';
import Routes from './routes';
import reducers from './reducers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './App.css'
const config = {
  key: 'root',
  blacklist: [
    'routing',
    'jobCreation',
    'jobs',
    'roboroyDashboard',
    'roboroyApplications',
    'enterprise',
    'roboroyTalentPool',
    'resumeUpload',
    'taskStatus',
    'user',
    'x0paDuser',
  ],
  storage,
};
const persistedReducer = persistReducer(config, reducers);

const history = createHistory();

const middleware = [thunk, routerMiddleware(history)];
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleware)),
);

const persistor = persistStore(store);

const App = () => (
  <Suspense fallback={<FlaskLoader />}>
    <Provider store={store}>
      {/* TODO: will look up a solution later */}
      <PersistGate persistor={persistor}>
        <Theme>
          <ConnectedRouter history={history}>
            <Routes />
          </ConnectedRouter>
        </Theme>
        <ToastContainer />
      </PersistGate>
    </Provider>
  </Suspense>
);

export default (App);
