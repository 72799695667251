const deleteJobApiCache = () => {
  window.caches.delete('xpa-api-jobs')
    .then((dres) => {
      console.log('Deleting cache:::xpa-api-jobs:::', dres);
    })
    .catch((err) => {
      console.error('deleteJobApiCache:::xpa-api-jobs:::', err);
    });
  window.caches.delete('xpa-api-jobs-cf')
    .then((dres) => {
      console.log('Deleting cache:::xpa-api-jobs:::', dres);
    })
    .catch((err) => {
      console.error('deleteJobApiCache:::xpa-api-jobs:::', err);
    });
};

const deleteApiCache = (cacheName) => {
  try {
    window.caches.delete(cacheName)
      .then((dres) => {
        console.log(`Deleting cache:::${cacheName}:::`, dres);
      })
      .catch((err) => {
        console.error(`deleteApiCache:::${cacheName}:::`, err);
      });
  } catch (error) {
    console.error(`deleteApiCache:::${cacheName}:::`, error);
  }
};

export const replacePlaceHolder = (strTemp, data, options) => {
  let str = strTemp;
  if (!str || str === '') return '';
  let before = '{{';
  let after = '}}';
  if (Array.isArray(options)) {
    before = options[0];
    after = options[1];
  }
  if (options && options.constructor === Object) {
    before = options.before;
    after = options.after;
  }
  const keys = Array.isArray(data) ? data.map((_, i) => i) : Object.keys(data);
  keys.forEach(it => {
    const regx = new RegExp(before + String(it) + after, 'g');
    str = str.replace(regx, (m, b) => String(data[it]));
  });
  return str;
};

export const mergeArrUni = (oldData, newData, key = null, sKey = null) => {
  if (newData.length === 0) return oldData;
  if (oldData.length === 0) {
    if (!key || typeof key !== 'string') return newData.filter((item, ind, self) => self.indexOf(item) === ind).sort();
    return newData
      .filter((item, ind, self) => self.findIndex(ig => ig[key] === item[key] && (!sKey ? true : ig[sKey] === item[sKey])) === ind)
      .sort((a, b) => (a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0));
  }
  if (!key || typeof key !== 'string') return oldData.concat(newData.filter(it => oldData.indexOf(it) < 0)).sort();
  return oldData
    .concat(newData.filter(item => oldData.findIndex(it => it[key] === item[key] && (!sKey ? true : it[sKey] === item[sKey])) < 0))
    .sort((a, b) => (a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0));
};

export {
  deleteJobApiCache,
  deleteApiCache,
};
