import callApi from 'utils/callApi';
import { baseUrl, regularUrl } from 'utils/config';

const getHiredStudents = data => dispatch => {
  const { searchKey, limit, offset, companies, jobs } = data || {};
  let url = `${baseUrl}/hires?x=1`;
  const pData = {}
  if (searchKey)  pData.searchKey = searchKey;
  if (limit)  pData.limit = limit;
  if (offset)  pData.skip = offset;
  if(companies && Array.isArray(companies) && companies.length > 0)  pData.companies = companies;
  if(jobs && Array.isArray(jobs) && jobs.length > 0)  pData.jobs = jobs;
  const params = {
    headers: {
      'content-type': 'application/json',
    },
    dispatch,
    method: 'POST',
    url,
    body: JSON.stringify(pData),
    actionType: 'REQUEST_GET_HIRED_STUDENTS_DATA',
  };
  return callApi(params);
};

const importHiredStudents = formData => dispatch => {
  const url = `${regularUrl}/upload/importhires`;
  const params = {
    dispatch,
    actionType: 'REQUEST_IMPORT_HIRED_STUDENTS',
    url,
    body: formData,
    method: 'POST',
  };
  return callApi(params);
};

const exportHiredStudents = () => (dispatch) => {
  let url = `${baseUrl}/hires/exports`;
  window.open(url);
};


const getDocs = data => dispatch => {
  const { searchKey, limit, offset, appId, studentId, type } = data || {};
  let url = `${baseUrl}/hires/docs/${studentId}?x=1`;
  if (searchKey) url += `&searchKey=${searchKey}`;
  if (limit) url += `&limit=${limit}`;
  if (offset) url += `&skip=${offset}`;
  if (appId) url += `&appId=${appId}`;
  if (type) url += `&type=${type}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: 'REQUEST_GET_DOCS',
  };
  return callApi(params);
};

const uploadDoc = (formData, data) => dispatch => {
  const { studentId, type  } = data || {};
  let url = `${baseUrl}/hires/upload/${studentId}?x=1`;
  if(type){
    url += `&docType=${type}`;
  }
  const params = {
    dispatch,
    actionType: 'REQUEST_UPLOAD_DOC',
    url,
    body: formData,
    method: 'POST',
  };
  return callApi(params);
};

const deleteDoc = deleteId => dispatch => {
  const url = `${baseUrl}/hires/docs/${deleteId}`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    headers: {
      'content-type': 'application/json',
    },
    actionType: 'REQUEST_DELETE_DOC',
  };
  return callApi(params);
};

const getCompaniesList = data => dispatch => {
  const { searchKey, limit, offset } = data || {};
  let url = `${baseUrl}/hires/filter/company?x=1`;
  if (searchKey) url += `&searchKey=${searchKey}`;
  if (limit) url += `&limit=${limit}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: 'REQUEST_GET_COMPANIES_LIST',
  };
  return callApi(params);
};

const getJobsList = data => dispatch => {
  const { searchKey, limit, offset } = data || {};
  let url = `${baseUrl}/hires/filter/jobs?x=1`;
  if (searchKey) url += `&searchKey=${searchKey}`;
  if (limit) url += `&limit=${limit}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: 'REQUEST_GET_JOBS_LIST',
  };
  return callApi(params);
};

export { 
  getHiredStudents, 
  importHiredStudents, 
  exportHiredStudents, 
  uploadDoc, 
  getDocs, 
  deleteDoc, 
  getCompaniesList,
  getJobsList,
};

