import React, { Fragment, useState } from 'react';
import {
  Button,
  InlineNotification,
  DatePicker,
  DatePickerInput,
  TextInput,
  Toggle,
} from 'carbon-components-react';
import DropDownBox from 'components/common/dropdown/Dropdown.component';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import RSelect from 'react-select';
import { arrMap } from 'utils/reactMapUtils';
import { replacePlaceHolder } from 'utils/helpers';
import { MultiFacet, CreateMultiValues } from './filterComps';

function reSpChar(str = '') {
  str = str.replace(/'/g, "''");
  str = str.replace(/%/g, '%25');
  // str = str.replace(/\+/g, '%2B');
  str = str.replace(/\//g, '%2F');
  str = str.replace(/\?/g, '%3F');
  str = str.replace(/#/g, '%23');
  str = str.replace(/&/g, '%26');
  return str;
}

const queryHandle = (tCurFilter, t) => {
  const currFilter = Object.clone(tCurFilter);
  const { method, dVal, key, rq, type } = currFilter;

  const RPH = (data) => replacePlaceHolder(rq, data, ['{', '}']);

  let { value, value2, values = [] } = currFilter;
  currFilter.query = `${key} eq '${value}'`;
  currFilter.qLabel = `${t('is equal to')} '${value}'`;
  if (rq) currFilter.query = RPH({ key, value });
  if (method === 'pJob') {
    if (['userListType', 'tagsType'].includes(type)) {
      currFilter.value = true;
      currFilter.query = `${key}=${values.map((x) => x.value).join(',')}`;
      currFilter.qLabel = `${t('in')} [ ${values
        .map((x) => x.name)
        .join(', ')} ]`;
    } else if (['seniorityType', 'companyType', 'statusType'].includes(type)) {
      currFilter.value = true;
      currFilter.query = values
        .map((x) => `filterArr[${key}]=${x.value}`)
        .join('&');
      currFilter.qLabel = `${t('in')} [ ${values
        .map((x) => x.name)
        .join(', ')} ]`;
    } else if (['sortByValueJob'].includes(type)) {
      currFilter.error = value ? false : true;
      currFilter.query = `filter[order][0]=${encodeURIComponent(value && value.value)}`;
      currFilter.qLabel = `${t('is')} ${value && value.label}`;
    }
    return currFilter;
  }
  if (method === 'pApp') {
    const typesArr = [
      'screeningType',
      'offerType',
      'hiringType',
      'applicationKIVType',
      'tagsAppType',
      'interviewType',
      'interviewStagesType',
    ];
    if (typesArr.includes(type)) {
      currFilter.error = values.length > 0 ? false : true;
      const subVal = `${encodeURIComponent(
        JSON.stringify(values.map((x) => x.value))
      )}`;
      currFilter.query = `filterKeys[${key}]=${subVal}`;
      currFilter.qLabel = `${t('in')} [ ${values
        .map((x) => x.name)
        .join(', ')} ]`;
    }
    return currFilter;
  }
  if (method === 'pTalent') {
    const newTypesArr = [
      'tBucketType',
      'tStatusType',
      'tTagsType',
      'tFunctionType',
      'tIndustryType',
      'tUGradsType',
      'tPGradsType',
      'tJobtitleType',
      'tJobtitlesType',
      'tCompanyType',
      'tSkillType',
      'tGenderType',
      'tUsers',
      'tAppStatus',
    ];
    if (['tUsers','tAppStatus'].includes(type)) {
      currFilter.value = true;
      const filterValues = values.map((x) => x.value);
      currFilter.filter = { [key]: filterValues };
      currFilter.qLabel = `${t('in')} [ ${values
        .map((x) => x.name)
        .join(', ')} ]`;
    } else if (newTypesArr.includes(type)) {
      currFilter.error = values.length > 0 ? false : true;
      currFilter.filter = { [key]: values };
      currFilter.qLabel = `${t('in')} [ ${values.join(', ')} ]`;
    } else if (['stringArr'].includes(type)) {
      currFilter.error = values.length > 0 ? false : true;
      currFilter.filter = { [key]: values };
      currFilter.qLabel = `${t('in')} [ ${values.join(', ')} ]`;
    } else if (['number'].includes(type)) {
      currFilter.error = value.length > 0 ? false : true;
      currFilter.filter = { [key]: value };
      if(currFilter.minValue)  currFilter.qLabel = `${t('is greater than or equal to')} ${value}`;
      else if(currFilter.maxValue)  currFilter.qLabel = `${t('is less than or equal to')} ${value}`;
      else currFilter.qLabel = `${t('is equal to')} ${value}`;
    } else if (['boolean'].includes(type)) {
      currFilter.filter = { [key]: value };
      currFilter.qLabel = `${t('is')} ${value ? 'TRUE' : 'FALSE'}`;
    } else if(['dateType'].includes(type)){
      const { value:v } = dVal || {};
      const { value: date1, value2: date2 } = currFilter || {};
      const finalDate1 = moment(date1).format('YYYY-MM-DD');
      const finalDate2 = moment(date2).format('YYYY-MM-DD');
      let qLabel = '';
      let filterValue ={};
      if(v == 'eq'){
        qLabel = `${t('on')} ${finalDate1}`;
        filterValue = {
          isEqualTo: finalDate1,
        }
      }else if(v == 'lt'){
        qLabel = `${t('before')} ${finalDate1}`;
        filterValue = {
          isLessThan: finalDate1,
        }
      }else if( v == 'gt'){
        qLabel = `${t('after')} ${finalDate1}`;
        filterValue = {
          isGreaterThan: finalDate1,
        }
      }else if( v == 'btw'){
        qLabel = `${t('between')} ${finalDate1} to ${finalDate2}`;
        filterValue = {
          isBetween: {
            min: finalDate1,
            max: finalDate2,
          },
        }
      }
      currFilter.filter = { [key]: filterValue };
      currFilter.qLabel = qLabel;
    }
    return currFilter;
  }

  if (['numberWithComma'].includes(type)) {
    currFilter.error = values.length > 0 ? false : true;
    currFilter.query = `search.in(${key}, '${values.join(';;')}',';;')`;
    currFilter.qLabel = `${t('in')} [ ${values.join(', ')} ]`;
  } else if (
    [
      'companyType',
      'jobType',
      'cvSourceType',
      'userListType',
      'tagsType',
      'approvalType',
      'hourType',
      'termType',
      'jobCategoryType',
      'tpCompanyType',
      'countryType',
    ].includes(type)
  ) {
    currFilter.error = values.length > 0 ? false : true;
    const newVal = reSpChar(values.map((x) => x.value).join(';;'));
    currFilter.query = `search.in(${key}, '${newVal}',';;')`;
    if (rq)
      currFilter.query = RPH({ key, value: newVal, query: currFilter.query });
    currFilter.qLabel = `${t('in')} [ ${values
      .map((x) => x.name)
      .join(', ')} ]`;
  } else if (['applicationType'].includes(type)) {
    let val = `${(value && value.value) || ''}`;
    val = reSpChar(val);
    currFilter.query = `${key} eq ${val}`;
    if (rq) currFilter.query = RPH({ key, value: val });
    currFilter.qLabel = `${t('is')} ${value && value.label}`;
  } else if (
    ['skillType', 'qualificationType', 'tpSkillsType', 'bucketType'].includes(
      type
    )
  ) {
    currFilter.error = values.length > 0 ? false : true;
    const newVal = reSpChar(values.join(';;'));
    currFilter.query = `search.in(${key}, '${newVal}',';;')`;
    if (rq)
      currFilter.query = RPH({ key, value: newVal, query: currFilter.query });
    currFilter.qLabel = `${t('in')} [ ${values.join(', ')} ]`;
  } else if (['text'].includes(type)) {
    if (dVal) {
      const { label, value: qVal } = dVal;
      currFilter.error = value ? false : true;
      currFilter.qLabel = `${label} '${value}'`;
      let val = '';
      value = reSpChar(value);
      if (qVal === 'equal') val = `'${value}'`;
      else if (qVal === 'fuzzy-match') val = `'*${value}*'`;
      else if (qVal === 'starts-with') val = `'${value}*'`;
      else if (qVal === 'ends-with') val = `'*${value}'`;
      currFilter.query = `${key} eq ${val}`;
      if (rq) currFilter.query = RPH({ key, value: val });
    } else currFilter.error = true;
  } else if (['textType'].includes(type)) {
    currFilter.error = value ? false : true;
    currFilter.qLabel = `${t('is match of')} '${value}'`;
    let val = '';
    val = reSpChar(value);
    currFilter.query = `search.ismatch('${val}*', '${key}', 'full', 'all')`;
    if (rq) currFilter.query = RPH({ key, value: val });
  } else if (['numberType', 'dateType'].includes(type)) {
    if (dVal) {
      const { label, value: qVal } = dVal;
      let labelVal = value;
      let labelVal2 = value2;
      if (type === 'dateType') {
        value = moment(value).toISOString();
        value2 = moment(value2).toISOString();
        labelVal = moment(value).format('MM/DD/YYYY');
        labelVal2 = moment(value2).format('MM/DD/YYYY');
        currFilter.value = value;
        currFilter.value2 = value2;
      }
      if (currFilter.withQuotes) {
        if (qVal === 'btw' && value2) value2 = `'${value2}'`;
        if (value) value = `'${value}'`;
      }
      if (qVal === 'btw') {
        currFilter.error = value && value2 ? false : true;
        currFilter.query = `${key} gt ${value} and ${key} lt ${value2}`;
        currFilter.qLabel = `${label} ${labelVal} - ${labelVal2}`;
      } else {
        currFilter.error = value ? false : true;
        currFilter.query = `${key} ${qVal} ${value}`;
        currFilter.qLabel = `${label} ${labelVal}`;
      }
      if (rq) currFilter.query = RPH({ key, query: currFilter.query });
    } else currFilter.error = true;
  } else if (['boolean'].includes(type)) {
    currFilter.query = value ? `${key} eq true` : `${key} ne true`;
    currFilter.qLabel = `${t('is')} ${value ? 'TRUE' : 'FALSE'}`;
  } else if (['customFieldType'].includes(type)) {
    let { name, dataType } = currFilter;
    if (dataType === 'number') {
      if (dVal) {
        const { label, value: qVal } = dVal;
        if (['isBetween', 'isNotBetween'].includes(qVal)) {
          currFilter.error = value && value2 ? false : true;
          currFilter.filter = {
            [qVal]: { min: parseInt(value), max: parseInt(value2) },
          };
          currFilter.qLabel = `${label} ${value} - ${value2}`;
        } else {
          if (value) {
            currFilter.filter = { [qVal]: parseInt(value) };
            currFilter.qLabel = `${label} ${value}`;
          } else if ((values || []).length > 0) {
            currFilter.filter = { [qVal]: values };
            currFilter.qLabel = `${label} [ ${values.join(', ')} ]`;
          } else currFilter.error = true;
        }
      } else currFilter.error = true;
    } else if (dataType === 'date') {
      if (dVal) {
        const { label, value: qVal } = dVal;
        value = moment(value).format('YYYY-MM-DD');
        value2 = moment(value2).format('YYYY-MM-DD');
        currFilter.value = value;
        currFilter.value2 = value2;
        if (['isBetween', 'isNotBetween'].includes(qVal)) {
          currFilter.error = value && value2 ? false : true;
          currFilter.filter = { [qVal]: { min: value, max: value2 } };
          currFilter.qLabel = `${label} ${value} - ${value2}`;
        } else {
          currFilter.error = value ? false : true;
          currFilter.filter = { [qVal]: value };
          currFilter.qLabel = `${label} ${value}`;
        }
      } else currFilter.error = true;
    } else if (dataType === 'text') {
      if (dVal) {
        const { label, value: qVal } = dVal;
        if (value) {
          currFilter.filter = { [qVal]: value };
          currFilter.qLabel = `${label} '${value}'`;
        } else if ((values || []).length > 0) {
          currFilter.filter = { [qVal]: values };
          currFilter.qLabel = `${label} [ ${values.join(', ')} ]`;
        } else currFilter.error = true;
      } else currFilter.error = true;
    } else if (dataType === 'single_choice') {
      currFilter.error = value ? false : true;
      currFilter.filter = { isEqualTo: value };
      currFilter.qLabel = `is equal to ${value}`;
    } else if (dataType === 'multiple_choice') {
      currFilter.error = values.length > 0 ? false : true;
      currFilter.filter = { response: values };
      currFilter.qLabel = `in [ ${values.join(', ')} ]`;
    }
  }
  return currFilter;
};

const FilterSectionEditor = ({
  currFilter,
  setCurrFilter,
  handleGetTjobTitlesList,
  onChangeCurrentFilter,
  applications,
  companies,
  getCompanyDetailsOnSearch,
  cvSourceList,
  getCVSourceOnSearch,
  usersList,
  tags,
  jobStatusList,
  hourTypes,
  jobTerms,
  workflowstages,
  talentPoolData,
  countries,
  tBucketArr,
  tStatusArr,
  tUsersArr,
  tAppStatusArr,
  tTagsArr,
  tFunctionArr,
  tIndustryArr,
  tUGradsArr,
  tPGradsArr,
  tJobtitleArr,
  tCompanyArr,
  tUserArr,
  customFieldOptions,
  filterSettings,
  jobsList,
  getJobDetailsOnSearch,
}) => {
  const { t } = useTranslation();
  const [notifMess, setNotifMess] = useState(null);
  const { openJobs, closedJobs } = filterSettings || {};
  const { dVal, dataType, value:currFilterValue, value2: currFilterValue2, values: currFilterValues, decimal: currFilterDecimal, key: currFilterKey } = currFilter || {};
  const { value: dValValue } = dVal || {};
  
  const numberOptions = [
    { label: t('is equal to'), value: 'eq' },
    { label: t('is not equal to'), value: 'ne' },
    { label: t('is greater than'), value: 'gt' },
    { label: t('is less than'), value: 'lt' },
    { label: t('is greater than or equal to'), value: 'ge' },
    { label: t('is less than or equal to'), value: 'le' },
    { label: t('is between'), value: 'btw' },
  ];

  const dateOptions = [
    { label: t('is equal to'), value: 'eq' },
    { label: t('is before'), value: 'lt' },
    { label: t('is after'), value: 'gt' },
    { label: t('is between'), value: 'btw' },
  ];

  const stringOptions = [
    { label: t('is exact match of'), value: 'equal' },
    // { label: t('is fuzzy match of'), value: 'fuzzy-match' },
    // { label: t('is startswith'), value: 'starts-with' },
    // { label: t('is endswith'), value: 'ends-with' },
  ];

  const dateSettingsOptions = [
    { label: 'is equal to', value: 'isEqualTo' },
    { label: 'is not equal to', value: 'isNotEqualTo' },
    { label: 'is greater than', value: 'isGreaterThan' },
    { label: 'is less than', value: 'isLessThan' },
    { label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo' },
    { label: 'is less than or equal to', value: 'isLessThanOrEqualTo' },
    { label: 'is between', value: 'isBetween' },
    { label: 'is not between', value: 'isNotBetween' },
  ];

  const stringSettingOptions = [
    { label: 'is equal to', value: 'isEqualTo' },
    { label: 'is not equal to', value: 'isNotEqualTo' },
    { label: 'is in', value: 'isIn', multiple: true },
    { label: 'is not in', value: 'isNotIn', multiple: true },
    { label: 'contains', value: 'contains' },
    { label: 'does not contain', value: 'doesNotContain' },
    { label: 'starts with', value: 'startsWith' },
    { label: 'ends with', value: 'endsWith' },
    { label: 'does not start with', value: 'doesNotStartWith' },
    { label: 'does not end with', value: 'doesNotEndWith' },
  ];

  const numberSettingsOptions = [
    { label: 'is equal to', value: 'isEqualTo' },
    { label: 'is not equal to', value: 'isNotEqualTo' },
    { label: 'is greater than', value: 'isGreaterThan' },
    { label: 'is less than', value: 'isLessThan' },
    { label: 'is greater than or equal to', value: 'isGreaterThanOrEqualTo' },
    { label: 'is less than or equal to', value: 'isLessThanOrEqualTo' },
    { label: 'is between', value: 'isBetween' },
    { label: 'is not between', value: 'isNotBetween' },
    { label: 'is in', value: 'isIn', multiple: true },
    { label: 'is not in', value: 'isNotIn', multiple: true },
  ];

  const getCustomFieldsRender = () => {
    const singleChoiceSelected = customFieldOptions.find(
      (ele) => ele.value === currFilterValue
    );
    const multipleChoiceSelected = customFieldOptions.filter((ele) =>
      (values && values.includes(ele.value))
    );
    return (
      <>
        {dataType === 'number' &&
          numberSettingsOptions.map((ele) => (
            <Fragment key={ele.label}>
              <div className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center">
                <div className="mr-2 pb-1">
                  <input
                    type="radio"
                    id={`radio-${ele.label}`}
                    name="numberType"
                    checked={ele.value === dValValue}
                    onChange={($) =>
                      setCurrFilter({
                        ...currFilter,
                        dVal: ele,
                        value: undefined,
                        value2: undefined,
                        values: undefined,
                      })
                    }
                  />
                </div>
                <label htmlFor={`radio-${ele.label}`}>{ele.label}</label>
              </div>
              {ele.value === dValValue && (
                <>
                  {ele.multiple ? (
                    <div className="ml-3 p-0 bx--col-lg-12 bx--col-md-12 d-flex align-items-center w-75 mb-2">
                      <CreateMultiValues
                        className={`w-100 `}
                        placeholder={t('Type values and press enter...')}
                        onValueChange={(v) => v.replace(/[^\d]/g, '')}
                        onChange={(value) => {
                          setCurrFilter({ ...currFilter, values: value || [] });
                        }}
                        value={currFilterValues || []}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="ml-3 p-0 bx--col-lg-12 bx--col-md-12 d-flex align-items-center w-75 mb-2">
                        <TextInput
                          hideLabel
                          placeholder={`Type Number`}
                          type="text"
                          value={currFilterValue}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^\d]/g, '');
                            setCurrFilter({ ...currFilter, value });
                          }}
                        />
                      </div>
                      {['isBetween', 'isNotBetween'].includes(
                        dValValue
                      ) && (
                        <div className="ml-3 p-0 bx--col-lg-12 bx--col-md-12 d-flex align-items-center w-75 mb-2">
                          <TextInput
                            hideLabel
                            placeholder={`Type Number2`}
                            type="text"
                            value={currFilterValue2}
                            onChange={(e) => {
                              const value2 = e.target.value.replace(
                                /[^\d]/g,
                                ''
                              );
                              setCurrFilter({ ...currFilter, value2 });
                            }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </Fragment>
          ))}

        {dataType === 'date' &&
          (() => {
            let minDate = undefined;
            let maxDate = undefined;
            return dateSettingsOptions.map((ele) => (
              <Fragment key={ele.label}>
                <div className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center">
                  <div className="mr-2 pb-1">
                    <input
                      type="radio"
                      id={`radio-${ele.label}`}
                      name="dateType"
                      checked={ele.value === dValValue}
                      onChange={($) => {
                        const value2 = currFilterValue;
                        setCurrFilter({ ...currFilter, dVal: ele, value2 });
                      }}
                    />
                  </div>
                  <label htmlFor={`radio-${ele.label}`}>{ele.label}</label>
                </div>
                {ele.value === dValValue && (
                  <div className="ml-3 p-0 bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
                    {['isBetween', 'isNotBetween'].includes(
                      dValValue
                    ) ? (
                      <DatePicker
                        datePickerType="range"
                        onChange={(dateData) => {
                          const [value = '', value2 = ''] = dateData;
                          setCurrFilter({ ...currFilter, value, value2 });
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                        value={[
                          moment(currFilterValue).format('MM/DD/YYYY'),
                          moment(currFilterValue2).format('MM/DD/YYYY'),
                        ]}>
                        <DatePickerInput
                          id="drp-start"
                          className="end_date"
                          labelText="Start date"
                          hideLabel
                        />
                        <DatePickerInput
                          id="drp-end"
                          className="end_date"
                          labelText="End date"
                          hideLabel
                        />
                      </DatePicker>
                    ) : (
                      <DatePicker
                        datePickerType="single"
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={(dateData) => {
                          const [value = ''] = dateData;
                          setCurrFilter({ ...currFilter, value });
                        }}>
                        <DatePickerInput
                          name="closeDate"
                          className="end_date"
                          id="start"
                          placeholder={'Date'}
                          value={moment(currFilterValue).format('MM/DD/YYYY')}
                        />
                      </DatePicker>
                    )}
                  </div>
                )}
              </Fragment>
            ));
          })()}

        {dataType === 'text' &&
          stringSettingOptions.map((ele) => (
            <Fragment key={ele.label}>
              <div className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center">
                <div className="mr-2 pb-1">
                  <input
                    type="radio"
                    id={`radio-${ele.label}`}
                    name="numberType"
                    checked={ele.value === dValValue}
                    onChange={($) =>
                      setCurrFilter({
                        ...currFilter,
                        dVal: ele,
                        value: undefined,
                        values: undefined,
                      })
                    }
                  />
                </div>
                <label htmlFor={`radio-${ele.label}`}>{ele.label}</label>
              </div>
              {ele.value === dValValue && (
                <>
                  <div className="ml-3 p-0 bx--col-lg-12 bx--col-md-12 d-flex align-items-center w-75 mb-2">
                    {ele.multiple ? (
                      <CreateMultiValues
                        className={`w-100 `}
                        placeholder={t('Type values and press enter...')}
                        onChange={(value) => {
                          setCurrFilter({ ...currFilter, values: value || [] });
                        }}
                        value={currFilterValues || []}
                      />
                    ) : (
                      <TextInput
                        name="text"
                        hideLabel
                        className="w-75"
                        placeholder={`Type Here`}
                        value={currFilterValue}
                        onChange={(e) => {
                          const value = e.target.value;
                          setCurrFilter({ ...currFilter, value });
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </Fragment>
          ))}

        {dataType === 'single_choice' && (
          <div className="ml-3 p-0 bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <DropDownBox
              title=""
              mainClassName="bx--form-item mb-0"
              titleClass=""
              labelClass=""
              required
              custom
              className={`w-100 `}
              options={customFieldOptions}
              placeholder={t`Select Option`}
              onChange={($, value) =>
                setCurrFilter({ ...currFilter, value: (value && value.value) })
              }
              name="single_choice"
              selectedValue={singleChoiceSelected}
            />
          </div>
        )}

        {dataType === 'multiple_choice' && (
          <div className="ml-3 p-0 bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <RSelect
              isClearable
              isMulti
              className={`w-100 `}
              onChange={(value) =>
                setCurrFilter({
                  ...currFilter,
                  values: value && value.map((it) => it.value),
                })
              }
              options={customFieldOptions}
              placeholder={t`Select Multiple Options`}
              value={multipleChoiceSelected}
            />
          </div>
        )}
      </>
    );
  };

  const handleCurrentFilter = async () => {
    const { type } = currFilter; 
    if(type == 'tJobtitleType'){
      handleGetTjobTitlesList();
    }
    const tCurFilter = await queryHandle(currFilter, t);
    const { error, errMsg, ...restCurFilter } = tCurFilter || {};
    if (error) return setNotifMess(errMsg || t('Plase fill required fields'));
    onChangeCurrentFilter(restCurFilter);
  };

  const onMSelect = (current, status) => {
    let tCurFilter = Object.clone(currFilter);
    let values = tCurFilter.values || [];
    const ind = values.findIndex((x) => x.value === current.key);
    if (ind !== -1) values.splice(ind, 1);
    else values.push({ value: current.key, name: current.value, plValue: current.plValue });
    tCurFilter.values = values;
    setCurrFilter(tCurFilter);
  };

  const onMSelectValues = (current, status) => {
    let tCurFilter = Object.clone(currFilter);
    let values = tCurFilter.values || [];
    if (values.includes(current.key))
      values = values.filter((it) => it !== current.key);
    else values.push(current.key);
    tCurFilter.values = values;
    setCurrFilter(tCurFilter);
  };

  const onMSearch = (sk) => {
    let tCurFilter = Object.clone(currFilter);
    const { type } = tCurFilter;
    if(type == 'tJobtitleType'){
      handleGetTjobTitlesList(sk);
    }
  }

  const mValues = (currFilterValues && currFilterValues.map((x) => x.value)) || [];
  const values = currFilterValues || [];

  return (
    <fieldset className="ml-1 sui-facet">
      <legend className="sui-facet__title">{currFilter.name}</legend>
      {notifMess && (
        <div className="bx--col-lg-12 bx--col-md-12 bx--row">
          <div className="ml-3 p-0 bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <InlineNotification
              lowContrast
              className="mb-0 mt-0"
              title={notifMess || ''}
              subtitle=""
              kind={'warning'}
              onCloseButtonClick={() => setNotifMess(null)}
            />
          </div>
        </div>
      )}
      <div className="bx--col-lg-12 bx--col-md-12 bx--row">
        {currFilter.type === 'sortByValueJob' && (
          <Fragment>
            <div className="ml-3 p-0 bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
              <DropDownBox
                mainClassName="bx--form-item mb-0"
                required
                custom
                className={`w-100 `}
                options={[
                  {
                    value: 'jobname.nameName ASC',
                    label: t('wa-title ascending'),
                  },
                  {
                    value: 'jobname.nameName DSC',
                    label: t('wa-title descending'),
                  },
                  {
                    value: 'dashboard.totalApplications ASC',
                    label: t('wa-applications ascending'),
                  },
                  {
                    value: 'dashboard.totalApplications DSC',
                    label: t('wa-applications descending'),
                  },
                  { value: 'openDate ASC', label: t('wa-postdate ascending') },
                  { value: 'openDate DSC', label: t('wa-postdate descending') },
                ]}
                placeholder={`${t('Select')} ${currFilter.name}`}
                onChange={($, value) => setCurrFilter({ ...currFilter, value })}
                name="sort_type"
                selectedValue={currFilter.value}
              />
            </div>
          </Fragment>
        )}

        {currFilter.type === 'boolean' && (
          <div className="ml-3 p-0 bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <Toggle
              className="bx--col-md-2 bx--col-sm-1"
              onToggle={(value) => setCurrFilter({ ...currFilter, value })}
              toggled={currFilterValue}
              labelA={t('No')}
              labelB={t('Yes')}
            />
          </div>
        )}

        {currFilter.type === 'number' && (
          <div className="ml-3 p-0 bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <TextInput
              hideLabel
              className="w-75"
              placeholder={t`Type Number`}
              type={'number'}
              value={currFilterValue}
              onChange={(e) => {
                let value = e.target.value;
                if (!currFilterDecimal) value = value.replace(/[^\d]/g, '');
                setCurrFilter({ ...currFilter, value });
              }}
            />
          </div>
        )}

        {currFilter.type === 'numberWithComma' && (
          <div className="ml-3 p-0 bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <CreateMultiValues
              className={`w-100 `}
              placeholder={t('Type values and press enter...')}
              onValueChange={(v) => v.replace(/[^\d]/g, '')}
              onChange={(value) => {
                setCurrFilter({ ...currFilter, values: value || [] });
              }}
              value={currFilterValues || []}
            />
          </div>
        )}

        {currFilter.type === 'stringArr' && (
          <div className="ml-3 p-0 bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <CreateMultiValues
              className={`w-100 `}
              placeholder={t('Type values and press enter...')}
              onChange={(value) => {
                setCurrFilter({ ...currFilter, values: value || [] });
              }}
              value={currFilterValues || []}
            />
          </div>
        )}

        {['text'].includes(currFilter.type) &&
          stringOptions.map((ele) => (
            <Fragment key={ele.label}>
              <div className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center">
                <div className="mr-2 pb-1">
                  <input
                    type="radio"
                    id={`radio-${ele.label}`}
                    name="numberType"
                    checked={ele.value === dValValue}
                    onChange={($) =>
                      setCurrFilter({ ...currFilter, dVal: ele })
                    }
                  />
                </div>
                <label htmlFor={`radio-${ele.label}`}>{ele.label}</label>
              </div>
              {ele.value === dValValue && (
                <>
                  <div className="ml-3 p-0 bx--col-lg-12 bx--col-md-12 d-flex align-items-center w-75 mb-2">
                    <TextInput
                      hideLabel
                      placeholder={t`Type Here`}
                      type="text"
                      value={currFilterValue}
                      onChange={(e) => {
                        const value = e.target.value.replace(
                          /[^a-z\d\s.$_&#@\/!\(\)\[\]+-]+/gi,
                          ''
                        );
                        setCurrFilter({ ...currFilter, value });
                      }}
                    />
                  </div>
                </>
              )}
            </Fragment>
          ))}

        {['textType'].includes(currFilter.type) && (
          <Fragment>
            <div className="ml-3 p-0 bx--col-lg-12 bx--col-md-12 d-flex align-items-center w-75 mb-2">
              <TextInput
                hideLabel
                placeholder={t`Type Here`}
                type="text"
                value={currFilterValue}
                onChange={(e) => {
                  const value = e.target.value.replace(
                    /[^a-z\d\s.$_&#@\/!\(\)\[\]+-]+/gi,
                    ''
                  );
                  setCurrFilter({ ...currFilter, value });
                }}
              />
            </div>
          </Fragment>
        )}

        {currFilter.type === 'numberType' &&
          numberOptions.map((ele) => (
            <Fragment key={ele.label}>
              <div className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center">
                <div className="mr-2 pb-1">
                  <input
                    type="radio"
                    id={`radio-${ele.label}`}
                    name="numberType"
                    checked={ele.value === dValValue}
                    onChange={($) =>
                      setCurrFilter({ ...currFilter, dVal: ele })
                    }
                  />
                </div>
                <label htmlFor={`radio-${ele.label}`}>{ele.label}</label>
              </div>
              {ele.value === dValValue && (
                <>
                  <div className="ml-3 p-0 bx--col-lg-12 bx--col-md-12 d-flex align-items-center w-75 mb-2">
                    <TextInput
                      hideLabel
                      placeholder={t`Type Number`}
                      type={'number'}
                      value={currFilterValue}
                      onChange={(e) => {
                        let value = e.target.value;
                        if (!currFilterDecimal)
                          value = value.replace(/[^\d]/g, '');
                        setCurrFilter({ ...currFilter, value });
                      }}
                    />
                  </div>
                  {dValValue === 'btw' && (
                    <div className="ml-3 p-0 bx--col-lg-12 bx--col-md-12 d-flex align-items-center w-75 mb-2">
                      <TextInput
                        hideLabel
                        placeholder={t`Type Number2`}
                        type={'number'}
                        value={currFilterValue2}
                        onChange={(e) => {
                          let value2 = e.target.value;
                          if (!currFilterDecimal)
                            value2 = value2.replace(/[^\d]/g, '');
                          setCurrFilter({ ...currFilter, value2 });
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </Fragment>
          ))}

        {currFilter.type === 'dateType' &&
          (() => {
            let minDate = undefined;
            let maxDate = undefined;
            if (['closeDate'].includes(currFilterKey)) {
              if (openJobs) minDate = moment().format('MM/DD/YYYY');
            }
            if (['closeDate'].includes(currFilterKey)) {
              if (closedJobs) maxDate = moment().format('MM/DD/YYYY');
            } else if (['openDate'].includes(currFilterKey)) {
              maxDate = moment().format('MM/DD/YYYY');
            }
            return dateOptions.map((ele) => (
              <Fragment key={ele.label}>
                <div className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center">
                  <div className="mr-2 pb-1">
                    <input
                      type="radio"
                      id={`radio-${ele.label}`}
                      name="dateType"
                      checked={ele.value === dValValue}
                      onChange={($) => {
                        const value2 = currFilterValue;
                        setCurrFilter({ ...currFilter, dVal: ele, value2 });
                      }}
                    />
                  </div>
                  <label htmlFor={`radio-${ele.label}`}>{ele.label}</label>
                </div>
                {ele.value === dValValue && (
                  <div className="ml-3 p-0 bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
                    {dValValue === 'btw' ? (
                      <DatePicker
                        datePickerType="range"
                        onChange={(dateData) => {
                          const [value = '', value2 = ''] = dateData;
                          setCurrFilter({ ...currFilter, value, value2 });
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                        value={[
                          moment(currFilterValue).format('MM/DD/YYYY'),
                          moment(currFilterValue2).format('MM/DD/YYYY'),
                        ]}>
                        <DatePickerInput
                          id="drp-start"
                          className="end_date"
                          labelText="Start date"
                          hideLabel
                        />
                        <DatePickerInput
                          id="drp-end"
                          className="end_date"
                          labelText="End date"
                          hideLabel
                        />
                      </DatePicker>
                    ) : (
                      <DatePicker
                        datePickerType="single"
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={(dateData) => {
                          const [value = ''] = dateData;
                          setCurrFilter({ ...currFilter, value });
                        }}>
                        <DatePickerInput
                          name="closeDate"
                          className="end_date"
                          id="start"
                          placeholder={'Date'}
                          value={moment(currFilterValue).format('MM/DD/YYYY')}
                        />
                      </DatePicker>
                    )}
                  </div>
                )}
              </Fragment>
            ));
          })()}

        {currFilter.type === 'approvalType' && (
          <div
            key="approvalType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={[
                { label: t('approval-awaiting'), value: 'pending' },
                { label: t('Approved'), value: 'approved' },
                { label: t('wa-rejected'), value: 'rejected' },
              ].map((x) => {
                const key = x.value;
                return { key, selected: mValues.includes(key), value: x.label };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'hourType' && (
          <div
            key="hourType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={hourTypes.map((x) => {
                const key = x.typeId;
                return {
                  key,
                  selected: mValues.includes(key),
                  value: x.typeName,
                };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'termType' && (
          <div
            key="termType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={jobTerms.map((x) => {
                const key = x.termId;
                return {
                  key,
                  selected: mValues.includes(key),
                  value: x.termName,
                };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'userListType' && (
          <div
            key="userListType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={usersList.map((x) => {
                const key = x.userId;
                return {
                  key,
                  selected: mValues.includes(key),
                  value: x.userName,
                };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'jobCategoryType' && (
          <div
            key="jobCategoryType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={[
                { label: t('internal'), value: 'Internal' },
                { label: t('external'), value: 'External' },
                { label: t('Internal & External'), value: 'Both' },
                { label: t('Campus Hiring'), value: 'Campus Hiring' },
              ].map((x) => {
                const key = x.value;
                return { key, selected: mValues.includes(key), value: x.label };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'companyType' && (
          <div
            key="companyType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={companies.map((x) => {
                const key = x.companyId;
                return {
                  key,
                  selected: mValues.includes(key),
                  value: `${x.companyName}${x.companyId ? ` (${x.companyId})`:''}`,
                  plValue: `${x.companyName}`,
                };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
              onSearch={getCompanyDetailsOnSearch}
            />
          </div>
        )}

        {currFilter.type === 'jobType' && (
          <div
            key="jobType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={jobsList.map((x) => {
                const key = x.jobId;
                return {
                  key,
                  selected: mValues.includes(key),
                  value: `${x.jobName}${x.jobId ? ` (${x.jobId})`:''}`,
                  plValue: `${x.jobName}`,
                };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
              onSearch={getJobDetailsOnSearch}
            />
          </div>
        )}

        {currFilter.type === 'screeningType' && (
          <div
            key="screeningType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={[
                { label: t('Screening'), value: 'screening' },
                { label: t('Shortlisted'), value: 'shortlisted' },
                { label: t('Rejected'), value: 'rejected' },
                {
                  label: t('consent-email-sent'),
                  value: 'shortlist-email-sent',
                },
                {
                  label: t('consent-email-accepted'),
                  value: 'emailacceptance',
                },
                { label: t('consent-email-rejected'), value: 'emailrejection' },
                { label: t('Interview in progress'), value: 'interviewing' },
                {
                  label: t('Interview round accept'),
                  value: 'interviewaccept',
                },
                {
                  label: t('Interview round reject'),
                  value: 'interviewreject',
                },
              ].map((x) => {
                const key = x.value;
                return { key, selected: mValues.includes(key), value: x.label };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'offerType' && (
          <div
            key="offerType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={[
                { label: t('Consent Sent'), value: 'consent-email-sent' },
                {
                  label: t('Consent Accepted'),
                  value: 'candidate-accepts-consent',
                },
                {
                  label: t('Consent Rejected'),
                  value: 'candidate-rejects-consent',
                },
                { label: t('Awaiting Offer'), value: 'offer-detail-sent' },
                { label: t('Offer Accepted'), value: 'offer-accepted' },
                { label: t('Offer Rejected'), value: 'offer-rejected' },
              ].map((x) => {
                const key = x.value;
                return { key, selected: mValues.includes(key), value: x.label };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'hiringType' && (
          <div
            key="hiringType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={[
                { label: t('Hire'), value: 'is-hired' },
                { label: t('Did not Join'), value: 'is-not-hired' },
              ].map((x) => {
                const key = x.value;
                return { key, selected: mValues.includes(key), value: x.label };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'cvSourceType' && (
          <div
            key="cvSourceType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={cvSourceList.map((x) => {
                const key = x.sourceNameLower;
                return {
                  key,
                  selected: mValues.includes(key),
                  value: x.sourceName,
                };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
              onSearch={getCVSourceOnSearch}
            />
          </div>
        )}

        {currFilter.type === 'applicationKIVType' && (
          <div
            key="applicationKIVType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={[
                { label: t('Keep In View (KIV)'), value: 'isLiked' },
                { label: t('X0PA Upvoted'), value: 'recommended' },
                { label: t('X0PA Downvoted'), value: 'downVotes' },
              ].map((x) => {
                const key = x.value;
                return { key, selected: mValues.includes(key), value: x.label };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'tagsType' && (
          <div
            key="tagsType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={tags.map((x) => {
                const key = `${x.tagId}`;
                return {
                  key,
                  selected: mValues.includes(key),
                  value: `${x.tagName}`,
                };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'statusType' && (
          <div
            key="statusType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={jobStatusList.map((x) => {
                const key = `${x.jobStatusId}`;
                return {
                  key,
                  selected: mValues.includes(key),
                  value: `${x.jobStatusName}`,
                };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'tagsAppType' && (
          <div
            key="tagsAppType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={tags.map((x) => {
                const key = `${x.tagId}`;
                return {
                  key,
                  selected: mValues.includes(key),
                  value: x.tagName,
                };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'tBucketType' && (
          <div
            key="tBucketType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={tBucketArr.map((x) => {
                const key = `${x}`;
                return { key, selected: values.includes(key), value: key };
              })}
              onSelectMod={onMSelectValues}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'tStatusType' && (
          <div
            key="tStatusType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={tStatusArr.map((x) => {
                const key = `${x}`;
                return { key, selected: values.includes(key), value: key };
              })}
              onSelectMod={onMSelectValues}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'tUsers' && (
          <div
            key="tUsers"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={tUsersArr.map((x) => {
                const { userId, username: userName } = x || {};
                const key = userId;
                return {
                  key,
                  selected: mValues.includes(key),
                  value: userName,
                };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}
        {currFilter.type === 'tAppStatus' && (
          <div
            key="tAppStatus"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={tAppStatusArr.map((x) => {
                const { value, label } = x || {};
                const key = value;
                return {
                  key,
                  selected: mValues.includes(key),
                  value: label,
                };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'tTagsType' && (
          <div
            key="tTagsType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={tTagsArr.map((x) => {
                const key = `${x}`;
                return { key, selected: values.includes(key), value: key };
              })}
              onSelectMod={onMSelectValues}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'tFunctionType' && (
          <div
            key="tFunctionType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={tFunctionArr.map((x) => {
                const key = `${x}`;
                return { key, selected: values.includes(key), value: key };
              })}
              onSelectMod={onMSelectValues}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'tIndustryType' && (
          <div
            key="tIndustryType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={tIndustryArr.map((x) => {
                const key = `${x}`;
                return { key, selected: values.includes(key), value: key };
              })}
              onSelectMod={onMSelectValues}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'tJobtitleType' && (
          <div
            key="tJobtitleType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={tJobtitleArr.map((x) => {
                const key = `${x}`;
                return { key, selected: values.includes(key), value: key };
              })}
              onSelectMod={onMSelectValues}
              onSearch={onMSearch}
              showSelectedFilters
              selectedFilters={values}
              showSearchAlways      
              callSearchOnNull
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'tJobtitlesType' && (
          <div
            key="tJobtitlesType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            {/* <MultiFacet
              optionsMod={tCompanyArr.map((x) => {
                const key = `${x}`;
                return { key, selected: values.includes(key), value: key };
              })}
              onSelectMod={onMSelectValues}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            /> */}
            <CreateMultiValues
              className={`w-100 `}
              placeholder={t('Type values and press enter...')}
              onChange={(value) => {
                setCurrFilter({ ...currFilter, values: value || [] });
              }}
              value={currFilterValues || []}
            />
          </div>
        )}

        {currFilter.type === 'tCompanyType' && (
          <div
            key="tCompanyType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            {/* <MultiFacet
              optionsMod={tCompanyArr.map((x) => {
                const key = `${x}`;
                return { key, selected: values.includes(key), value: key };
              })}
              onSelectMod={onMSelectValues}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            /> */}
            <CreateMultiValues
              className={`w-100 `}
              placeholder={t('Type values and press enter...')}
              onChange={(value) => {
                setCurrFilter({ ...currFilter, values: value || [] });
              }}
              value={currFilterValues || []}
            />
          </div>
        )}

        {currFilter.type === 'tGenderType' && (
          <div
            key="tGenderType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={[
                { key: 'Male' },
                { key: 'Female' },
                { key: 'Undisclosed' },
                { key: 'Others' },
              ].map((x) => {
                const key = `${x.key}`;
                return { key, selected: values.includes(key), value: key };
              })}
              onSelectMod={onMSelectValues}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'tUserType' && (
          <div
            key="tUserType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={tUserArr.map((x) => {
                const key = `${x.userId}`;
                return {
                  key,
                  selected: mValues.includes(key),
                  value: `${x.userName}`,
                };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'tagsTalentType' && (
          <div
            key="tagsTalentType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={arrMap((talentPoolData && talentPoolData.Tags), (x) => {
                const key = `${x.key}`;
                return { key, selected: values.includes(key), value: key };
              })}
              onSelectMod={onMSelectValues}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'bucketType' && (
          <div
            key="bucketType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={arrMap(talentPoolData && talentPoolData.Buckets, (x) => {
                const key = `${x.key}`;
                return { key, selected: values.includes(key), value: key };
              })}
              onSelectMod={onMSelectValues}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'departmentType' && (
          <div
            key="departmentType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={arrMap(talentPoolData && talentPoolData.Department, (x) => {
                const key = `${x.value}`;
                return {
                  key,
                  selected: mValues.includes(key),
                  value: `${x.key} (${key})`,
                };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'statusTalentType' && (
          <div
            key="statusTalentType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={arrMap(talentPoolData && talentPoolData.Status, (x) => {
                const key = `${x.key}`;
                return { key, selected: values.includes(key), value: key };
              })}
              onSelectMod={onMSelectValues}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'uploadSourceType' && (
          <div
            key="uploadSourceType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={arrMap(talentPoolData && talentPoolData['Upload Source'], (x) => {
                const key = `${x.key}`;
                return { key, selected: values.includes(key), value: key };
              })}
              onSelectMod={onMSelectValues}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'profileOwnerTalentType' && (
          <div
            key="profileOwnerTalentType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={arrMap(talentPoolData && talentPoolData['Users'], (x) => {
                const key = `${x.value}`;
                const label = `${
                  (x.key || 'null').includes('null') ? 'Unknown' : x.key
                }`;
                return {
                  key,
                  selected: mValues.includes(key),
                  value: `${label} (${x.value})`,
                };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'tpCompanyType' && (
          <div
            key="tpCompanyType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={arrMap(talentPoolData && talentPoolData['Companies'], (x) => {
                const key = `${x.value}`;
                const label = `${
                  (x.key || 'null').includes('null') ? 'Unknown' : x.key
                }`;
                return {
                  key,
                  selected: mValues.includes(key),
                  value: `${label} (${x.value})`,
                };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'tpSkillsType' && (
          <div
            key="tpSkillsType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={arrMap(talentPoolData && talentPoolData['Skills'], (x) => {
                const key = `${x.key}`;
                return { key, selected: values.includes(key), value: key };
              })}
              onSelectMod={onMSelectValues}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'RegisteredOnX0paType' && (
          <div
            key="Registered-on-X0pa"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={arrMap([{ key: 'yes' }, { key: 'no' }], (x) => {
                const key = `${x.key}`;
                return { key, selected: values.includes(key), value: key };
              })}
              onSelectMod={onMSelectValues}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'RecentApplicationType' && (
          <div
            key="Recent-Application"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={[
                { key: 'Within 1 month' },
                { key: 'Within 2 months' },
                { key: 'Within 3 months' },
                { key: 'Within 6 months' },
                { key: 'Within 12 months' },
                { key: 'Never' },
              ].map((x) => {
                const key = `${x.key}`;
                return { key, selected: values.includes(key), value: key };
              })}
              onSelectMod={onMSelectValues}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'HireStatusType' && (
          <div
            key="Hire-Status"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={arrMap([{ key: 'Yes' }, { key: 'No' }], (x) => {
                const key = `${x.key}`;
                return { key, selected: values.includes(key), value: key };
              })}
              onSelectMod={onMSelectValues}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'interviewType' && (
          <div
            key="interviewType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={workflowstages.map((x) => {
                const key = `${x.stageTypeName}`;
                return {
                  key,
                  selected: mValues.includes(key),
                  value: x.stageDisplayName,
                };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'interviewStagesType' && (
          <div
            key="interviewStagesType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={[
                { label: t('Scheduling'), value: 'Scheduling' },
                { label: t('Interview'), value: 'Interview' },
                { label: t('Feedback'), value: 'Feedback' },
                { label: t('Decision'), value: 'Decision' },
              ].map((x) => {
                const key = x.value;
                return { key, selected: mValues.includes(key), value: x.label };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'countryType' && (
          <div
            key="countryType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <MultiFacet
              optionsMod={countries.map((x) => {
                const key = `${x.countryId}`;
                return {
                  key,
                  selected: mValues.includes(key),
                  value: t(x.countryFull),
                };
              })}
              onSelectMod={onMSelect}
              placeHolder={`${t('Search')} ${currFilter.name}`}
            />
          </div>
        )}

        {currFilter.type === 'tUGradsType' && (
          <div
            key="tUGradsType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <RSelect
              isClearable
              isMulti
              className={`w-100 `}
              onChange={(value) =>
                setCurrFilter({
                  ...currFilter,
                  values: value && value.map((it) => it.value),
                })
              }
              options={tUGradsArr.map((x) => {
                const { ugid, ugname } = x || {};
                return { 
                  xid: ugid,
                  label: ugname,
                  value: ugname, 
                };
              })}
              placeholder={t`Select Multiple Options`}
              value={(currFilterValues && currFilterValues.map(v => ({ label: v, value: v }))) || null}
            />
          </div>
        )}

        {currFilter.type === 'tPGradsType' && (
          <div
            key="tPGradsType"
            className="bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <RSelect
              isClearable
              isMulti
              className={`w-100 `}
              onChange={(value) =>
                setCurrFilter({
                  ...currFilter,
                  values: value && value.map((it) => it.value),
                })
              }
              options={tPGradsArr.map((x) => {
                const { pgid, pgname } = x || {};
                return { 
                  xid: pgid,
                  label: pgname,
                  value: pgname, 
                };
              })}
              placeholder={t`Select Multiple Options`}
              value={currFilterValues && currFilterValues.map(v => ({ label: v, value: v })) || null}
            />
          </div>
        )}

        {currFilter.type === 'applicationType' && (
          <div className="ml-3 p-0 bx--col-lg-12 bx--col-md-12 d-flex align-items-center mb-2">
            <DropDownBox
              mainClassName="bx--form-item mb-0"
              required
              custom
              className={`w-100 `}
              options={applications.map(({ appId, formattedName }) => ({
                value: appId,
                label: `${formattedName}(${appId})`,
              }))}
              placeholder={t`Select Option`}
              onChange={($, value) => setCurrFilter({ ...currFilter, value })}
              name="single_choice"
              selectedValue={currFilter.value}
            />
          </div>
        )}

        {currFilter.type === 'customFieldType' && getCustomFieldsRender()}

        <Button
          kind="primary"
          small
          className="ml-3 d-flex justify-content-center align-items-center"
          style={{ width: 175 }}
          onClick={handleCurrentFilter}>
          {t`Apply`}
        </Button>
      </div>
    </fieldset>
  );
};

export default FilterSectionEditor;
