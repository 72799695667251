import callApi from 'utils/callApi';
import { baseUrl } from 'utils/config';

const getAllFilters = data => dispatch => {
  if (!data) return Promise.reject(new Error('No valid data.'));
  const { filterType, searchKey, limit, offset, xview, nocount } = data || {};
  let url = `${baseUrl}/apifilters/af/all/dummy?_=${Date.now()}`;
  if (filterType) url += `&type=${filterType}`;
  if (limit) url += `&limit=${limit}`;
  if (offset) url += `&offset=${offset}`;
  if (searchKey) url += `&searchKey=${searchKey}`;
  if(xview) url += `&xview=${xview}`;
  if(nocount) url += `&nocount=${nocount}`;
  const params = {
    dispatch,
    method: 'GET',
    url,
    actionType: 'REQUEST_GET_FILTERS_DATA',
  };
  return callApi(params);
};

const createOrUpdateFilter = data => dispatch => {
  if (!data) return Promise.reject(new Error('No valid data.'));
  const { filterId, filterType, filterName, filterJson } = data || {};
  let url = `${baseUrl}/apifilters/dummy`;
  if (filterId) url += `/${filterId}`;
  else url += `/upsert`;
  const payload = { filterName, filterType, filterJson };
  const params = {
    headers: { 'content-type': 'application/json' },
    dispatch,
    method: filterId ? 'PATCH' : 'POST',
    url,
    body: JSON.stringify(payload),
    actionType: 'REQUEST_CREATE_OR_UPDATE_FILTER',
  };
  return callApi(params);
};

const deleteFilter = data => dispatch => {
  if (!data) return Promise.reject(new Error('No valid data.'));
  const { filterId } = data || {};
  let url = `${baseUrl}/apifilters/${filterId}/dummy`;
  const params = {
    dispatch,
    method: 'DELETE',
    url,
    actionType: 'REQUEST_DELETE_FILTER',
  };
  return callApi(params);
};

export {
  getAllFilters,
  createOrUpdateFilter,
  deleteFilter,
};