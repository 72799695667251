import { useEffect, useState } from 'react';

export const useModel = () => {
  const [modelOpen, setModelOpen] = useState(false);
  useEffect(() => {
    setTimeout(() => setModelOpen(true), 50);
  }, []);
  const closeModel = (callback = null) => {
    setModelOpen(false);
    setTimeout(() => callback && callback(), 170);
  };
  return [modelOpen, closeModel];
};
