import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getDocs, uploadDoc, deleteDoc } from 'actions/Hires';
import { Button, FileUploaderButton, Pagination } from 'carbon-components-react';
import GenericDataTable from 'components/common/GenericDataTable/GenericDataTable';
import NoContentBox from 'components/common/NoContentBox';
import JDProcessOverlay from 'containers/roboroy/NewJob/JDProcessOverlay';
import { toast } from "react-toastify";
import moment from "moment";

let searchTimer = null;
const UploadStudentDoc = (props) => {
  const { t } = useTranslation();
  const { appId, studentId, type } = props || {};
  // '.doc','.docx','.pdf','.ppt','.pptx','.xls','.xlsx','.txt','.png','.jpg','.jpeg'
  const supportedFileFormats = ['.doc','.docx','.pdf'];
  const [rows, setRows] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [count, setCount] = useState(null);
  const [searchKey, setSearchKey] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [loading, setLoading] = useState(false);

  let heading;
  switch (type) {
    case 'otherDocument':
      heading = t('Other Documents');
      break;
    case 'offerLetter':
      heading = t('Offer Letter Documents');
      break;
    case 'jobDescription':
      heading = t('Job Description Documents');
      break;
    default:
      break;
  }
  if(!type) {
    return null;
  }

  useEffect(() => {
    handleGetTableData();
  }, [itemsPerPage, activePage, searchKey]);

  const handleGetTableData = async () => {
    try {
      setLoading(true);
      const pData = {
        limit: itemsPerPage,
        offset: activePage === 0 ? 0 : (activePage - 1) * itemsPerPage,
        type,
        appId,
        studentId,
      };

      if (searchKey) {
        pData.searchKey = searchKey;
      }
      const res = await props.getDocs(pData);
      setLoading(false);
      if (res && !res.error) {
        const { data: listData, count: resCount } = res || {};
        handleGetHeaders();
        handleGetRows(listData);
        setCount((resCount != null && resCount != undefined) ? Number(resCount) : 0);
      }
    } catch (error) {
      setLoading(false);
      console.error('error', error);
    }
  };

  const handleDeleteDoc = async (docId) => {
    try {
      setLoading(true);
      const res = await props.deleteDoc(docId);
      setLoading(false);
      if (res && !res.error) {
        toast.success(t('Successfully deleted file!'));
        handleGetTableData();  
      } else {
        toast.error(t('Sorry, something went wrong.'))
      }
    } catch (error) {
      setLoading(false);
      console.error('error', error);
      toast.error(t('Sorry, something went wrong.'))
    }
  }

  const handleGetHeaders = () => {
    const tHeaders = [
      { key: 'fileName', header: t('Name') },
      { key: 'action', header: t('Action') },
    ];
    setHeaders(tHeaders);
  };

  const handleGetRows = listData => {
    const tRows =
      listData && Array.isArray(listData) && listData.length > 0
        ? listData.map(lr => {
            const {
              docId,
              fileName,
              presignedUrl,
            } = lr || {};
            return {
              id: docId,
              fileName: (fileName || '-'),
              action: (
                <div>
                  {presignedUrl && <a className="bx--btn bx--btn--primary bx--btn--sm" href={`${presignedUrl}`} target="_blank">{t('View')}</a>}
                  <Button className='ml-2' kind='danger' size='sm' onClick={() => handleDeleteDoc(docId)}>{t('Delete')}</Button>
                </div>
              ),
            };
          })
        : [];
    setRows(tRows);
  };

  const handlePaginationChange = data => {
    const tActivePage = data.page || 1;
    const tItemsPerPage = data.pageSize || itemsPerPage;
    setActivePage(tActivePage);
    setItemsPerPage(tItemsPerPage);
  };

  const handleSearch = event => {
    const { target } = event || {};
    const { value } = target || {};
    if (searchTimer) {
      clearTimeout(searchTimer);
    }
    searchTimer = setTimeout(() => {
      setSearchKey(value);
    }, 1000);
  };

  const handleUploadDocument = async (element) => {
    try {
      const { target } = element || {};
      const { files } = target || {};
      let fileTypeInvalid = false;
      const fileData = files && files[0] || {};
      const { name } = fileData || {};
      let fileType = name && name.split('.').pop();
      fileType = `.${fileType && fileType.toString() && fileType.toString().toLowerCase()}`;
      fileTypeInvalid = !supportedFileFormats.includes(fileType);
      if(fileTypeInvalid){
        alert(`${t(`Only`)} ${supportedFileFormats.join(', ')} ${t('are supported, please check the file extension and try again')}.`);
        return;
      }
      const currFile = new FormData();
      currFile.append('file', fileData);
      const pData = { studentId, type };
      setLoading(true);
      const res = await props.uploadDoc(currFile, pData);
      setLoading(false);
      if(res && !res.error) {
        toast.success(t('Successfully uploaded the file!'));
        handleGetTableData();
      } else {
        toast.error((res && res.error && res.error.message) || t('Sorry, something went wrong'));
      }
    } catch (error) {
      console.error('error', error);
      toast.error(t('Sorry, something went wrong'));
    }
  }

  const otherActionButtons = [
    <FileUploaderButton
      accept={supportedFileFormats}
      buttonKind="primary"
      className="bob"
      disableLabelChanges
      labelText={`${t('upload')}`}
      name="index"
      onChange={handleUploadDocument}
      tabIndex={0}
      size='lg'
    />
  ];

  return(
    <div>
      <div>
        <h4 className='font-weight-bold text-capitalize bx--type-gamma'>{heading}</h4>
      </div>
      <GenericDataTable
        className='x-candidate-status-logs-list-table'
        otherActions={otherActionButtons}
        noHeaders={!headers || (headers && headers.length <= 0)}
        headers={headers || []}
        rows={rows || []}
        searchable
        onSearchInputChange={handleSearch}
      />

      {(!rows || !rows.length || !count) && <NoContentBox useNoContentImg name='mb-0' message={t('no_hire_records_msg')} />}
      
      {!loading && count >= 0 && (
        <Pagination
          onChange={handlePaginationChange}
          page={activePage}
          pageSize={itemsPerPage}
          totalItems={count || 0}
          pageSizes={[25, 50, 75, 100]}
        />
      )}

      {loading && (<JDProcessOverlay show={loading} message="processing..." />)}
    </div>
  );
}

const mapStateToProps = state => ({
  user: state.x0paDuser.user,
});

const mapActionsToProps = {
  getDocs,
  uploadDoc,
  deleteDoc,
}

export default connect(mapStateToProps, mapActionsToProps)(UploadStudentDoc);