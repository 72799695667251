import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const PortalComp = ({ id, children, hide }) => {
  const tId = id || 'portal-comp';
  let portalElm = document.getElementById(tId);

  if (!portalElm) {
    const elem = document.createElement('div');
    elem.setAttribute('id', tId);
    document.body.appendChild(elem);
    portalElm = elem;
  }

  useEffect(() => {
    portalElm.style.transition = 'all .3s';
    if (typeof hide !== 'boolean') {
      portalElm.style.opacity = '1';
      portalElm.style.visibility = 'visible';
    }
    portalElm.style.display = 'none';
    setTimeout(() => (portalElm.style.display = ''), 50);
    return () => {
      portalElm.remove();
    };
  }, []);

  useEffect(() => {
    if (portalElm && typeof hide === 'boolean') {
      const body = document.getElementById('body');
      if (hide) {
        portalElm.style.opacity = '0';
        portalElm.style.visibility = 'hidden';
        body.style.overflow = '';
      } else {
        portalElm.style.opacity = '1';
        portalElm.style.visibility = 'visible';
        body.style.overflow = 'hidden';
      }
    }
    return () => {
      document.body.style.overflow = '';
    }
  }, [hide, portalElm]);

  if (!portalElm) return null;
  return ReactDOM.createPortal(children, portalElm);
};

export default PortalComp;
