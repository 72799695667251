import '@babel/polyfill';
import 'react-app-polyfill/ie11';
import 'core-js/fn/object/values';
import 'core-js/es6/string';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import Bowser from 'bowser';
import 'utils/prefixUtility';

import App from './App';
import UnsupportedBrowser from './UnsupportedBrowser';
import './i18next';

const browser = Bowser.getParser(window.navigator.userAgent);
const browserData = browser.getBrowser();
const isValidBrowser = browser.satisfies({
  ie: '>11',
  edge: '>=15',
  chrome: '>=64',
  firefox: '>51',
  opera: '>=43',
  safari: '>=11',
});

let errEnvironment;
if (window.location.host.includes('campus.x0pa')) {
  errEnvironment = 'camp-prod';
}
if (window.location.host.includes('geip.x0pa')) {
  errEnvironment = 'geip-prod';
}
if (window.location.host.includes('cstage.x0pa')) {
  errEnvironment = 'camp-stag';
}
if (window.location.host.includes('rps.x0pa')) {
  errEnvironment = 'rps-stag';
}
if (window.location.host.includes('rp.x0pa')) {
  errEnvironment = 'rp-prod';
}
if (errEnvironment) {
  Sentry.init({
    environment: errEnvironment,
    dsn: 'https://15485c78729f4f5b82a953a8953fc257@sentry.io/1488549',
  });
}
if (isValidBrowser === false) {
  ReactDOM.render(<UnsupportedBrowser browser={browserData} />, document.getElementById('app-container'));
} else {
  ReactDOM.render(<App />, document.getElementById('app-container'));
}
